import React from 'react';
import ReactDOM from 'react-dom/client';
import {Routes, Route, BrowserRouter} from 'react-router-dom';
import './index.css';
import reportWebVitals from './reportWebVitals';
import Login from './Views/Auth/Components/Login';
import SignUp from './Views/Auth/Components/SignUp';
import ForgetPassword from './Views/Auth/Components/ForgetPassword';
import UpdatePassword from './Views/Auth/Components/UpdatePassword';
import { UserAuthContextProvider  } from './context/AuthContext';
import FormBilan from './Views/Bilan/Components/FormBilan';

import { ViewBilan } from './Views/Bilan/Components/ViewBilan';
import { ViewAdmin } from './Views/Admin/Components/ViewsAdmin';

import { DetailCollab } from './Views/Collab/Components/DetailCollab';
import { ViewMyCollabs } from './Views/MyCollabs/Components/ViewMyCollabs';

import { HomeAnalytics } from './Views/Analytics/Components/HomeAnalytics';

import MaintenanceContext from './context/MaintenanceContext';
import { HomeAnalyticsManager } from './Views/Analytics/Components/HomeAnalyticsManager';
import { AnalyticsCollab } from './Views/Analytics/Components/AnalyticsCollab';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(

  // // <AuthProvider>
  // <React.StrictMode>
  //   {/* <SignUp/> */}
  //   <Login/>
  // </React.StrictMode>
  // // </AuthProvider>


  <UserAuthContextProvider>
    <BrowserRouter>
    <MaintenanceContext>
    <Routes>
      <Route path="/" element={<Login/>}/>
      <Route path="/signup" element={<SignUp/>}/>
      <Route path="/reset-password" element={<ForgetPassword/>}/>
      <Route path="/update-password" element={<UpdatePassword/>}/>
      
      <Route path="/new-bilan" element={<FormBilan/>}/>
      <Route path="/view-bilan" element={<ViewBilan/>}/>
      
      <Route path="/view-admin" element={<ViewAdmin/>}/>
      <Route path="/view-mycollabs" element={<ViewMyCollabs/>}/>

      <Route path="/view-collab" element={<DetailCollab/>}/>

      <Route path="/home-analytics" element={<HomeAnalytics/>}/>
      <Route path="/analytics-manager" element={<HomeAnalyticsManager/>}/>
      <Route path="/view-analytics" element={<AnalyticsCollab/>}/>

    </Routes>
    </MaintenanceContext>
    </BrowserRouter>
  </UserAuthContextProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
