import React, { useEffect, useRef } from "react";
import Chart from "chart.js/auto";
import { formatDate } from "./HomeAnalytics";

const CollabPersonalChart = ({ data, loading, type }) => {
  const chartRef = useRef(null);
  const chartInstance = useRef(null);

  const createGradient = (before, after, ctx) => {
    const grd = ctx.createLinearGradient(0, 0, 0, 400);
    grd.addColorStop(0, before);
    grd.addColorStop(1, after);
    return grd;
  };

  const createStripedPattern = (
    ctx,
    color1,
    color2,
    stripeWidth,
    stripeSpacing
  ) => {
    const canvas = document.createElement("canvas");
    canvas.width = (stripeWidth + stripeSpacing) * 4;
    canvas.height = (stripeWidth + stripeSpacing) * 4;
    const patternContext = canvas.getContext("2d");

    patternContext.fillStyle = color1;
    patternContext.fillRect(0, 0, canvas.width, canvas.height);

    patternContext.strokeStyle = color2;
    patternContext.lineWidth = stripeWidth;

    // Draw diagonal lines (from top right to bottom left)
    for (
      let i = -canvas.width;
      i < canvas.width;
      i += (stripeWidth + stripeSpacing) * 2
    ) {
      patternContext.beginPath();
      patternContext.moveTo(canvas.width, i);
      patternContext.lineTo(i, canvas.height);
      patternContext.stroke();

      patternContext.beginPath();
      patternContext.moveTo(i, 0);
      patternContext.lineTo(0, i);
      patternContext.stroke();
    }

    return ctx.createPattern(canvas, "repeat");
  };

  useEffect(() => {
    if (loading) {
      return () => {
        if (chartInstance.current) {
          chartInstance.current.destroy();
        }
      };
    }

    if (chartInstance.current) {
      chartInstance.current.destroy();
    }
    const ctx = chartRef.current.getContext("2d");

    const datasets = [];

    if (type === "hardis" || type === "all") {
      datasets.push({
        label: "Hardis",
        data: Object.values(data.average_notes_by_months).map(
          (monthData) => monthData.average_hardis
        ),
        fill: true,
        tension: 0.4,
        borderColor: "#000094",
        borderWidth: 2,
        backgroundColor: createGradient("#3333A9FF", "#9999D400", ctx),
        pointBackgroundColor: "#000094",
        pointBorderColor: "#FFFFFF",
        pointBorderWidth: 2,
        pointRadius: 7,
        pointHoverRadius: 9,
        pointHoverBorderWidth: 2,
      });
    }

    if (type === "mission" || type === "all") {
      datasets.push({
        label: "Mission",
        data: Object.values(data.average_notes_by_months).map(
          (monthData) => monthData.average_mission
        ),
        fill: true,
        tension: 0.4,
        borderColor: "#0053FF",
        borderWidth: 2,
        backgroundColor: createGradient("#6698FFFF", "#6698FF00", ctx),
        pointBackgroundColor: createStripedPattern(
          ctx,
          "#FFFFFF",
          "#0053FF",
          1,
          2
        ),
        pointBorderColor: "#0053FF",
        pointBorderWidth: 2,
        pointRadius: 7,
        pointHoverRadius: 9,
        pointHoverBorderWidth: 2,
      });
    }

    const config = {
      type: "line",
      data: {
        labels: Object.keys(data.average_notes_by_months).map(formatDate),
        datasets: datasets,
      },
      options: {
        scales: {
          y: {
            min: 0,
            max: 10,
            grid: {
              color: function (context) {
                if (context.tick.value % 2 === 1) {
                  return "#FFFFFF";
                } else {
                  return "#E7EBEC";
                }
              },
            },
            ticks: {
              color: function (context) {
                if (context.tick.value % 2 === 1) {
                  return "#FFFFFF";
                } else {
                  return "#737373";
                }
              },
            },
          },
          x: {
            grid: {
              color: "#FFFFFF",
            },
            ticks: {
              font: {
                size: 10,
              },
            },
          },
        },
        plugins: {
          legend: {
            display: false,
          },
        },
      },
    };

    chartInstance.current = new Chart(ctx, config);

    return () => {
      if (chartInstance.current) {
        chartInstance.current.destroy();
      }
    };
  }, [data, loading, type]);

  if (loading) {
    return <div>Loading chart...</div>;
  }

  return (
    <div style={{ backgroundColor: "white", padding: "1%", borderRadius: "12px" }}>
      <canvas ref={chartRef} width={1200} height={300} />
    </div>
  );
};

export default CollabPersonalChart;
