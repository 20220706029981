import React, { useState, useEffect } from "react";
import axios from "axios";
import "../css/Modif_PopUp.css";
import "../css/AddMission.css";
import PopUp from "../../../core/Component/PopUp";

const CLOUD_RUN_DOMAIN = process.env.REACT_APP_CLOUD_RUN_DOMAIN;

function DisablePopUp(props) {
  const [lastname, setLastname] = useState("");
  const [firstname, setFirstname] = useState("");
  const [uid, setUID] = useState("");
  const [email, setEmail] = useState("");
  const [job, setJob] = useState("");
  const [yearsExp, setYearsExp] = useState("");
  const [matricule, setMatricule] = useState("");
  const [practice, setPractice] = useState([]);
  const [manager, setManager] = useState("");
  const [pl, setPL] = useState("");
  const [dateEntree, setDateEntree] = useState("");
  const [num, setNum] = useState("");
  const [status, setStatus] = useState("");
  const [showConfirmationPopup, setShowConfirmationPopup] = useState(false);
  const date = new Date();
  const defaultDate = date.toLocaleDateString("en-CA");

  const handleConfirmationPopup = () => {
    setShowConfirmationPopup(!showConfirmationPopup);
  };

  useEffect(() => {
    const fetchUserInfo = async () => {
      try {
        const response = await axios.get(
          `${CLOUD_RUN_DOMAIN}/collaborator/${sessionStorage.getItem(
            "selectedMatricule"
          )}`,
          {
            headers: {
              "Content-Type": "application/json",
              token: sessionStorage.getItem("token"),
            },
          }
        );
        const userInfo = response.data;
        setUID(userInfo.uid);
        setLastname(userInfo.lastname);
        setFirstname(userInfo.firstname);
        setEmail(userInfo.email);
        setJob(userInfo.job);
        setYearsExp(userInfo.years_experience);
        setMatricule(userInfo.matricule);
        setPractice(userInfo.practice);
        setManager(userInfo.manager_uid);
        setPL(userInfo.people_leader_uid);
        setDateEntree(userInfo.entrance_date);
        setStatus(userInfo.status);
        setNum(userInfo.tel);
      } catch (error) {
        console.log(error);
      }
    };
    fetchUserInfo();
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      await axios.put(
        `${CLOUD_RUN_DOMAIN}/collaborator/${matricule}`,
        {
          lastname: lastname.toUpperCase(),
          firstname: firstname,
          email: email,
          job: job,
          tel: num,
          status: status,
          years_experience: yearsExp,
          matricule: matricule,
          practice: practice,
          manager_uid: manager,
          people_leader_uid: pl,
          entrance_date: dateEntree,
          exit_date: defaultDate,
          account_uid: uid,
        },
        {
          headers: {
            "Content-Type": "application/json",
            token: sessionStorage.getItem("token"),
          },
        }
      );
      props.setTrigger(false);
      setShowConfirmationPopup(true);
      const timer = setTimeout(() => {
        window.location.reload();
      }, 2000);
      return () => clearTimeout(timer);
    } catch (error) {
      console.log(error.response.data.detail);
    }
  };

  return (
    <>
      <PopUp
        message="Le collaborateur a bien été désactivé."
        show={showConfirmationPopup}
        onClose={handleConfirmationPopup}
      />
      {props.trigger && (
        <div
          className="modal modal-bis"
          style={{ width: "100vw", height: "110vh" }}
        >
          <div
            onClick={() => props.setTrigger(false)}
            className="overlay"
            style={{ width: "110vw", height: "110vh" }}
          ></div>
          <div
            className="modal-content modal-content-bis"
            style={{ marginLeft: "-6.5%", marginTop: "-6.5%" }}
          >
            <div className="row">
              <div className="col">
                <h6>
                  <b>DESACTIVER LE COLLABORATEUR</b>
                </h6>
              </div>
            </div>
            <form>
              <div className="row" style={{ paddingTop: "4%" }}>
                <div class="col text-center">
                  <span style={{ color: "#000000", fontSize: "14px" }}>
                    Êtes-vous sûr de vouloir désactiver {firstname} {lastname} ?
                  </span>
                </div>
              </div>
              <div className="row" style={{ paddingTop: "5%" }}>
                <div className="col text-end">
                  <button
                    className="btn btn-primary secondary-button"
                    onClick={() => props.setTrigger(false)}
                    style={{ width: "158.29px" }}
                  >
                    Annuler
                  </button>
                </div>
                <div className="col text-start">
                  <button
                    className="btn btn-primary main-button"
                    onClick={handleSubmit}
                    style={{ width: "158.29px" }}
                  >
                    Désactiver
                  </button>
                </div>
              </div>
            </form>

            {props.children}
          </div>
        </div>
      )}
    </>
  );
}

export default DisablePopUp;
