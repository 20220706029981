import React, { useEffect, useState } from 'react';
import "../css/PopUp.css";


const PopUp = ({ message, show, onClose }) => {

    const [visible, setVisible] = useState(show);

    useEffect(() => {
        if (show) {
          setVisible(true);
          const timer = setTimeout(() => {
            setVisible(false);
            onClose();
          }, 4000);
          return () => clearTimeout(timer);
        }
      }, [show, onClose]);

    return (
        <>
            {show && (
                    <div className="modal-content" style = {{ animation: visible ? 'fade-in 0.5s ease-in' : 'fade-out 0.5s ease-out', zIndex: "9999", position: "fixed", top: "8%", height: "8%", width: "auto", boxShadow: "rgba(0, 0, 0, 0.2) 0px 8px 24px", overflow: "unset", paddingLeft: "2%", paddingRight: "2%", justifyContent: "center" }}>
                        <span style={{ fontSize: "14px" }}>{message}</span>
                    </div>
            )}
        </>
    );
};

export default PopUp;