import React from "react";
import { ReactComponent as HighIcon } from "./Assets/humeur_high.svg";
import { ReactComponent as MediumIcon } from "./Assets/humeur_medium.svg";
import { ReactComponent as LowIcon } from "./Assets/humeur_low.svg";
import AnalyticsBlock from "./AnalyticBlock";

// eslint-disable-next-line
const CLOUD_RUN_DOMAIN = process.env.REACT_APP_CLOUD_RUN_DOMAIN;

export function AnalyticsBloc2({ stats, loading, filter }) {
  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="analytics-bloc-container">
      <div className="analytics-block-wrapper">
        <AnalyticsBlock
          title={"Humeur Hardis"}
          value={stats["global_mean"][filter]['average_global']["mean_hardis_note"]}
          infoTitle={"Moyenne BA Paris / Lille"}
          icon={
            stats["global_mean"][filter]['average_global']["mean_hardis_note"] >= 7 ? (
              <HighIcon className="icon" />
            ) : stats["global_mean"][filter]['average_global']["mean_hardis_note"] >= 4 ? (
              <MediumIcon className="icon" />
            ) : (
              <LowIcon className="icon" />
            )
          }
          evol={(stats["global_mean"][filter]['average_global']["mean_hardis_note"] - stats["global_mean"][filter]['average_global_evol']["mean_hardis_note"]).toFixed(1)}
          evolType="hardis"
        />
      </div>
      <div className="analytics-block-wrapper">
        <AnalyticsBlock
          title={"Humeur Mission"}
          value={stats["global_mean"][filter]['average_global']["mean_mission_note"]}
          infoTitle={"Moyenne BA Paris / Lille"}
          icon={
            stats["global_mean"][filter]['average_global']["mean_mission_note"] >= 7 ? (
              <HighIcon className="icon" />
            ) : stats["global_mean"][filter]['average_global']["mean_mission_note"] >= 4 ? (
              <MediumIcon className="icon" />
            ) : (
              <LowIcon className="icon" />
            )
          }
          evol={(stats["global_mean"][filter]['average_global']["mean_mission_note"] - stats["global_mean"][filter]['average_global_evol']["mean_mission_note"]).toFixed(1)}
          evolType="mission"
        />
      </div>
    </div>
  );
}
