import React, { useState, useEffect } from "react";
import { AnalyticsBloc1 } from "./AnalyticsBloc1";
import { AnalyticManagerBlock } from "./AnalyticManagerBlock";
import TopNav from "../../../core/Component/TopNav";
import PopUp from "../../../core/Component/PopUp";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import "../css/HomeAnalytics.css";
import ChartEvol from "./Charts"
import PracticeBarChart from "./PracticeBarChart"
import CollabBarChart from "./CollabBarChart"
import * as data from "./default_stats.json"
import { AnalyticsBloc2 } from "./AnalyticBloc2";
//import ChartEvol from "./chart"

const CLOUD_RUN_DOMAIN = process.env.REACT_APP_CLOUD_RUN_DOMAIN;

export function formatDate(inputDate) {
  const [year, month] = inputDate.split('-');
  const monthNumber = parseInt(month); 
  const monthNames = [
    'Jan', 'Fev', 'Mars', 'Avr',
    'Mai', 'Juin', 'Juil', 'Aout',
    'Sept', 'Oct', 'Nov', 'Dec'
  ];
  const monthName = monthNames[monthNumber - 1];
  const formattedOutput = `${monthName} ${year.slice(-2)}`;
  return formattedOutput;
}

export function HomeAnalytics() {
  let navigate = useNavigate();
  const [showSessionPopup, setShowSessionPopup] = useState(false);
  const [loading, setLoading] = useState(true);
  const [filter, setFilter] = useState(1);
  const [stats, setStats] = useState(data);

  const isManager = sessionStorage.getItem("admin");
  console.log(isManager)
  console.log(stats)

  const handleSessionPopup = () => {
    setShowSessionPopup(!showSessionPopup);
  };

  useEffect(() => {
    axios
      .get(`${CLOUD_RUN_DOMAIN}/user/me`, {
        headers: {
          "Content-Type": "application/json",
          token: sessionStorage.getItem("token"),
        },
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          setShowSessionPopup(true);
          const timer = setTimeout(() => {
            navigate("/");
          }, 4000);
          return () => clearTimeout(timer);
        } else {
          navigate("/");
        }
      });
        // eslint-disable-next-line
  }, []);

  useEffect(() => {
    axios.get(`${CLOUD_RUN_DOMAIN}/analytics/`,
        {
          headers: {
            "Content-Type": "application/json",
            token: sessionStorage.getItem("token"),
          },
        })
      .then((response) => {
          setStats(response.data);
          setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  }, []);
  
  useEffect(() => {
    // When the user scrolls the page, execute myFunction
window.onscroll = function() {myFunction()};

// Get the navbar
var navbar = document.getElementById("stickynav");

// Get the offset position of the navbar
var sticky = navbar.offsetTop;

// Add the sticky class to the navbar when you reach its scroll position. Remove "sticky" when you leave the scroll position
function myFunction() {
  if (window.pageYOffset >= sticky) {
    navbar.classList.add("sticky")
  } else {
    navbar.classList.remove("sticky");
  }
}

  }, []);

  return (
    <>
      <PopUp
        message="Session expirée, veuillez vous reconnecter."
        show={showSessionPopup}
        onClose={handleSessionPopup}
      />
      <TopNav />

      <div
        className="mb-5"
        style={{width: "100%", textAlign: "left", position: "absolute", left: "0%", paddingLeft: "12%", paddingRight: "5%", paddingTop: "10%" }} // to change width
      >
  <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
    <p className="h4 fw-bold">
      Bienvenue dans l'Appli Collab
    </p>
    <div className="country-button">
      <img src="https://upload.wikimedia.org/wikipedia/en/c/c3/Flag_of_France.svg" alt="French Flag" style={{ width: "20px", marginRight: "-10px" }} />
      Français
    </div>
  </div>

        {/* filtre */}
       <label htmlFor="filter" className="h6 mt-5">Période</label>
      <div className="sub-block" id="stickynav">
      <div className="filter-options">
    <button
      className={`filter-option ${filter === 12 && 'selected'}`}
      onClick={() => setFilter(12)}
    >
      12m
    </button>
    <button
      className={`filter-option ${filter === 6 && 'selected'}`}
      onClick={() => setFilter(6)}
    >
      6m
    </button>
    <button
      className={`filter-option ${filter === 3 && 'selected'}`}
      onClick={() => setFilter(3)}
    >
      3m
    </button>
    <button
      className={`filter-option ${filter === 1 && 'selected'}`}
      onClick={() => setFilter(1)}
    >
      1m
    </button>
  </div>

      </div>

      {/* Blocks */}

        <AnalyticsBloc1 stats={stats} loading={loading} filter={filter}/>
        <div className="second_block mt-4" style={{backgroundColor: "white", padding: "1%", borderRadius: "10px", paddingTop: "80px"}}>
          <div className="title-container">
          <p className="h5 fw-bold">Evolution des moyennes de mes collaborateurs</p>
          </div>
          <div className="chart_evol text-uppercase h5 fw-bold" style={{width: "60%", marginBottom: "10px"}}>

            {(filter === 1) ? <ChartEvol data={stats[3]} loading={loading}/> : <ChartEvol data={stats[filter]} loading={loading}/>}
          </div>
          <div className="practice_mean" style={{width: "40%", backgroundColor: "white", marginBottom: "10px"}}>
            { 
              (isManager === "true") ? 
              <PracticeBarChart data={stats} filter={filter} loading={loading}/> : 
              <AnalyticsBloc2 stats={stats} loading={loading} filter={filter}/>
               // to replace
            }
          </div>
          {/*last block*/}
        </div>
        {
          (isManager === "true") ? 
          <AnalyticManagerBlock stats={stats} loading={loading}/> : 
          [
            <div key="Bar block" className="chart-container mt-4" style={{backgroundColor: "white", padding: "1%", borderRadius: "10px"}}>
              <CollabBarChart data={stats[filter]["mean_per_collab"]} type={"Hardis"} width="50%"/>
              <CollabBarChart data={stats[filter]["mean_per_collab"]} type={"Mission"} width="50%"/>
            </div>  
          ] // to replace
        }
      </div>
    </>
  );
}
