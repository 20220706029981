import React, { useState, useEffect } from "react";
import "./Login.css";
import { useUserAuth } from "../../../context/AuthContext";
import { useNavigate } from "react-router-dom";
import logo from "./Assets/logo.svg";
import PopUp from "../../../core/Component/PopUp";
import { BiCheck } from "react-icons/bi";
import { FiX } from "react-icons/fi";
import TopNav from "../../../core/Component/TopNav";

export default function UpdatePassword() {
  const [showConfirmationPopup, setShowConfirmationPopup] = useState(false);
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
    // eslint-disable-next-line
  const [error, setError] = useState("");
  const [customErrorMessage, setCustomErrorMessage] = useState("");
  const [passwordLengthValid, setPasswordLengthValid] = useState(false);
  const [passwordUpperCaseValid, setPasswordUpperCaseValid] = useState(false);
  const [passwordSpecialCharValid, setPasswordSpecialCharValid] =
    useState(false);
  const [isInteracted, setInteracted] = useState(false);
    // eslint-disable-next-line
  const logoHardis = logo;
  const { user, changePassword, logIn } = useUserAuth();
  let navigate = useNavigate();

  const handleConfirmationPopup = () => {
    setShowConfirmationPopup(!showConfirmationPopup);
  };

  useEffect(() => {
    // Vérifiez les règles de gestion à chaque changement du mot de passe
    checkPasswordRules(newPassword);
  }, [newPassword]);

  const checkPasswordRules = (password) => {
    // Vérifiez les règles de gestion et mettez à jour les états correspondants
    setPasswordLengthValid(password.length >= 8);
    setPasswordUpperCaseValid(/[A-Z]/.test(password));
    setPasswordSpecialCharValid(/[^A-Za-z0-9]/.test(password));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (newPassword !== confirmNewPassword) {
      setCustomErrorMessage("Les mots de passe ne correspondent pas.");
      return;
    }

    if (
      !(
        passwordLengthValid &&
        passwordUpperCaseValid &&
        passwordSpecialCharValid
      )
    ) {
      setCustomErrorMessage(
        "Le nouveau mot de passe ne respecte pas les règles de gestion."
      );
      return;
    }

    try {
      await logIn(user.email, currentPassword);

      // Changer le mot de passe
      await changePassword(newPassword);

      setShowConfirmationPopup(true);
        // eslint-disable-next-line
      const timer = setTimeout(() => {
        navigate("/");
      }, 4000);
    } catch (err) {
      console.log(err);
      setCustomErrorMessage(
        "Échec de la modification du mot de passe. Veuillez réessayer."
      );
    }
  };

  const handleChangeCurrentPassword = (event) => {
    const password = event.target.value;
    setCurrentPassword(password);
  };

  const handleChangeNewPassword = (event) => {
    const password = event.target.value;
    setInteracted(true);
    setNewPassword(password);
  };

  const handleChangeConfirmNewPassword = (event) => {
    const password = event.target.value;
    setConfirmNewPassword(password);
  };

  return (
    <>
      <TopNav />
      <PopUp
        message={`Votre mot de passe a été modifié avec succès.`}
        show={showConfirmationPopup}
        onClose={handleConfirmationPopup}
      />
      <div className="text-center mt-5">
        <div className="form-signin w-100 m-auto">
          <form onSubmit={handleSubmit}>
            {/* Title */}
            <h1 className="h3 mb-3 fw-normal mt-5">
              Modification du mot de passe
            </h1>
            {error && (
              <div className="alert alert-danger" role="alert">
                {error}
              </div>
            )}
            {/* Input current password */}
            <div className="form-floating">
              <input
                type="password"
                onChange={handleChangeCurrentPassword}
                value={currentPassword}
                className="form-control"
                id="floatingCurrentPassword"
                placeholder="Ancien mot de passe"
                required
              />
              <label htmlFor="floatingCurrentPassword">
                Ancien mot de passe
              </label>
            </div>

            {/* Input new password */}
            <div className="form-floating">
              <input
                type="password"
                onChange={handleChangeNewPassword}
                value={newPassword}
                className={`form-control ${
                  passwordLengthValid &&
                  passwordUpperCaseValid &&
                  passwordSpecialCharValid
                    ? "is-valid"
                    : ""
                }`}
                id="floatingNewPassword"
                placeholder="Nouveau mot de passe"
                required
              />
              <label htmlFor="floatingNewPassword">Nouveau mot de passe</label>
            </div>

            {/* Password rules */}
            <div className="password-rules">
              <ul className="list-unstyled text-start">
                <li>
                  {passwordLengthValid ? (
                    <span
                      className="text-success"
                      style={
                        isInteracted
                          ? {
                              color: "#28be1e",
                            }
                          : {
                              color: "#6c757d",
                            }
                      }
                    >
                      <BiCheck
                        style={
                          isInteracted
                            ? {
                                color: "#28be1e",
                              }
                            : {
                                color: "#6c757d",
                              }
                        }
                      />{" "}
                      8 caractères minimum
                    </span>
                  ) : (
                    <span
                      style={
                        isInteracted
                          ? {
                              color: "#cb101a",
                            }
                          : {
                              color: "#6c757d",
                            }
                      }
                    >
                      <FiX
                        style={
                          isInteracted
                            ? {
                                color: "#cb101a",
                              }
                            : {
                                color: "#6c757d",
                              }
                        }
                      />{" "}
                      8 caractères minimum
                    </span>
                  )}
                </li>
                <li>
                  {passwordUpperCaseValid ? (
                    <span
                      className="text-success"
                      style={
                        isInteracted
                          ? {
                              color: "#28be1e",
                            }
                          : {
                              color: "#6c757d",
                            }
                      }
                    >
                      <BiCheck
                        style={
                          isInteracted
                            ? {
                                color: "#28be1e",
                              }
                            : {
                                color: "#6c757d",
                              }
                        }
                      />{" "}
                      1 majuscule
                    </span>
                  ) : (
                    <span
                      style={
                        isInteracted
                          ? {
                              color: "#cb101a",
                            }
                          : {
                              color: "#6c757d",
                            }
                      }
                    >
                      <FiX
                        style={
                          isInteracted
                            ? {
                                color: "#cb101a",
                              }
                            : {
                                color: "#6c757d",
                              }
                        }
                      />{" "}
                      1 majuscule
                    </span>
                  )}
                </li>
                <li>
                  {passwordSpecialCharValid ? (
                    <span
                      className="text-success"
                      style={
                        isInteracted
                          ? {
                              color: "#28be1e",
                            }
                          : {
                              color: "#6c757d",
                            }
                      }
                    >
                      <BiCheck
                        style={
                          isInteracted
                            ? {
                                color: "#28be1e",
                              }
                            : {
                                color: "#6c757d",
                              }
                        }
                      />{" "}
                      1 caractère spécial
                    </span>
                  ) : (
                    <span
                      style={
                        isInteracted
                          ? {
                              color: "#cb101a",
                            }
                          : {
                              color: "#6c757d",
                            }
                      }
                    >
                      <FiX
                        style={
                          isInteracted
                            ? {
                                color: "#cb101a",
                              }
                            : {
                                color: "#6c757d",
                              }
                        }
                      />{" "}
                      1 caractère spécial
                    </span>
                  )}
                </li>
              </ul>
            </div>

            {/* Input confirm new password */}
            <div className="form-floating">
              <input
                type="password"
                onChange={handleChangeConfirmNewPassword}
                value={confirmNewPassword}
                className="form-control"
                id="floatingConfirmNewPassword"
                placeholder="Confirmez le nouveau mot de passe"
                required
              />
              <label htmlFor="floatingConfirmNewPassword">
                Confirmez le nouveau mot de passe
              </label>
            </div>

            {/* Button */}
            <button className="w-100 btn btn-lg btn-color" type="submit">
              Changer le mot de passe
            </button>
            {customErrorMessage && (
              <p className="p-error" style={{ color: "#737373" }}>
                {customErrorMessage}
              </p>
            )}
          </form>
        </div>
      </div>
    </>
  );
}
