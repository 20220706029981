import React, { useState, forwardRef, useImperativeHandle , useEffect} from "react";
import "../css/TextAreasComponent.css";
import axios from 'axios';
import 'quill/dist/quill.snow.css';
import ReactQuill from 'react-quill';

const TextAreas = forwardRef((props, _ref) => {
  const [remarqueHardis, setRemarqueHardis] = useState("");
  const [remarqueMission, setRemarqueMission] = useState("");
  const [actionHardis, setActionHardis] = useState("");
  const [actionMission, setActionMission] = useState("");
  
  
  const CLOUD_RUN_DOMAIN = process.env.REACT_APP_CLOUD_RUN_DOMAIN;
  
  
  async function getData(matricule) {
    try {
      const response = await axios.get(`${CLOUD_RUN_DOMAIN}/draft/${matricule}`,
      {
        headers: {
          "Content-Type": "application/json",
          token: sessionStorage.getItem("token"),
        },
      }
      );
      
      if (response.data) {
        const draft = response.data;
        console.log(draft);
        setRemarqueHardis(draft.hardis_comment);
        setActionHardis(draft.hardis_remark);        
        setRemarqueMission(draft.mission_comment);
        setActionMission(draft.mission_remark);
      } else {
        // Gérer le cas où la propriété draft n'est pas présente dans la réponse
        console.error("La propriété 'draft' n'est pas présente dans la réponse.");
      }
    } catch (error) {
      // Gérer les erreurs de la requête
      console.error("Erreur lors de la récupération des données :", error);
    }
  }

  useEffect(() => {
    getData(sessionStorage.getItem("selectedMatricule"))
      // eslint-disable-next-line
  },[]);

  const handleRemarqueHardis = (content) => {
    setRemarqueHardis(content);
    console.log("remarque hardis : ", content)
  };
  const handleRemarqueMission = (content) => {
    setRemarqueMission(content);
    console.log("remarque mission : ", content)
  };
  const handleActionMission = (content) => {
    setActionMission(content);
    console.log("action mission : ", content)
  };
  const handleActionHardis = (content) => {
    setActionHardis(content);
    console.log("action hardis : ", content)
  };

  useImperativeHandle(_ref, () => ({
    getRemarqueHardis: () => {
      return remarqueHardis;
    },
    getRemarqueMission: () => {
      return remarqueMission;
    },
    getActionHardis: () => {
      return actionHardis;
    },
    getActionMission: () => {
      return actionMission;
    },
  }));

  const modules = {
    toolbar: [
      [{ 'size': [] }, { 'color': [] }],
      ['bold', 'italic', 'underline', 'strike'],
      [{ 'list': 'ordered'}, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
      ['link'], 
    ],
  };
  
  const quillStyle = {
    height: "130px",
    width: "95%",
    maxWidth: "95%",
    whiteSpace: "pre-wrap"
  };

  return (
    <>
  
      <div className="row" style={{ paddingTop: "2.5%" }}>
        <div class="col" >
          <label htmlFor="" style={{ fontSize: "14px" }}>
            Remarque Hardis*
          </label>
          <ReactQuill
            required
            theme="snow"
            value={remarqueHardis}
            onChange={handleRemarqueHardis}
            modules={modules}
            style={quillStyle}
          />
        </div>

        <div className="col" style={{ paddingLeft: "10px" }}> 
          <label htmlFor="" style={{ fontSize: "14px" }}>
            Remarque Mission*
          </label>
          <ReactQuill
            required
            theme="snow"
            value={remarqueMission}
            onChange={handleRemarqueMission}
            modules={modules}
            style={quillStyle}
          />
        </div>     
      </div>

      <div className="row" style={{ paddingTop: "5%" }}>
        <div class="col" style={{ paddingTop: "2.5%" }}>
          <label htmlFor="" style={{ fontSize: "14px" }}>
            Action Hardis
          </label>
          <ReactQuill
            theme="snow"
            value={actionHardis}
            onChange={handleActionHardis}
            modules={modules}
            style={quillStyle}
          />
        </div>
        
        <div className="col" style={{ paddingTop: "2.5%", paddingLeft: "10px" } }>
          <label htmlFor="" style={{ fontSize: "14px" }}>
            Action Mission
          </label>
          <ReactQuill
            theme="snow"
            value={actionMission}
            onChange={handleActionMission}
            modules={modules}
            style={quillStyle}
          />
        </div>
      </div>
    </>
  );
});

export default React.memo(TextAreas);
