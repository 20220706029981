import React, { useEffect, useRef } from 'react';
import Chart from "chart.js/auto"

const CollabPracticeBarChart = ({ data, type, practice, manager }) => {
  const chartRef = useRef(null);
  const chartInstance = useRef(null);

  const createStripedPattern = (
    ctx,
    color1,
    color2,
    stripeWidth,
    stripeSpacing
  ) => {
    const canvas = document.createElement("canvas");
    canvas.width = (stripeWidth + stripeSpacing) * 4;
    canvas.height = (stripeWidth + stripeSpacing) * 4;
    const patternContext = canvas.getContext("2d");

    patternContext.fillStyle = color1;
    patternContext.fillRect(0, 0, canvas.width, canvas.height);

    patternContext.strokeStyle = color2;
    patternContext.lineWidth = stripeWidth;

    // Draw diagonal lines (from top right to bottom left)
    for (
      let i = -canvas.width;
      i < canvas.width;
      i += (stripeWidth + stripeSpacing) * 2
    ) {
      patternContext.beginPath();
      patternContext.moveTo(canvas.width, i);
      patternContext.lineTo(i, canvas.height);
      patternContext.stroke();

      patternContext.beginPath();
      patternContext.moveTo(i, 0);
      patternContext.lineTo(0, i);
      patternContext.stroke();
    }

    return ctx.createPattern(canvas, "repeat");
  };


  useEffect(() => {
    if (chartInstance.current) {
      chartInstance.current.destroy();
    }

    //data = Object.values(data).sort((a, b) => a["mean_"+type] - b["mean_"+type])

    const ctx = chartRef.current.getContext("2d");
    const config = {
      type: "bar",
      data: {
        labels: Object.values(data).map(entry => entry.firstname),
        datasets: [
          {
            label: "Moyenne " + type,
            data: Object.values(data).map(entry => entry[type + "_mood"]),
            borderWidth: 2,
            borderColor: (type === "hardis") ?  '#000094' : '#0053FF',
            backgroundColor: (type === "hardis") ?  '#000094' : createStripedPattern(
              ctx,
              "#FFFFFF",
              "#0053FF",
              1,
              2
            ),
            borderRadius: 10,
            borderSkipped: 'start',
            barPercentage: 0.5,
          },
        ]
      },
      options: {
        indexAxis: 'y',
        scales: {
          x: {
            min: 0,
            max: 10,
            position: "top",
            border: {
              display: false
            },
            grid: {
              color: function (context) {
                if (context.tick.value % 2 === 1) {
                  return '#FFFFFF'
                } else {
                  return '#E7EBEC'
                }
              }
            },
            ticks: {
              color: function (context) {
                if (context.tick.value % 2 === 1) {
                  return '#FFFFFF'
                } else {
                  return '#737373'
                }
              }
            },
          },
          y: {
            grid: {
              color: "#FFFFFF",
            },
          },
          yAxes: [{
            barPercentage: 0.4
          }]
        },
        plugins: {
          legend: {
            display: false
          }
        }
      }
    }

    chartInstance.current = new Chart(ctx, config)

    return () => {
      if (chartInstance.current) {
        chartInstance.current.destroy();
      }
    }
    // eslint-disable-next-line
  }, [data]);

  return (
    <div className="collab-bar-chart-practice" style={{ display: "grid"}}>
      {/* <p className="h6 mb-2" style={{ width: "66%", fontSize: "0.8rem", color: "#737373" }}>
        {manager.manager} | {manager.collab_count} collabs
            </p> */}
      <canvas ref={chartRef} />
      <p className="h6 mt-2" style={{ fontSize: "0.8rem", color: "#737373", alignSelf: "center", justifySelf: "center" }}>
        Notes {type.charAt(0).toUpperCase() + type.slice(1)}
      </p>
    </div>
  )
}

export default CollabPracticeBarChart
