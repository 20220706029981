import React, { useEffect, useState } from "react";
import { ReactComponent as ClearIcon } from "./Assets/clear_icon.svg";
import { ReactComponent as HighIcon } from "./Assets/humeur_high.svg";
import { ReactComponent as MediumIcon } from "./Assets/humeur_medium.svg";
import { ReactComponent as LowIcon } from "./Assets/humeur_low.svg";
import TopNav from "../../../core/Component/TopNav";
import { useNavigate } from "react-router-dom";
import "../css/ViewMyCollabs.css";
import PopUp from "../../../core/Component/PopUp";
import axios from "axios";

const CLOUD_RUN_DOMAIN = process.env.REACT_APP_CLOUD_RUN_DOMAIN;

const date = new Date();
const defaultDate = date.toLocaleDateString("en-CA");

export function ViewMyCollabs() {
  let navigate = useNavigate();
  const [query, setQuery] = useState("");
  const [collaborators, setCollaborators] = useState([]);
  const [filteredCollabs, setFilteredCollabs] = useState([]);
  const [practice, setPractice] = useState("");
  const [manager, setManager] = useState("");
  const [noteHardis, setNoteHardis] = useState("");
  const [noteMission, setNoteMission] = useState("");
  const [status, setStatus] = useState("ACTIF");
  const [managers, setManagers] = useState([]);
  const [practices, setPractices] = useState([]);
  const [hardisIncreasingOrder, setHardisIncreasingOrder] = useState(true);
  const [missionIncreasingOrder, setMissionIncreasingOrder] = useState(true);
  const [nameIncreasingOrder, setNameIncreasingOrder] = useState(true);
  const [bilanIncreasingOrder, setBilanIncreasingOrder] = useState(true);
  const [managerIncreasingOrder, setManagerIncreasingOrder] = useState(true);
  const [practiceIncreasingOrder, setPracticeIncreasingOrder] = useState(true);
  const [exitDateIncreasingOrder, setExitDateIncreasingOrder] = useState(true);
  const [applyFilters, setApplyFilters] = useState(false);
  const [showSessionPopup, setShowSessionPopup] = useState(false);
  const [practicePopUp, setPracticePopUp] = useState(false);
  const [selectedCollabPractices, setSelectedCollabPractices] = useState([]);

  const handleSessionPopup = () => {
    setShowSessionPopup(!showSessionPopup);
  };

  useEffect(() => {
    axios
      .get(`${CLOUD_RUN_DOMAIN}/collaborator`, {
        headers: {
          "Content-Type": "application/json",
          token: sessionStorage.getItem("token"),
        },
      })
      .then((response) => {
        // Make sure response.data is an array
        if (Array.isArray(response.data)) {
          let collabs = response.data;
          collabs = collabs.filter((collab) => collab.status !== "Manageur");
          setCollaborators(collabs);
          const filteredCollaborators = response.data;
          const { filteredPractices, filteredManagers } = filterOptions(
            JSON.parse(sessionStorage.getItem("practice")),
            JSON.parse(sessionStorage.getItem("manager")),
            filteredCollaborators
          );
          setPractices(filteredPractices);
          setManagers(filteredManagers);
          setApplyFilters(true);
        } else {
          console.log("Invalid response data:", response.data);
        }
      })
      .catch((error) => console.log(error));
  }, [query, practice, manager, noteHardis, noteMission, status]);

  useEffect(() => {
    axios
      .get(`${CLOUD_RUN_DOMAIN}/user/me`, {
        headers: {
          "Content-Type": "application/json",
          token: sessionStorage.getItem("token"),
        },
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          setShowSessionPopup(true);
          const timer = setTimeout(() => {
            navigate("/");
          }, 4000);
          return () => clearTimeout(timer);
        } else {
          navigate("/");
        }
      });
    // eslint-disable-next-line
  }, []);

  const handleClickBilan = async (matricule) => {
    sessionStorage.setItem("selectedMatricule", matricule);
    const url = `${CLOUD_RUN_DOMAIN}/collaborator/${sessionStorage.getItem(
      "selectedMatricule"
    )}/infos`;
    const header = {
      "Content-Type": "application/json",
      token: sessionStorage.getItem("token"),
    };
    const res = await axios.get(url, { headers: header });
    sessionStorage.setItem("collab", JSON.stringify(res["data"]));
    navigate("/new-bilan");
  };

  const handleClickOnCollab = async (matricule) => {
    sessionStorage.setItem("selectedMatricule", matricule);
    const url = `${CLOUD_RUN_DOMAIN}/collaborator/${sessionStorage.getItem(
      "selectedMatricule"
    )}/infos`;
    const header = {
      "Content-Type": "application/json",
      token: sessionStorage.getItem("token"),
    };
    const res = await axios.get(url, { headers: header });
    sessionStorage.setItem("collab", JSON.stringify(res["data"]));
    navigate("/view-collab");
  };

  const handleNameOrder = async () => {
    if (nameIncreasingOrder) {
      const sortedCollabs = [...filteredCollabs].sort((a, b) => {
        return a.firstname.localeCompare(b.firstname);
      });
      setFilteredCollabs(sortedCollabs);
    } else {
      const sortedCollabs = [...filteredCollabs].sort((a, b) => {
        return b.firstname.localeCompare(a.firstname);
      });
      setFilteredCollabs(sortedCollabs);
    }
    setNameIncreasingOrder(!nameIncreasingOrder);
  };

  const handleManagerOrder = async () => {
    if (managerIncreasingOrder) {
      const sortedCollabs = [...filteredCollabs].sort((a, b) => {
        if (a.manager_name === "-" && b.manager_name !== "-") {
          return 1;
        }
        if (a.manager_name !== "-" && b.manager_name === "-") {
          return -1;
        }
        return a.manager_name.localeCompare(b.manager_name);
      });
      setFilteredCollabs(sortedCollabs);
    } else {
      const sortedCollabs = [...filteredCollabs].sort((a, b) => {
        return b.manager_name.localeCompare(a.manager_name);
      });
      setFilteredCollabs(sortedCollabs);
    }
    setManagerIncreasingOrder(!managerIncreasingOrder);
  };

  const handleMissionNoteOrder = async () => {
    if (!missionIncreasingOrder) {
      const sortedCollabs = [...filteredCollabs].sort((a, b) => {
        return a.note_mission - b.note_mission;
      });
      setFilteredCollabs(sortedCollabs);
    } else {
      const sortedCollabs = [...filteredCollabs].sort((a, b) => {
        return b.note_mission - a.note_mission;
      });
      setFilteredCollabs(sortedCollabs);
    }
    setMissionIncreasingOrder(!missionIncreasingOrder);
  };

  const handleHardisNoteOrder = async () => {
    if (!hardisIncreasingOrder) {
      const sortedCollabs = [...filteredCollabs].sort((a, b) => {
        return a.note_hardis - b.note_hardis;
      });
      setFilteredCollabs(sortedCollabs);
    } else {
      const sortedCollabs = [...filteredCollabs].sort((a, b) => {
        return b.note_hardis - a.note_hardis;
      });
      setFilteredCollabs(sortedCollabs);
    }
    setHardisIncreasingOrder(!hardisIncreasingOrder);
  };

  const parseDate = (dateStr) => {
    const [day, month, year] = dateStr.split("/").map(Number);
    return new Date(year, month - 1, day);
  };

  const parseDateBis = (dateStr) => {
    const [year, month, day] = dateStr.split("-").map(Number);
    return new Date(year, month - 1, day);
  };

  const handleBilanDateOrder = async () => {
    if (!bilanIncreasingOrder) {
      const sortedCollabs = [...filteredCollabs].sort((a, b) => {
        if (!a.bilan_date) {
          a.bilan_date = "01/01/1901";
        }
        if (!b.bilan_date) {
          b.bilan_date = "01/01/1901";
        }
        return parseDate(a.bilan_date) - parseDate(b.bilan_date);
      });
      setFilteredCollabs(sortedCollabs);
    } else {
      const sortedCollabs = [...filteredCollabs].sort((a, b) => {
        if (!a.bilan_date) {
          a.bilan_date = "01/01/1901";
        }
        if (!b.bilan_date) {
          b.bilan_date = "01/01/1901";
        }
        return parseDate(b.bilan_date) - parseDate(a.bilan_date);
      });
      setFilteredCollabs(sortedCollabs);
    }
    setBilanIncreasingOrder(!bilanIncreasingOrder);
  };

  const handlePracticeOrder = async () => {
    const compareArrays = (a, b) => {
      a.sort();
      b.sort();

      const aString = a.join(",");
      const bString = b.join(",");

      return practiceIncreasingOrder
        ? aString.localeCompare(bString)
        : bString.localeCompare(aString);
    };

    const sortedCollabs = [...filteredCollabs].sort((a, b) => {
      return compareArrays(a.practice, b.practice);
    });

    setFilteredCollabs(sortedCollabs);

    setPracticeIncreasingOrder(!practiceIncreasingOrder);
  };

  const handleExitDateOrder = async () => {
    if (exitDateIncreasingOrder) {
      const sortedCollabs = [...filteredCollabs].sort((a, b) => {
        if (!a.exit_date) {
          a.exit_date = "2901-01-01";
        }
        if (!b.exit_date) {
          b.exit_date = "2901-01-01";
        }
        return parseDateBis(a.exit_date) - parseDateBis(b.exit_date);
      });
      setFilteredCollabs(sortedCollabs);
    } else {
      const sortedCollabs = [...filteredCollabs].sort((a, b) => {
        if (!a.exit_date) {
          a.exit_date = "2901-01-01";
        }
        if (!b.exit_date) {
          b.exit_date = "2901-01-01";
        }
        return parseDateBis(b.exit_date) - parseDateBis(a.exit_date);
      });
      setFilteredCollabs(sortedCollabs);
    }
    setExitDateIncreasingOrder(!exitDateIncreasingOrder);
  };

  const handlePractice = (event) => {
    setPractice(event.target.value);
  };

  const handleManager = (event) => {
    setManager(event.target.value);
  };

  const handleNoteHardis = (event) => {
    setNoteHardis(event.target.value);
  };

  const handleNoteMission = (event) => {
    setNoteMission(event.target.value);
  };

  const handlePracticeClear = () => {
    setPractice("");
  };

  const handleManagerClear = () => {
    setManager("");
  };

  const handleNoteHardisClear = () => {
    setNoteHardis("");
  };

  const handleNoteMissionClear = () => {
    setNoteMission("");
  };

  const handleStatus = (event) => {
    const newStatus = event.target.value;
    setStatus(newStatus);
    localStorage.setItem("STATUT", newStatus);
  };

  const handleStatusClear = () => {
    setStatus("");
  };

  useEffect(() => {
    const savedFilters =
      JSON.parse(localStorage.getItem("myCollabsFilters")) || {};
    setQuery(savedFilters.query || "");
    setPractice(savedFilters.practice || "");
    setManager(savedFilters.manager || "");
    setNoteHardis(savedFilters.noteHardis || "");
    setNoteMission(savedFilters.noteMission || "");
    setStatus(savedFilters.status || "ACTIF");
  }, []);

  useEffect(() => {
    const res = search(collaborators);
    setFilteredCollabs(res);
    const filtersToSave = {
      query,
      practice,
      manager,
      noteHardis,
      noteMission,
      status,
    };
    localStorage.setItem("myCollabsFilters", JSON.stringify(filtersToSave));
    // eslint-disable-next-line
  }, [query, practice, manager, noteHardis, noteMission, status, applyFilters]);

  const search = (data) => {
    return data.filter(
      (item) =>
        (item.lastname.toLowerCase().includes(query) ||
          item.firstname.toLowerCase().includes(query)) &&
        (manager !== "MANAGER"
          ? item.manager_name.toLowerCase().includes(manager.toLowerCase())
          : item) &&
        (practice !== "PRACTICE"
          ? // item.practice est un tableau, il faut vérifier si le tableau contient la valeur de practice
            practice === "" || item.practice.includes(practice)
          : // Si practice est vide, on retourne tout
            item) &&
        (status !== ""
          ? (defaultDate >= item.exit_date
              ? "Inactif"
              : "Actif"
            ).toLowerCase() === status.toLowerCase()
          : item) &&
        (noteHardis !== "" && noteHardis !== "HUMEUR HARDIS"
          ? checkNote(item, item.note_hardis, noteHardis)
          : item) &&
        (noteMission !== "" && noteMission !== "HUMEUR MISSION"
          ? checkNote(item, item.note_mission, noteMission)
          : item)
    );
  };

  const checkNote = (item, note, filter) => {
    note = parseInt(note);
    if (
      (filter === "Vert" && note > 6) ||
      (filter === "Jaune" && note >= 4 && note <= 6) ||
      (filter === "Rouge" && note < 4)
    ) {
      return true;
    }
    return false;
  };

  const filterOptions = (practices, managers, collaborators) => {
    // Si admin est true, retourner les arrays initiaux
    if (JSON.parse(sessionStorage.getItem("admin")) === true) {
      return { filteredPractices: practices, filteredManagers: managers };
    } else {
      // Filtrer les options en fonction des collaborateurs
      const practiceSet = new Set();
      const managerSet = new Set();

      collaborators.forEach((collaborator) => {
        // Filtrer les practices
        if (collaborator.practice) {
          collaborator.practice.forEach((practice) =>
            practiceSet.add(practice)
          );
          // pour l'instant il n'y a qu'une seule practice par collaborateur
          //practiceSet.add(collaborator.practice);
        }
        // Filtrer les managers
        if (collaborator.manager_uid) {
          managerSet.add(collaborator.manager_uid);
        }
      });

      // Filtrer les practices et les managers en fonction des ensembles
      const filteredPractices = practices.filter((practice) =>
        practiceSet.has(practice)
      );
      const filteredManagers = managers.filter((manager) =>
        managerSet.has(manager.uid)
      );

      return { filteredPractices, filteredManagers };
    }
  };

  const handlePracticePopUp = (practices) => {
    setSelectedCollabPractices(practices);
    setPracticePopUp(true);
  };

  return (
    <>
      <PopUp
        message="Session expirée, veuillez vous reconnecter."
        show={showSessionPopup}
        onClose={handleSessionPopup}
      />
      <TopNav />
      {practicePopUp && (
        <div
          className="popup"
          style={{
            width: "100vw",
            height: "100vh",
            position: "fixed",
            top: "0",
            left: "0",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "rgba(0,0,0,0.5)",
            zIndex: "1000",
          }}
        >
          <div
            className="popup_inner"
            style={{
              position: "relative",
              padding: "20px",
              width: "50%",
              backgroundColor: "white",
              borderRadius: "10px",
              boxShadow: "0 0 10px 3px rgba(0,0,0,0.2)",
            }}
          >
            <h2>Practices</h2>
            <ul
              style={{
                listStyle: "none",
                padding: "0",
              }}
            >
              {selectedCollabPractices.map((practice, index) => (
                <li key={index}>{practice}</li>
              ))}
            </ul>
            <button
              style={{
                position: "absolute",
                top: "10px",
                right: "10px",
                cursor: "pointer",
                border: "none",
                background: "none",
                fontSize: "20px",
              }}
              onClick={() => setPracticePopUp(false)}
            >
              <i class="bi bi-x-lg"></i>
            </button>
          </div>
        </div>
      )}
      <div
        className="container padding-top"
        style={{ width: "100vw", marginLeft: "3.5%" }}
      >
        <p className="text-uppercase h4 fw-bold">Tous mes collaborateurs</p>

        {/* PARTIE FILTRES  */}
        <div
          style={{
            backgroundColor: "white",
            padding: "3% 3% 3% 3%",
            borderRadius: "4px",
            boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
            marginTop: "3%",
          }}
        >
          <div className="row">
            <div className="col-2">
              <input
                type="text"
                className="form-control selector"
                style={{ fontSize: "16px" }}
                id="floatingInput"
                placeholder="Recherche"
                value={query}
                onChange={(e) => setQuery(e.target.value)}
              />
            </div>
            <div
              className="col-2"
              style={{ position: "relative", marginLeft: "-1%" }}
            >
              <div style={{ display: "flex" }}>
                <select
                  className="form-select selector"
                  style={{
                    fontSize: "14px",
                    borderColor: "#FFFFFF",
                    width: "80%",
                    paddingRight: "30px",
                  }}
                  id="floatingPractice"
                  onChange={handlePractice}
                  value={practice}
                >
                  <option value="" disabled selected>
                    PRACTICE
                  </option>
                  {practices &&
                    practices.map((practice) => (
                      <option key={practice} value={practice}>
                        {practice}
                      </option>
                    ))}
                </select>
                {practice && (
                  <div
                    style={{
                      position: "absolute",
                      top: "50%",
                      right: "8px",
                      transform: "translateY(-50%)",
                      width: "20px",
                      height: "20px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      cursor: "pointer",
                      borderRadius: "50%",
                      backgroundColor: "#ddd",
                    }}
                    onClick={handlePracticeClear}
                  >
                    <ClearIcon
                      style={{
                        width: "12px",
                        height: "12px",
                        fill: "black",
                        marginLeft: "-1%",
                      }}
                    />
                  </div>
                )}
              </div>
            </div>

            <div
              className="col-2"
              style={{ position: "relative", marginLeft: "-1%" }}
            >
              <div style={{ display: "flex" }}>
                <select
                  className="form-select selector"
                  style={{
                    fontSize: "14px",
                    borderColor: "#FFFFFF",
                    width: "81%",
                    paddingRight: "30px",
                  }}
                  id="floatingPractice"
                  onChange={handleManager}
                  value={manager}
                >
                  <option value="" disabled selected>
                    MANAGER
                  </option>
                  {managers &&
                    managers.map((manager) => (
                      <option key={manager["Name"]} value={manager["name"]}>
                        {manager["LastName"]}
                      </option>
                    ))}
                </select>
                {manager && (
                  <div
                    style={{
                      position: "absolute",
                      top: "50%",
                      right: "8px",
                      transform: "translateY(-50%)",
                      width: "20px",
                      height: "20px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      cursor: "pointer",
                      borderRadius: "50%",
                      backgroundColor: "#ddd",
                    }}
                    onClick={handleManagerClear}
                  >
                    <ClearIcon
                      style={{
                        width: "12px",
                        height: "12px",
                        fill: "black",
                        marginLeft: "-1%",
                      }}
                    />
                  </div>
                )}
              </div>
            </div>

            <div
              className="col-3"
              style={{ position: "relative", marginLeft: "-1%" }}
            >
              <div style={{ display: "flex" }}>
                <select
                  className="form-select selector"
                  style={{
                    fontSize: "14px",
                    borderColor: "#FFFFFF",
                    width: "60%",
                  }}
                  id="floatingPractice"
                  onChange={handleNoteHardis}
                  value={noteHardis}
                >
                  <option value="" disabled selected>
                    HUMEUR HARDIS
                  </option>
                  <option>Vert</option>
                  <option>Jaune</option>
                  <option>Rouge</option>
                </select>
                {noteHardis && (
                  <div
                    style={{
                      position: "absolute",
                      top: "50%",
                      right: "80px",
                      transform: "translateY(-50%)",
                      width: "20px",
                      height: "20px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      cursor: "pointer",
                      borderRadius: "50%",
                      backgroundColor: "#ddd",
                    }}
                    onClick={handleNoteHardisClear}
                  >
                    <ClearIcon
                      style={{
                        width: "12px",
                        height: "12px",
                        fill: "black",
                        marginLeft: "-1%",
                      }}
                    />
                  </div>
                )}
              </div>
            </div>

            <div
              className="col-3"
              style={{ position: "relative", marginLeft: "-7%" }}
            >
              <div style={{ display: "flex" }}>
                <select
                  className="form-select selector"
                  style={{
                    fontSize: "14px",
                    borderColor: "#FFFFFF",
                    width: "62%",
                  }}
                  name="practice"
                  id=""
                  onChange={handleNoteMission}
                  value={noteMission}
                >
                  <option value="" disabled selected>
                    HUMEUR MISSION
                  </option>
                  <option>Vert</option>
                  <option>Jaune</option>
                  <option>Rouge</option>
                </select>
                {noteMission && (
                  <div
                    style={{
                      position: "absolute",
                      top: "50%",
                      right: "80px",
                      transform: "translateY(-50%)",
                      width: "20px",
                      height: "20px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      cursor: "pointer",
                      borderRadius: "50%",
                      backgroundColor: "#ddd",
                    }}
                    onClick={handleNoteMissionClear}
                  >
                    <ClearIcon
                      style={{
                        width: "12px",
                        height: "12px",
                        fill: "black",
                        marginLeft: "-1%",
                      }}
                    />
                  </div>
                )}
              </div>
            </div>

            <div
              className="col-2"
              style={{ position: "relative", marginLeft: "-7%" }}
            >
              <div style={{ display: "flex" }}>
                <select
                  className="form-select selector"
                  style={{
                    fontSize: "14px",
                    borderColor: "#FFFFFF",
                    width: "72%",
                  }}
                  name="STATUT"
                  id=""
                  onChange={handleStatus}
                  value={status}
                >
                  <option value="" disabled>
                    STATUT
                  </option>
                  <option value="Actif" selected>
                    ACTIF
                  </option>
                  <option value="Inactif">INACTIF</option>
                </select>
                {status && (
                  <div
                    style={{
                      position: "absolute",
                      top: "50%",
                      right: "40px",
                      transform: "translateY(-50%)",
                      width: "20px",
                      height: "20px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      cursor: "pointer",
                      borderRadius: "50%",
                      backgroundColor: "#ddd",
                    }}
                    onClick={handleStatusClear}
                  >
                    <ClearIcon
                      style={{
                        width: "12px",
                        height: "12px",
                        fill: "black",
                        marginLeft: "-1%",
                      }}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div
          className="container container-no-shadow"
          style={{ marginTop: "3%", padding: "2%" }}
        >
          <div>
            <div className="row" style={{ marginBottom: "-14px" }}>
              <div className="col-1 text-center">
                <button
                  className="column-title sub-title column-button"
                  onClick={handleHardisNoteOrder}
                >
                  HARDIS ⇅
                </button>
              </div>
              <div className="col-1 text-center">
                <button
                  className="column-title sub-title column-button"
                  onClick={handleMissionNoteOrder}
                >
                  MISSION ⇅
                </button>
              </div>
              <div className="col-2 text-center">
                <button
                  className="column-title sub-title column-button"
                  onClick={handleNameOrder}
                >
                  PRENOM & NOM ⇅
                </button>
              </div>
              <div className="col-2 text-center">
                <button
                  className="column-title sub-title column-button"
                  onClick={handleBilanDateOrder}
                >
                  DERNIER BILAN ⇅
                </button>
              </div>
              <div className="col-2 text-center">
                <button
                  className="column-title sub-title column-button"
                  onClick={handleManagerOrder}
                >
                  MANAGER ⇅
                </button>
              </div>
              <div className="col-2 text-center">
                <button
                  className="column-title sub-title column-button"
                  onClick={handlePracticeOrder}
                >
                  PRACTICE ⇅
                </button>
              </div>
              <div className="col-1 text-center">
                <button
                  className="column-title sub-title column-button"
                  onClick={handleExitDateOrder}
                >
                  STATUT ⇅
                </button>
              </div>
              <div className="col-1 text-center">
                <p
                  className="sub-title"
                  style={{
                    whiteSpace: "normal",
                    color: "#737373",
                    fontSize: "12px",
                  }}
                >
                  NOUVEAU BILAN
                </p>
              </div>
            </div>

            {/* PARTIE DATA DES COLLAB  */}
            {/* Utiliser la fonction map() pour gérer l'affichage en fonction de la data  */}
            <div>
              {filteredCollabs &&
                filteredCollabs.map((collaborator) => (
                  <div
                    className="row"
                    style={{ marginTop: "14px" }}
                    key={collaborator.matricule}
                  >
                    <div className="center-text col-1">
                      {collaborator.note_hardis >= 7 ? (
                        <HighIcon style={{ width: "25px", height: "25px" }} />
                      ) : collaborator.note_hardis >= 4 ? (
                        <MediumIcon style={{ width: "25px", height: "25px" }} />
                      ) : (
                        <LowIcon style={{ width: "25px", height: "25px" }} />
                      )}
                    </div>
                    <div className="center-text col-1">
                      {collaborator.note_mission >= 7 ? (
                        <HighIcon style={{ width: "25px", height: "25px" }} />
                      ) : collaborator.note_mission >= 4 ? (
                        <MediumIcon style={{ width: "25px", height: "25px" }} />
                      ) : (
                        <LowIcon style={{ width: "25px", height: "25px" }} />
                      )}
                    </div>
                    <div className="center-text col-2">
                      <p
                        className="data-txt-css text"
                        onClick={() =>
                          handleClickOnCollab(collaborator.matricule)
                        }
                        style={{
                          fontSize: "16px",
                          margin: "0px",
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                        }}
                      >
                        {collaborator.firstname} {collaborator.lastname[0]}.
                      </p>
                    </div>
                    <div className="center-text col-2">
                      <p
                        className="data-txt-css"
                        style={{
                          fontSize: "16px",
                          margin: "0px",
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                        }}
                      >
                        {collaborator.bilan_date === "01/01/1901"
                          ? "-"
                          : collaborator.bilan_date
                          ? collaborator.bilan_date
                          : "-"}
                      </p>
                    </div>
                    <div className="center-text col-2">
                      <p
                        className="data-txt-css"
                        style={{
                          fontSize: "16px",
                          margin: "0px",
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                        }}
                      >
                        {collaborator.manager_name}
                      </p>
                    </div>
                    <div className="center-text col-2">
                      {collaborator.practice.length > 1 ? (
                        <p
                          className="data-txt-css"
                          style={{
                            fontSize: "16px",
                            margin: "0px",
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                            cursor: "pointer",
                          }}
                          onClick={() =>
                            handlePracticePopUp(collaborator.practice)
                          }
                        >
                          Multi Practices <i class="bi bi-info-circle-fill"></i>
                        </p>
                      ) : (
                        <p
                          className="data-txt-css"
                          style={{
                            fontSize: "16px",
                            margin: "0px",
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                          }}
                        >
                          {collaborator.practice}
                        </p>
                      )}
                    </div>
                    <div className="center-text col-1">
                      {defaultDate >= collaborator.exit_date ? (
                        <>
                          <span
                            class=" badge badge-ok badge-pill badge-primary"
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              color: "#414141",
                              fontSize: "12px",
                              fontWeight: "normal",
                              borderRadius: "20px",
                              width: "67px",
                              backgroundColor: "#E8E8E8",
                            }}
                          >
                            Inactif
                          </span>
                        </>
                      ) : (
                        <>
                          <span
                            class=" badge badge-ok badge-pill badge-primary"
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              color: "white",
                              fontSize: "12px",
                              fontWeight: "normal",
                              borderRadius: "20px",
                              width: "67px",
                              backgroundColor: "#0053FF",
                            }}
                          >
                            Actif
                          </span>
                        </>
                      )}
                    </div>
                    <div className="center-text col-1">
                      <button
                        className="center-text btn btn-primary secondary-button"
                        onClick={() => handleClickBilan(collaborator.matricule)}
                      >
                        <i class=" bi-pencil"></i>
                      </button>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
