import React, { useState, useEffect } from "react";
import TopNav from "../../../core/Component/TopNav";
import PopUp from "../../../core/Component/PopUp";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import "../css/HomeAnalytics.css";
import CollabPracticeBarChart from "./CollabPracticeBarChart"
import * as data from "./default_stats.json"

const CLOUD_RUN_DOMAIN = process.env.REACT_APP_CLOUD_RUN_DOMAIN;

export function formatDate(inputDate) {
  const [year, month] = inputDate.split('-');
  const monthNumber = parseInt(month); 
  const monthNames = [
    'Jan', 'Fev', 'Mars', 'Avr',
    'Mai', 'Juin', 'Juil', 'Aout',
    'Sept', 'Oct', 'Nov', 'Dec'
  ];
  const monthName = monthNames[monthNumber - 1];
  const formattedOutput = `${monthName} ${year.slice(-2)}`;
  return formattedOutput;
}

export function HomeAnalyticsManager() {
  let navigate = useNavigate();
  const [showSessionPopup, setShowSessionPopup] = useState(false);
  //eslint-disable-next-line
  const [loading, setLoading] = useState(true);
  const [filter, setFilter] = useState(1);
  const [stats, setStats] = useState(data);
  //eslint-disable-next-line
  const [type, setType] = useState("hardis");
  const [practiceManager, setPracticeManager] = useState([]);
  const [selectedPractice, setSelectedPractice] = useState("");
  const practices = Object.keys(stats[1]);

  const isManager = sessionStorage.getItem("admin");
// inside filter there are objects with practice names as keys
// console.log the first object to see the data structure

  const handleSessionPopup = () => {
    setShowSessionPopup(!showSessionPopup);
  };

  useEffect(() => {
    if (isManager !== "true") {
      navigate("/home-analytics");
    }
  }, [isManager, navigate]);

  useEffect(() => {
    axios
      .get(`${CLOUD_RUN_DOMAIN}/user/me`, {
        headers: {
          "Content-Type": "application/json",
          token: sessionStorage.getItem("token"),
        },
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          setShowSessionPopup(true);
          const timer = setTimeout(() => {
            navigate("/");
          }, 4000);
          return () => clearTimeout(timer);
        } else {
          navigate("/");
        }
      });
        // eslint-disable-next-line
  }, []);

  useEffect(() => {
    axios.get(`${CLOUD_RUN_DOMAIN}/practice-manager/`, {
      })
      .then((response) => {
        setPracticeManager(response.data);
          setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  }
  , []);
  useEffect(() => {
    axios.get(`${CLOUD_RUN_DOMAIN}/analytics-manager/`, {
      })
      .then((response) => {
        setStats(response.data);
        console.log(response.data);
          setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  }
  , [type, filter]);

  useEffect(() => {
    // When the user scrolls the page, execute myFunction
window.onscroll = function() {myFunction()};

// Get the navbar
var navbar = document.getElementById("stickynav");

// Get the offset position of the navbar
var sticky = navbar.offsetTop;

// Add the sticky class to the navbar when you reach its scroll position. Remove "sticky" when you leave the scroll position
function myFunction() {
  if (window.pageYOffset >= sticky) {
    navbar.classList.add("sticky")
  } else {
    navbar.classList.remove("sticky");
  }
}

  }, []);


  return (
    <>
      <PopUp
        message="Session expirée, veuillez vous reconnecter."
        show={showSessionPopup}
        onClose={handleSessionPopup}
      />
      <TopNav />

      <div
        className="mb-5"
        style={{width: "100%", textAlign: "left", position: "absolute", left: "0%", paddingLeft: "12%", paddingRight: "5%", paddingTop: "10%" }}
      >
        <p className="text-uppercase h4 fw-bold">
          Statistiques par practice
        </p>

        {/* filtre */}
        <label htmlFor="filter" className="h6 mt-5">Période</label>
      <div className="sub-block" id="stickynav">
      <div className="filter-options">
    <button
      className={`filter-option ${filter === 12 && 'selected'}`}
      onClick={() => setFilter(12)}
    >
      12m
    </button>
    <button
      className={`filter-option ${filter === 6 && 'selected'}`}
      onClick={() => setFilter(6)}
    >
      6m
    </button>
    <button
      className={`filter-option ${filter === 3 && 'selected'}`}
      onClick={() => setFilter(3)}
    >
      3m
    </button>
    <button
      className={`filter-option ${filter === 1 && 'selected'}`}
      onClick={() => setFilter(1)}
    >
      1m
    </button>
  </div>
  <div className="filter-options" style={{ padding: "10px" }}>
  <select style={{ border: "none", outline: "none", appearance: "none", background: "transparent", padding: "0", margin: "0", fontSize: "inherit", fontFamily: "inherit", cursor: "pointer", width: "250px", paddingLeft: "4px" }} value={selectedPractice} onChange={(e) => setSelectedPractice(e.target.value)}>
    <option value="">Toutes les practices</option>
    {practices.map((practice) => (
      <option key={practice} value={practice}>{practice}</option>
    ))}
  </select>
  {/* {selectedPractice && (
    <button style={{ border: "none", background: "transparent", padding: "0", margin: "0", fontSize: "inherit", fontFamily: "inherit", cursor: "pointer" }} onClick={() => setSelectedPractice('')}>
      &#10006;
    </button>
  )} */}
</div>


      </div>

 
      <div className="blocks" style={{marginTop: "5%", marginBottom: "5%"}}>
  {!selectedPractice && (
    <>
      {practices.map((practice) => (
        <div key={`${practice}`} style={{ backgroundColor: "white", borderRadius: "10px", display: "grid" }}>
            <p className="text-uppercase h6 fw-bold mb-4 mt-4" style={{ alignSelf: "center", justifySelf: "center"}}>
                    {practice}
                  </p>
        <div key={`chart-${practice}`} className="chart-container-practice" style={{padding: "1%" }}>
          {practiceManager[practice] && (
            <>
              <CollabPracticeBarChart
                data={stats[filter][practice]}
                type="hardis"
                practice={practice}
                manager={practiceManager[practice]}
                className="chart-item-practice"
              />
              <CollabPracticeBarChart
                data={stats[filter][practice]}
                type="mission"
                practice={practice}
                manager={practiceManager[practice]}
                className="chart-item-practice"
              />
            </>
          )}
        </div>
        </div>
      ))}
    </>
  )}

  {selectedPractice && (
            <div key={`${selectedPractice}`} style={{ backgroundColor: "white", borderRadius: "10px", display: "grid" }}>
            <p className="text-uppercase h6 fw-bold mb-4 mt-4" style={{ alignSelf: "center", justifySelf: "center"}}>
                    {selectedPractice}
                  </p>
    <div key={`chart-${selectedPractice}`} className="chart-container-practice" style={{ backgroundColor: "white", padding: "1%", borderRadius: "10px" }}>
      {practiceManager[selectedPractice] && (
        <>
          <CollabPracticeBarChart
            data={stats[filter][selectedPractice]}
            type="hardis"
            practice={selectedPractice}
            manager={practiceManager[selectedPractice]}
            className="chart-item-practice"
          />
          <CollabPracticeBarChart
            data={stats[filter][selectedPractice]}
            type="mission"
            practice={selectedPractice}
            manager={practiceManager[selectedPractice]}
            className="chart-item-practice"
          />
        </>
      )}
    </div>
    </div>
  )}

</div>

      </div>
    </>
  );
}