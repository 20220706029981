import React, { useState, forwardRef, useImperativeHandle, useEffect } from "react";
import "../css/InputEvaluation.css";
import axios from 'axios';
 
 
const InputEvaluation = forwardRef((props, _ref) => {
  const date = new Date();
  const defaultDate = date.toLocaleDateString("en-CA");
 
  const [inputDate, setInputDate] = useState(defaultDate);
  const [noteHardis, setNoteHardis] = useState("1");
  const [noteMission, setNoteMission] = useState("1");
 
  const CLOUD_RUN_DOMAIN = process.env.REACT_APP_CLOUD_RUN_DOMAIN;
 
  async function getData2(matricule) {
    try {
      const response = await axios.get(`${CLOUD_RUN_DOMAIN}/draft/${matricule}`,
      {
        headers: {
          "Content-Type": "application/json",
          token: sessionStorage.getItem("token"),
        },
      }
      );
     
 
      if (response.data ) {
        const draft = response.data;
        setNoteHardis(draft.hardis_note);
        setNoteMission(draft.mission_note);
        console.log(draft)
      } else {
        // Gérer le cas où la propriété draft n'est pas présente dans la réponse
        console.error("La propriété 'draft' n'est pas présente dans la réponse.");
      }
    } catch (error) {
      // Gérer les erreurs de la requête
      console.error("Erreur lors de la récupération des données :", error);
    }
  }
  useEffect(() => {
    //const draft=JSON.parse(sessionStorage.getItem('draftBilan '+sessionStorage.getItem("selectedMatricule") ));
    /*const draft= axios.get(`/draft/${draft.matricule}`)
    if (draft){
          setNoteHardis(draft.hardis_note);
          setNoteMission(draft.mission_note);          
    }*/
    console.log(sessionStorage.getItem("selectedMatricule"))
    getData2(sessionStorage.getItem("selectedMatricule"))
      // eslint-disable-next-line
  },[]);
 
  const handleChangeDate = (event) => {
    const date = event.target.value;
    setInputDate(date);
  };
  const handleNoteHardis = (event) => {
    const noteHardis = event.target.value;
    // cannot be more than 10
    if (noteHardis > 10) {
      setNoteHardis(10);
    } else {
    setNoteHardis(noteHardis);
    }
  };
 
  const handleInputMission = (event) => {
    const noteMission = event.target.value;
    // cannot be more than 10
    if (noteMission > 10) {
      setNoteMission(10);
    } else {
    setNoteMission(noteMission);
    }
  };
 
  useImperativeHandle(_ref, () => ({
    getInputDate: () => {
      return inputDate;
    },
    getNoteHardis: () => {
      return noteHardis;
    },
    getNoteMission: () => {
      return noteMission;
    },
  }));
 
  return (
    <div className="row" style={{ display: "block"}}>
      <div class="col-6">
        <label style={{ fontSize: "14px" }}>Date*</label>
        <input
          class="number-input form-control"
          style={{ width: "80%" }}
          value={inputDate}
          defaultValue={defaultDate}
          name="date"
          type="date"
          onChange={handleChangeDate}
        />
      </div>
      <div class="row" style={{ paddingTop: "2.5%", display: "inline-flex" }}>
        <div class="col-3" style={{ marginRight: "26%" }}>
          <label style={{ fontSize: "14px", display: "flex" }}>Satisfaction Hardis (1-10)*</label>
          <input
            class="number-input form-control"
            style={{ width: "60%" }}
            name="satisfaction-hardis"
            type="number"
            value={noteHardis}
            defaultValue={1}
            min={1}
            max={10}
            onChange={handleNoteHardis}
          />
        </div>
 
        <div class="col-3">
          <label style={{ fontSize: "14px", display: "flex"  }}>Satisfaction Mission (1-10)*</label>
          <input
            class="number-input form-control"
            style={{ width: "60%" }}
            type="number"
            name="satisfaction-mission"
            value={noteMission}
            defaultValue={1}
            min={1}
            max={10}
            onChange={handleInputMission}
          />
        </div>
      </div>
    </div>
  );
});
 
export default React.memo(InputEvaluation);
 