import "./../css/Vertical.css";
import logo_hardis from "../../Views/Auth/Components/Assets/logo.svg";

export function VerticalNav() {

  return (
    <div className="container hidden-xs">
      <div class="test-ver row " style={{zIndex: "9998"}}>
        <div class="col-sm-auto  ">
          <div class="d-flex flex-sm-column flex-row  align-items-center">
            <img className="img-logo" alt="logo-hardis" src={logo_hardis} />
            <ul class="nav nav-flush flex-sm-column flex-row flex-wrap">
              {<li>
                <a
                  href="/home-analytics"
                  class=" py-3 px-2"
                  title=""
                  data-bs-toggle="tooltip"
                  data-bs-placement="right"
                  data-bs-original-title="Accueil"
                >
                  <i class="bi bi-house-door-fill fs-3"></i>
                </a>
              </li> }
              {(sessionStorage.getItem('admin') === "true") ? (
                <li>
                  <a
                    href="/analytics-manager"
                    class=" py-3 px-2"
                    title=""
                    data-bs-toggle="tooltip"
                    data-bs-placement="right"
                    data-bs-original-title="Dashboard"
                  >
                  <i class="bi bi-graph-up fs-3"></i>
                  </a>
                </li>
              ) : null}
              <li>
                <a
                  href="/view-mycollabs"
                  class=" py-3 px-2"
                  title=""
                  data-bs-toggle="tooltip"
                  data-bs-placement="right"
                  data-bs-original-title="MyCollabs"
                >
                <i class="bi bi-people-fill fs-3"></i>
                </a>
              </li> 
              {(sessionStorage.getItem('admin') === "true") ? (
                <li>
                  <a
                    href="/view-admin"
                    class=" py-3 px-2"
                    title=""
                    data-bs-toggle="tooltip"
                    data-bs-placement="right"
                    data-bs-original-title="Dashboard"
                  >
                  <i class="bi bi-grid-fill fs-3"></i>
                  </a>
                </li>
              ) : null}
              {/* last list should be fixed at the bottom */}
              <div style={{ position: "absolute", bottom: "20px" }}>
              <li>
                <a
                  href="/update-password"
                  class="px-2"
                  title=""
                  data-bs-toggle="tooltip"
                  data-bs-placement="right"
                  data-bs-original-title="Changer de mot de passe"
                >
                <i class="bi bi-key-fill fs-3"></i>
                </a>
              </li>
              <li>
              <a
                  href="/"
                  class="nav-link px-2"
                  title=""
                  data-bs-toggle="tooltip"
                  data-bs-placement="right"
                  data-bs-original-title="Customers"
                >
                  <i class="bi bi-box-arrow-right fs-3"></i>
                </a>
              </li>
              </div>
            </ul>
          </div>
          <div class="col-sm p-3 min-vh-100" />
        </div>
      </div>
    </div>
  );
}
