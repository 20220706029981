import React from "react";
import { VerticalNav } from "./VerticalNav";
import "./../css/TopBar.css";

function TopNav() {
  return (
    <>
    <VerticalNav/>
      <nav class="navbar navbar-hardis fixed-top bg-body-tertiary" style={{zIndex: "9998", height: "58px"}}>
        <div class="container-fluid">
          <h3 class="navbar-brand text-white" style={{ marginBottom: "0"}}>Appli Collab</h3>
        </div>
      </nav>
    </>
  );
}

export default TopNav;
