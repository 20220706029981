import React, { useState, useEffect } from "react";
import "./Login.css";
import { useNavigate } from "react-router-dom";
import logo from "./Assets/logo.svg";
import { useUserAuth } from "../../../context/AuthContext";
import axios from "axios";

const CLOUD_RUN_DOMAIN = process.env.REACT_APP_CLOUD_RUN_DOMAIN;
const APP_VERSION = "v0.1.0";

export default function Login() {
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [customErrorMessage, setCustomErrorMessage] = useState("");

  const { logIn } = useUserAuth();
  let navigate = useNavigate();
  const logoHardis = logo;

  async function load_data() {
    const token = sessionStorage.getItem("token");
    const promiseList = [];

    promiseList.push(
      axios.get(`${CLOUD_RUN_DOMAIN}/user/me`, {
        headers: {
          "Content-Type": "application/json",
          token: token,
        },
      })
    );

    promiseList.push(
      axios.get(`${CLOUD_RUN_DOMAIN}/infos/practice`, {
        headers: {
          "Content-Type": "application/json",
          token: token,
        },
      })
    );

    promiseList.push(
      axios.get(`${CLOUD_RUN_DOMAIN}/infos/manager`, {
        headers: {
          "Content-Type": "application/json",
          token: token,
        },
      })
    );

    promiseList.push(
      axios.get(`${CLOUD_RUN_DOMAIN}/infos/pl`, {
        headers: {
          "Content-Type": "application/json",
          token: token,
        },
      })
    );

    return Promise.all(promiseList)
      .then(([resAdmin, resPractice, resManager, resPl]) => {
        sessionStorage.setItem("admin", resAdmin.data.is_manager);
        sessionStorage.setItem("practice", JSON.stringify(resPractice.data));
        sessionStorage.setItem("manager", JSON.stringify(resManager.data));
        sessionStorage.setItem("pl", JSON.stringify(resPl.data));
      })
      .catch((error) => {
        console.error(error);
      });
  }

  useEffect(() => {
    sessionStorage.setItem("token", "");
  }, []);

  useEffect(() => {
    checkServerHealth();

    const intervalId = setInterval(() => {
        checkServerHealth();
    }, 5 * 60 * 1000);

    return () => clearInterval(intervalId);
  }, []);

  const checkServerHealth = async () => {
    try {
        const response = await axios.get(`${CLOUD_RUN_DOMAIN}/health`);
        console.log('Succès lors de la vérification de la santé du serveur:', response);
    } catch (error) {
        console.error('Erreur lors de la vérification de la santé du serveur:', error);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      let t = await logIn(email, password);
      sessionStorage.setItem("token", t._tokenResponse.idToken);
      await load_data();
      navigate("/home-analytics");
    } catch (err) {
      setCustomErrorMessage("Adresse email ou mot de passe incorrect.");
    }
  };

  const handleChangeEmail = (event) => {
    setEmail(event.target.value);
  };

  const handleChangePass = (event) => {
    setPassword(event.target.value);
  };

  return (
    <div className="text-center">
      <div className="form-signin w-100 m-auto">
        <form onSubmit={handleSubmit}>
          {/* Title */}
          {customErrorMessage && (
            <div
              class="alert alert-primary"
              style={{ color: "#737373" }}
              role="alert"
            >
              {customErrorMessage}
            </div>
          )}
          <img className="mb-5 img-home-logo" src={logoHardis} alt="logo_hardis" />
          <h1 className="h4 mb-3 fw-">CONNEXION</h1>

          {/* Input email */}
          <div className="form-floating">
            <input
              type="email"
              onChange={handleChangeEmail}
              value={email}
              className="form-control"
              id="floatingInput"
              placeholder="name@example.com"
              autocomplete="email"
            />
            <label htmlFor="floatingInput">Adresse email</label>
          </div>

          {/* Input password */}
          <div className="form-floating">
            <input
              type="password"
              className="form-control"
              id="floatingPassword"
              onChange={handleChangePass}
              value={password}
              placeholder="Password"
              autocomplete="current-password"
            />
            <label htmlFor="floatingPassword">Mot de passe</label>
          </div>

          {/* Checkbox */}
          <div className="checkbox row">
            <label className="col">
              <input type="checkbox" value="stay-connect" /> Se souvenir de moi
            </label>
            <p className="text-muted col">
              <a className="anchor-link" href="/reset-password">
                Mot de passe oublié ?
              </a>
            </p>
          </div>

          {/* Button */}
          <button className="w-100 btn btn-lg btn-color" type="submit">
            Se connecter
          </button>
          <p className="mt-5 mb-3 text-muted">
            Votre compte n'est pas activé ?{" "}
            <a className="anchor-link" href="/signup">
              Cliquez ici.
            </a>
          </p>
          <label
            style={{
              marginTop: "20%",
              color: "#737373",
              fontSize: "8px",
              fontStyle: "italic",
            }}
          >
            {APP_VERSION}
          </label>
        </form>
      </div>
    </div>
  );
}
