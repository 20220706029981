/* eslint-disable */
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import "../css/Modif_PopUp.css";
import "../css/AddMission.css";
import PopUp from "../../../core/Component/PopUp";
import { useUserAuth } from '../../../context/AuthContext.js';

const CLOUD_RUN_DOMAIN = process.env.REACT_APP_CLOUD_RUN_DOMAIN;


function PopupModifier(props) {
  let navigate = useNavigate();
  const { user } = useUserAuth();
  const [lastname, setLastname] = useState("");
  const [firstname, setFirstname] = useState("");
  const [uid, setUID] = useState("");
  const [email, setEmail] = useState("");
  const [job, setJob] = useState("");
  const [matricule, setMatricule] = useState("");
  const [practice, setPractice] = useState([]);
  const [manager, setManager] = useState("");
  const [pl, setPL] = useState("");
  const [dateEntree, setDateEntree] = useState("");
  const [dateSortie, setDateSortie] = useState("");
  const [num, setNum] = useState("");
  const [status, setStatus] = useState("");
  const [managers, setManagers] = useState([]);
  const [peopleLeaders, setPeopleLeaders] = useState([]);
  const [practices, setPractices] = useState([]);
  const [showConfirmationPopup, setShowConfirmationPopup] = useState(false);
  const [showErrorPopup, setShowErrorPopup] = useState(false);
  const [showSessionPopup, setShowSessionPopup] = useState(false);
  const [showFirstMission, setShowFirstMission] = useState(false);
  const [showSecondMission, setShowSecondMission] = useState(false);
  const [actionToMission1, setActionToMission1] = useState("create");
  const [actionToMission2, setActionToMission2] = useState("create");
  const [nomClient1, setNomClient1] = useState("");
  const [nomClient2, setNomClient2] = useState("");
  const [emailClient1, setEmailClient1] = useState("");
  const [emailClient2, setEmailClient2] = useState("");
  const [descriptionMission1, setdescriptionMission1] = useState("");
  const [descriptionMission2, setdescriptionMission2] = useState("");
  const [missionId1, setMissionId1] = useState("");
  const [missionId2, setMissionId2] = useState("");
  const [flagToDeleteMission1, setFlagToDeleteMission1] = useState(false);
  const [flagToDeleteMission2, setFlagToDeleteMission2] = useState(false);
  const [lastBilan, setLastBilan] = useState("");
  const [noteHardis, setNoteHardis] = useState(0);
  const [noteMission, setNoteMission] = useState(0);
  const [bilanDate, setBilanDate] = useState("");

  const handlenomChange = (event) => {
    setLastname(event.target.value);
  };
  const handleprenomChange = (event) => {
    setFirstname(event.target.value);
  };
  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };
  const handlejobChange = (event) => {
    setJob(event.target.value);
  };
 
  const handlematriculeChange = (event) => {
    setMatricule(event.target.value);
  };
  const handlePractice = (event) => {
    const selectedPractice = event.target.value;
    if (practice.includes(selectedPractice)) {
      const updatedPractice = practice.filter(item => item !== selectedPractice);
      setPractice(updatedPractice);
    } else {
      const updatedPractice = [...practice, selectedPractice];
      setPractice(updatedPractice);
    }
  };
  const handlemanagerChange = (event) => {
    setManager(event.target.value);
  };
  const handleplChange = (event) => {
    setPL(event.target.value);
  };
  const handledateEntreeChange = (event) => {
    setDateEntree(event.target.value);
  };
  const handledateSortieChange = (event) => {
    setDateSortie(event.target.value);
  };
  const handlenumChange = (event) => {
    setNum(event.target.value);
  };
  const handleNomClient1 = (event) => {
    setNomClient1(event.target.value);
  };
  const handleNomClient2 = (event) => {
    setNomClient2(event.target.value);
  };
  const handleEmailClient1 = (event) => {
    setEmailClient1(event.target.value);
  };
  const handleEmailClient2 = (event) => {
    setEmailClient2(event.target.value);
  };
  const handleDescriptionMission1 = (event) => {
    setdescriptionMission1(event.target.value);
  };
  const handleDescriptionMission2 = (event) => {
    setdescriptionMission2(event.target.value);
  };

  const handleConfirmationPopup = () => {
    setShowConfirmationPopup(!showConfirmationPopup);
  };

  const handleErrorPopup = () => {
    setShowErrorPopup(!showErrorPopup);
  };

  const handleSessionPopup = () => {
    setShowSessionPopup(!showSessionPopup);
  };

  const toggleFirstMission = () => {
    setShowFirstMission(true);
  };

  const toggleSecondMission = () => {
    setShowSecondMission(true);
  };

  //Gets the collaborator info
  useEffect(() => {
    const fetchUserInfo = async () => {
      try {
        const response = await axios.get(
          `${CLOUD_RUN_DOMAIN}/collaborator/${sessionStorage.getItem(
            "selectedMatricule"
          )}`,
          {
            headers: {
              "Content-Type": "application/json",
              token: sessionStorage.getItem("token"),
            },
          }
        );

        const userInfo = response.data;
        console.log(userInfo);
        const noteHardisToNumber = parseInt(userInfo.note_hardis);
        const noteMissionToNumber = parseInt(userInfo.note_mission);
        setUID(userInfo.uid);
        setLastname(userInfo.lastname);
        setFirstname(userInfo.firstname);
        setEmail(userInfo.email.split("@")[0]);
        setJob(userInfo.job);
        setMatricule(userInfo.matricule);
        setPractice(userInfo.practice);
        setManager(userInfo.manager_uid);
        setPL(userInfo.people_leader_uid);
        setDateEntree(userInfo.entrance_date);
        setDateSortie(userInfo.exit_date);
        setStatus(userInfo.status);
        setNum(userInfo.tel);
        setLastBilan(userInfo.last_bilan);
        setNoteHardis(noteHardisToNumber);
        setNoteMission(noteMissionToNumber);
        setBilanDate(userInfo.bilan_date);
      } catch (error) {
        console.log(error);
      }
      //Gets the info of his missions
      try {
        const selectedMatricule = sessionStorage.getItem("selectedMatricule");
        const response = await axios.get(
          `${CLOUD_RUN_DOMAIN}/mission/${selectedMatricule}`,
          {
            headers: {
              "Content-Type": "application/json",
              token: sessionStorage.getItem("token"),
            },
          }
        );
        if (response.data.length > 0) {
          setNomClient1(response.data[0].client_name);
          setEmailClient1(response.data[0].email_client);
          setdescriptionMission1(response.data[0].description);
          setMissionId1(response.data[0].mission_id);
          setShowFirstMission(true);
          setActionToMission1("update");
        }
        if (response.data.length > 1) {
          setNomClient2(response.data[1].client_name);
          setEmailClient2(response.data[1].email_client);
          setdescriptionMission2(response.data[1].description);
          setMissionId2(response.data[1].mission_id);
          setShowSecondMission(true);
          setActionToMission2("update");
        }
      } catch (error) {
        console.log(error);
      }
    };

    fetchUserInfo();
  }, []
  );

  //Gets the manager list
  useEffect(() => {
    axios
      .get(`${CLOUD_RUN_DOMAIN}/infos/manager`, {
        params: {
          practice: practice,
        },
        headers: {
          "Content-Type": "application/json",
          token: sessionStorage.getItem("token"),
        },
      })
      .then((response) => {
        console.log(response.data);
        setManagers(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [practice]);

  //Gets the people leader list
  useEffect(() => {
    setPeopleLeaders(JSON.parse(sessionStorage.getItem("pl")));
  }, []);

  useEffect(() => {
    axios
      .get(`${CLOUD_RUN_DOMAIN}/infos/practice`, {
        headers: {
          "Content-Type": "application/json",
          token: sessionStorage.getItem("token"),
        },
      })
      .then((response) => {
        console.log(response.data);
        setPractices(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  // Flag to delete mission 1
  const handleDeleteMission1 = async (event) => {
    event.preventDefault();
    setFlagToDeleteMission1(true);
  };

  // Flag to delete mission 2
  const handleDeleteMission2 = async (event) => {
    event.preventDefault();
    setFlagToDeleteMission2(true);
  };

  //Perform the action(s) when clicking on "Valider"
  const handleSubmit = async (event) => {
    event.preventDefault();
      try {
        //Convert empty fields to null to avoid validation of empty non-optional fields
        if (practice === "") {
          setPractice(null);
        }
        if (dateEntree === "") {
          setDateEntree(null);
        }
        if (dateSortie === "") {
          setDateSortie(null);
        }
        if (lastname === "") {
          setLastname(null);
        }
        if (firstname === "") {
          setFirstname(null);
        }
        if (email === "") {
          setEmail(null);
        }
        if (job === "") {
          setJob(null);
        }
     
        if (num === "") {
          setNum(null);
        }
        if (nomClient1 === "") {
          setNomClient1(null);
        }
        if (nomClient2 === "") {
          setNomClient2(null);
        }
        if (emailClient1 === "") {
          setEmailClient1(null);
        }
        if (emailClient2 === "") {
          setEmailClient2(null);
        }
        if (descriptionMission1 === "") {
          setdescriptionMission1(null);
        }
        if (descriptionMission2 === "") {
          setdescriptionMission2(null);
        }
        //Update the collaborator info
        console.log(lastBilan, noteHardis, noteMission);
        await axios.put(
          `${CLOUD_RUN_DOMAIN}/collaborator/${matricule}`,
          {
            lastname: lastname.toUpperCase(),
            firstname: firstname,
            email: email.split("@")[0] + "@hardis-group.com",
            job: job,
            tel: num,
            status: status,
            matricule: matricule,
            practice: practice,
            manager_uid: manager,
            people_leader_uid: pl,
            entrance_date: dateEntree,
            exit_date: dateSortie,
            account_uid: uid,
            last_bilan: lastBilan,
            note_hardis: parseInt(noteHardis),
            note_mission: parseInt(noteMission),
            bilan_date: bilanDate,
          },
          {
            headers: {
              "Content-Type": "application/json",
              token: sessionStorage.getItem("token"),
            },
          }
        );
        //Perform an action for mission one if it exists
        if (showFirstMission) {
          if (actionToMission1 === "create") {
            //Generating the mission id via hash
              // eslint-disable-next-line
            String.prototype.hashCode = function () {
              var hash = 0,
                i,
                chr;
              if (this.length === 0) return hash;
              for (i = 0; i < this.length; i++) {
                chr = this.charCodeAt(i);
                hash = (hash << 5) - hash + chr;
                hash |= 0; // Convert to 32bit integer
              }
              return hash;
            };
            const str =
              matricule + nomClient1 + emailClient1 + descriptionMission1;
            const encoder = new TextEncoder();
            const data = encoder.encode(str);
            const hashBuffer = await crypto.subtle.digest("SHA-256", data);
            const hashArray = Array.from(new Uint8Array(hashBuffer));
            const hashHex = hashArray
              .map((byte) => byte.toString(16).padStart(2, "0"))
              .join("");
            await axios.post(
              `${CLOUD_RUN_DOMAIN}/mission`,
              {
                matricule: matricule,
                mission_id: hashHex,
                client_name: nomClient1.toUpperCase(),
                email_client: emailClient1,
                description: descriptionMission1,
              },
              {
                headers: {
                  "Content-Type": "application/json",
                  token: sessionStorage.getItem("token"),
                },
              }
            );
          }
          //Execute this instead if the mission already exists
          else {
            await axios.put(
              `${CLOUD_RUN_DOMAIN}/mission/${missionId1}`,
              {
                matricule: matricule,
                mission_id: missionId1,
                client_name: nomClient1.toUpperCase(),
                email_client: emailClient1,
                description: descriptionMission1,
              },
              {
                headers: {
                  "Content-Type": "application/json",
                  token: sessionStorage.getItem("token"),
                },
              }
            );
          }
          // Delete mission 1 if flag is true
          if (flagToDeleteMission1) {
            await axios.delete(`${CLOUD_RUN_DOMAIN}/mission/${missionId1}`, {
              params: {
                mission_id: missionId1,
              },
              headers: {
                "Content-Type": "application/json",
                token: sessionStorage.getItem("token"),
              },
            });
          }
        }
        //Perform an action for mission two if it exists
        if (showSecondMission) {
          if (actionToMission2 === "create") {
            //Generating the mission id via hash
              // eslint-disable-next-line
            String.prototype.hashCode = function () {
              var hash = 0,
                i,
                chr;
              if (this.length === 0) return hash;
              for (i = 0; i < this.length; i++) {
                chr = this.charCodeAt(i);
                hash = (hash << 5) - hash + chr;
                hash |= 0;
              }
              return hash;
            };
            const str =
              matricule + nomClient2 + emailClient2 + descriptionMission2;
            const encoder = new TextEncoder();
            const data = encoder.encode(str);
            const hashBuffer = await crypto.subtle.digest("SHA-256", data);
            const hashArray = Array.from(new Uint8Array(hashBuffer));
            const hashHex = hashArray
              .map((byte) => byte.toString(16).padStart(2, "0"))
              .join("");
            await axios.post(
              `${CLOUD_RUN_DOMAIN}/mission`,
              {
                matricule: matricule,
                mission_id: hashHex,
                client_name: nomClient2.toUpperCase(),
                email_client: emailClient2,
                description: descriptionMission2,
              },
              {
                headers: {
                  "Content-Type": "application/json",
                  token: sessionStorage.getItem("token"),
                },
              }
            );
          }
          //Execute this instead if the mission already exists
          else {
            await axios.put(
              `${CLOUD_RUN_DOMAIN}/mission/${missionId2}`,
              {
                matricule: matricule,
                mission_id: missionId2,
                client_name: nomClient2.toUpperCase(),
                email_client: emailClient2,
                description: descriptionMission2,
              },
              {
                headers: {
                  "Content-Type": "application/json",
                  token: sessionStorage.getItem("token"),
                },
              }
            );
          }
  
          // Delete mission 2 if flag is true
          if (flagToDeleteMission2) {
            await axios.delete(`${CLOUD_RUN_DOMAIN}/mission/${missionId2}`, {
              params: {
                mission_id: missionId2,
              },
              headers: {
                "Content-Type": "application/json",
                token: sessionStorage.getItem("token"),
              },
            });
          }
        }
        //Dismiss the modal, displays a confirmation message and refreshes the page
        props.setTrigger(false);
        setShowConfirmationPopup(true);
        if (user) {
          user
            .getIdTokenResult()
            .then((idTokenResult) => {
              user.getIdToken(true).then((newIdToken) => {
                // Log the refreshed ID token
                console.log("Refreshed ID Token:", newIdToken);
              });
            })
            .catch((error) => {
              console.error('Error fetching ID token result:', error);
            });
        }
        const timer = setTimeout(() => {
          window.location.reload();
        }, 2000);
        return () => clearTimeout(timer);
      } catch (error) {
          // eslint-disable-next-line
        if (error.response && error.response.status === 401 || error.response.status === 402) {
          setShowSessionPopup(true);
          const timer = setTimeout(() => {
            navigate('/');
          }, 4000);
          return () => clearTimeout(timer);
        }
        else {
          setShowErrorPopup(true);
          console.log(error);
        }
      }
  };

  // Handles the click on the "Annuler" button
  const handleCancel = () => {
    setFlagToDeleteMission1(false);
    setFlagToDeleteMission2(false);
    props.setTrigger(false);
  };

  return (
    <>
      {/*Confirmation and pop-up messages*/}
      <PopUp
        message="Le collaborateur a été modifié avec succès."
        show={showConfirmationPopup}
        onClose={handleConfirmationPopup}
      />
      <PopUp
        message="Veuillez correctement remplir les champs nécessaires avant de valider."
        show={showErrorPopup}
        onClose={handleErrorPopup}
      />
      <PopUp message="Session expirée, veuillez vous reconnecter." show={showSessionPopup} onClose={handleSessionPopup} />
      {props.trigger && (
        <div
          className="modal modal-bis"
          style={{ width: "100vw", height: "110vh" }}
        >
          <div
            onClick={() => handleCancel()}
            className="overlay"
            style={{ width: "110vw", height: "110vh" }}
          ></div>
          <div className="modal-content modal-content-bis">
            <div className="row" style={{ paddingTop: "4%" }}>
              <div className="col">
                <h6 style={{  color: "#0053FF", fontSize: "22px" }}>
                  <b>Informations générales</b>
                </h6>
              </div>
            </div>

            <form>
              <div className="row" style={{ paddingTop: "2%" }}>
                <div class="col">
                  <span style={{ color: "#000094", fontSize: "18px", fontWeight: "500"}}>
                    Informations personnelles
                  </span>
                  <br />
                  <label style={{ fontSize: "14px" }} htmlFor="nom">
                    Nom*
                  </label>
                  <textarea
                    className="form-control"
                    required
                    style={{
                      width: "90%",
                      height: "5%",
                      fontSize: "14px",
                      resize: "none",
                      borderRadius: "4px",
                      borderColor: "#C4C4C4",
                    }}
                    id="nom"
                    value={lastname}
                    onChange={handlenomChange}
                  />
                </div>
                <div className="col">
                  <span style={{ color: "#000094", fontSize: "18px", fontWeight: "500"}}>
                    Informations Hardis
                  </span>
                  <br />
                  <label style={{ fontSize: "14px" }} htmlFor="matricule">
                    Matricule
                  </label>
                  <textarea
                    readOnly={true}
                    disabled={true}
                    style={{
                      width: "90%",
                      height: "5%",
                      fontSize: "14px",
                      resize: "none",
                      borderRadius: "4px",
                      borderColor: "#C4C4C4",
                    }}
                    className="form-control"
                    id="matricule"
                    value={matricule}
                    onChange={handlematriculeChange}
                  />
                </div>
              </div>

              <div className="row custom-div" style={{ paddingTop: "0.5%" }}>
                <div class="col">
                  <label style={{ fontSize: "14px" }} htmlFor="prenom">
                    Prénom*
                  </label>
                  <textarea
                    required
                    className="form-control"
                    style={{
                      width: "90%",
                      height: "5%",
                      fontSize: "14px",
                      resize: "none",
                      borderRadius: "4px",
                      borderColor: "#C4C4C4",
                    }}
                    id="prenom"
                    value={firstname}
                    onChange={handleprenomChange}
                  />
                </div>
                <div className="col">
                  <label style={{ fontSize: "14px" }} htmlFor="">
                    Practice*
                  </label>
                  <div className="practice-checkboxes" style={{ width: "90%", marginLeft: "15px" }}>
                    <div className="row">
                      {practices && practices.map((practiceItem, index) => (
                        <div key={practiceItem} className="form-check col-6">
                          <input 
                            className="form-check-input" 
                            type="checkbox" 
                            value={practiceItem} 
                            id={`practice-${practiceItem}`}
                            checked={practice.includes(practiceItem)}
                            onChange={handlePractice}
                          />
                          <label className="form-check-label" htmlFor={`practice-${practiceItem}`}>
                            {practiceItem}
                          </label>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div style={{ marginBottom: "20px" }}>
                    <span style={{ color: "#000094", fontSize: "14px" }}>
                      {practice.length} practice(s) sélectionnée(s)
                    </span>
                  </div>
                </div>
              </div>

              <div className="row custom-div" style={{ paddingTop: "0.5%" }}>
                <div class="col">
                  <label style={{ fontSize: "14px" }} htmlFor="" required>
                    Email Hardis*
                  </label>
                  <div class="input-group" style={{ width: "90%" }}>
                    <input
                      type="text"
                      class="form-control"
                      aria-describedby="basic-addon2"
                      style={{
                        width: "57%",
                        fontSize: "14px",
                        resize: "none",
                        borderRadius: "4px 0px 0px 4px",
                        borderColor: "#C4C4C4",
                      }}
                      onChange={handleEmailChange}
                      value={email}
                    ></input>
                    <span
                      class="input-group-text text-center"
                      id="basic-addon2"
                      style={{
                        width: "43%",
                        height: "5%",
                        fontSize: "14px",
                        resize: "none",
                        borderRadius: "0px 4px 4px 0px",
                        borderColor: "#C4C4C4",
                        color: "#121212",
                        alignItems: "center",
                        display: "block",
                      }}
                    >
                      @hardis-group.com
                    </span>
                  </div>
                </div>
                <div className="col">
                  <label style={{ fontSize: "14px" }} htmlFor="manager">
                    Manager*
                  </label>
                  <select
                    className="number-input form-select"
                    required
                    id="manager"
                    onChange={handlemanagerChange}
                    value={manager}
                  >
                    <option value="" selected>
                      Aucun
                    </option>
                    {managers &&
                      managers.map((manager) => (
                        <option key={manager.uid} value={manager.uid}>
                          {manager.Name} {manager.LastName}
                        </option>
                      ))}
                  </select>
                </div>
              </div>

              <div className="row" style={{ paddingTop: "1%" }}>
                <div class="col">
                  <label style={{ fontSize: "14px" }} htmlFor="num">
                    Téléphone
                  </label>
                  <textarea
                    className="text-mission form-control"
                    required
                    id="num"
                    value={num}
                    onChange={handlenumChange}
                  />
                </div>

                <div className="col">
                  <label style={{ fontSize: "14px" }} htmlFor="pl">
                    People leader*
                  </label>
                  <select
                    className="number-input form-select"
                    required
                    id="pl"
                    onChange={handleplChange}
                    value={pl}
                  >
                    <option value="" selected>
                      Aucun
                    </option>
                    {peopleLeaders &&
                      peopleLeaders.map((peopleLeader) => (
                        <option key={peopleLeader.uid} value={peopleLeader.uid}>
                          {peopleLeader.Name} {peopleLeader.LastName}
                        </option>
                      ))}
                  </select>
                </div>
              </div>

              <div className="row custom-div" style={{ paddingTop: "0.5%" }}>
                <div className="col-6">
                <label style={{ fontSize: "14px" }} htmlFor="Metier">
      Métier*
    </label>
    <select
      className="form-select"
      required
      id="Job"
      style={{
        width: "90%",
        fontSize: "14px",
        resize: "none",
        borderRadius: "4px",
        borderColor: "#C4C4C4",
      }}
      value={job}
      onChange={handlejobChange}
    >
      <option value="" disabled selected>
        Sélectionnez un métier
      </option>
      <option value="Manager">Manager</option>
      <option value="Chef de projet">Chef de projet</option>
      <option value="Développeur">Développeur</option>
      <option value="Software Engineer">Software Engineer</option>
      <option value="DevOps Engineer">DevOps Engineer</option>
      <option value="Data Engineer">Data Engineer</option>
      <option value="Data Analyste">Data Analyste</option>
      <option value="Architecte">Architecte</option>
    </select>
                </div>

                <div className="col-3">
                  <label style={{ fontSize: "14px" }} htmlFor="dateEntree">
                    Date d'entrée*
                  </label>
                  <textarea
                    className="date-input form-control"
                    readOnly={true}
                    disabled={true}
                    id="dateEntree"
                    value={dateEntree}
                    onChange={handledateEntreeChange}
                  />
                </div>
                <div className="col-3">
                  <label style={{ fontSize: "14px" }} htmlFor="dateSortie">
                    Date de sortie
                  </label>
                  <textarea
                    className="date-input form-control"
                    readOnly={true}
                    disabled={true}
                    id="dateSortie"
                    value={dateSortie}
                    onChange={handledateSortieChange}
                  />
                </div>
              </div>
              
              {/*Button to display the mission 1 fields if no mission already existing*/}
              {!showFirstMission && (
                <div style={{ paddingTop: "3.5%" }}>
                  <a
                    href="#"
                    onClick={toggleFirstMission}
                    class="text"
                    style={{ color: "#000094", fontSize: "14px" }}
                  >
                    + Ajouter une mission
                  </a>
                </div>
              )}
              {/*Displays the mission 1 fields if button is clicked or one mission already existing*/}
              {showFirstMission && (
                <div>
                  <div className="row" style={{ paddingTop: "3.5%" }}>
                    <div className="col">
                      <div className="d-flex align-items-center justify-content-between">
                        <span style={{ color: "#737373", fontSize: "14px" }}>
                          Informations mission 1
                        </span>
                        {missionId1 !== "" && (
                          <>
                            {flagToDeleteMission1 ? (
                              <span
                                className="text"
                                style={{
                                  color: "#737373",
                                  fontSize: "14px",
                                  textDecoration: "none",
                                  marginRight: "4.7%",
                                }}
                              >
                                Marquée pour suppression
                              </span>
                            ) : (
                                // eslint-disable-next-line
                              <a
                                onClick={handleDeleteMission1}
                                className="text"
                                style={{
                                  color: "#000094",
                                  fontSize: "14px",
                                  marginRight: "4.7%",
                                }}
                              >
                                Supprimer la mission
                              </a>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <label style={{ fontSize: "14px" }} htmlFor="nomClient">
                        Nom du client*
                      </label>
                      <textarea
                        required
                        className="form-control"
                        style={{
                          width: "90%",
                          height: "5%",
                          fontSize: "14px",
                          resize: "none",
                          borderRadius: "4px",
                          borderColor: "#C4C4C4",
                        }}
                        id="nomClient1"
                        value={nomClient1}
                        onChange={handleNomClient1}
                      />
                    </div>
                    <div className="col">
                      <label style={{ fontSize: "14px" }} htmlFor="email">
                        Email client
                      </label>
                      <textarea
                        style={{
                          width: "90%",
                          height: "5%",
                          fontSize: "14px",
                          resize: "none",
                          borderRadius: "4px",
                          borderColor: "#C4C4C4",
                        }}
                        className="form-control"
                        id="emailClient1"
                        value={emailClient1}
                        onChange={handleEmailClient1}
                      />
                    </div>
                  </div>
                  <div className="row custom-div" style={{ paddingTop: "0.5%" }}>
                    <div class="col">
                    <label
                      style={{ fontSize: "14px" }}
                      htmlFor="descriptionMission"
                    >
                      Description mission*
                    </label>
                    <textarea
                      required
                      className="form-control"
                      style={{
                        width: "90%",
                        fontSize: "14px",
                        resize: "none",
                        borderRadius: "4px",
                        borderColor: "#C4C4C4",
                        overflowY: "auto",
                      }}
                      id="descriptionMission1"
                      value={descriptionMission1}
                      onChange={handleDescriptionMission1}
                    />
                  </div>
                  <div class="col"></div>
                </div> </div>
              )}

              {/*Button to display the mission 2 fields if mission one is displayed and no second mission is already existing*/}
              {!showSecondMission && showFirstMission && (
                <div style={{ paddingTop: "3.5%" }}>
                  <a
                    href="#"
                    onClick={toggleSecondMission}
                    class="text"
                    style={{ color: "#000094", fontSize: "14px" }}
                  >
                    + Ajouter une mission
                  </a>
                </div>
              )}
              {/*Button to display the mission 2 fields if button clicked or two missions already existing*/}
              {showSecondMission && (
                <div>
                  <div className="row" style={{ paddingTop: "3.5%" }}>
                    <div className="col">
                      <div className="d-flex align-items-center justify-content-between">
                        <span style={{ color: "#737373", fontSize: "14px" }}>
                          Informations mission 2
                        </span>
                        {missionId2 !== "" && (
                          <>
                            {flagToDeleteMission2 ? (
                              <span
                                className="text"
                                style={{
                                  color: "#737373",
                                  fontSize: "14px",
                                  textDecoration: "none",
                                  marginRight: "4.7%",
                                }}
                              >
                                Marquée pour suppression
                              </span>
                            ) : (
                                // eslint-disable-next-line
                              <a
                                onClick={handleDeleteMission2}
                                className="text"
                                style={{
                                  color: "#000094",
                                  fontSize: "14px",
                                  marginRight: "4.7%",
                                }}
                              >
                                Supprimer la mission
                              </a>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div class="col">
                      <label style={{ fontSize: "14px" }} htmlFor="nomClient">
                        Nom du client*
                      </label>
                      <textarea
                        className="form-control"
                        style={{
                          width: "90%",
                          height: "5%",
                          fontSize: "14px",
                          resize: "none",
                          borderRadius: "4px",
                          borderColor: "#C4C4C4",
                        }}
                        id="nomClient2"
                        value={nomClient2}
                        onChange={handleNomClient2}
                      />
                    </div>
                    <div className="col">
                      <label style={{ fontSize: "14px" }} htmlFor="email">
                        Email client
                      </label>
                      <textarea
                        style={{
                          width: "90%",
                          height: "5%",
                          fontSize: "14px",
                          resize: "none",
                          borderRadius: "4px",
                          borderColor: "#C4C4C4",
                        }}
                        className="form-control"
                        id="emailClient2"
                        value={emailClient2}
                        onChange={handleEmailClient2}
                      />
                    </div>
                  </div>
                  <div className="row custom-div" style={{ paddingTop: "0.5%" }}>
                  <div class="col">
                    <label
                      style={{ fontSize: "14px" }}
                      htmlFor="descriptionMission"
                    >
                      Description mission*
                    </label>
                    <textarea
                      className="form-control"
                      style={{
                        width: "90%",
                        fontSize: "14px",
                        resize: "none",
                        borderRadius: "4px",
                        borderColor: "#C4C4C4",
                        overflowY: "auto",
                      }}
                      id="descriptionMission2"
                      value={descriptionMission2}
                      onChange={handleDescriptionMission2}
                    />
                  </div>
                  <div class="col"></div>
                </div>
                </div>
              )}
              {showErrorPopup && (
                <div className="row text-center">
                  <label style={{ color: "#737373", fontSize: "14px" }}>
                    Veuillez correctement remplir les champs nécessaires avant
                    de valider.
                  </label>
                </div>
              )}
              {showSessionPopup && (
                <div className="row text-center">
                  <label style={{ color: "#737373", fontSize: "14px" }}>
                    Session expirée. Veuillez vous reconnecter.
                  </label>
                </div>
              )}
              {/*Buttons to cancel/validate modifications*/}
              <div className="row" style={{ paddingTop: "4%", width: "90%" }}>
                <div className="col-10 text-end">
                  <button
                    className="btn btn-primary secondary-button"
                    onClick={() => handleCancel()}
                    style={{ width: "158.29px" }}
                  >
                    Annuler
                  </button>
                </div>
                <div className="col-2 text-end">
                  <button
                    className="btn btn-primary main-button"
                    onClick={handleSubmit}
                    style={{ width: "158.29px" }}
                  >
                    Valider
                  </button>
                </div>
              </div>
            </form>
            {props.children}
          </div>
        </div>
      )}
    </>
  );
}

export default PopupModifier;
