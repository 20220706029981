import React, { useEffect, useRef } from 'react';
import Chart from 'chart.js/auto';

const PracticeBarChart = ({ data, filter, loading }) => {
  const chartRef = useRef(null);
  const chartInstance = useRef(null);

  const createStripedPattern = (
    ctx,
    color1,
    color2,
    stripeWidth,
    stripeSpacing
  ) => {
    const canvas = document.createElement("canvas");
    canvas.width = (stripeWidth + stripeSpacing) * 4;
    canvas.height = (stripeWidth + stripeSpacing) * 4;
    const patternContext = canvas.getContext("2d");

    patternContext.fillStyle = color1;
    patternContext.fillRect(0, 0, canvas.width, canvas.height);

    patternContext.strokeStyle = color2;
    patternContext.lineWidth = stripeWidth;

    // Draw diagonal lines (from top right to bottom left)
    for (
      let i = -canvas.width;
      i < canvas.width;
      i += (stripeWidth + stripeSpacing) * 2
    ) {
      patternContext.beginPath();
      patternContext.moveTo(canvas.width, i);
      patternContext.lineTo(i, canvas.height);
      patternContext.stroke();

      patternContext.beginPath();
      patternContext.moveTo(i, 0);
      patternContext.lineTo(0, i);
      patternContext.stroke();
    }

    return ctx.createPattern(canvas, "repeat");
  };

//  const practiceList = JSON.parse(sessionStorage.getItem('practice')) || []; // array
//  practiceList.shift(); // remove "agence de paris"
  const generateAlias = (label) => {
    if (label === "PAINTEG") {
        return "INTEG";
    }
    if (label === "Paris BA Testing") {
        return "Test";
    }
    if (label === "Paris Fonctionnelles") {
      return "FCT";
    }
    if (label === "Paris Transfo SI") {
      return "TSI";
    }
    if (label === "Paris CloudOps") {
      return "Cloud";
    }
    if (label === "Paris Data") {
      return "Data";
    }
    if (label === "Paris application") {
      return "Apps";
    }
    if (label === "BA Paris Digital Commerce") {
      return "DC";
    }
    if (label === "BA Lille") {
      return "Lille";
    }
    return label;
  };

  useEffect(() => {
    if (chartInstance.current) {
      chartInstance.current.destroy();
    }

    const ctx = chartRef.current.getContext('2d');

    if (loading) {
      ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
      ctx.fillText('Loading...', ctx.canvas.width / 2, ctx.canvas.height / 2);
    } else {
        const averagePracticeData = data['practice_mean'][filter]['average_practice'];
        const labels = Object.keys(averagePracticeData);
        
        const datasets = [
          {
            label: 'Moyenne Hardis',
            data: Object.values(averagePracticeData).map((entry) => entry.mean_hardis_note),
            backgroundColor: '#000094',
            borderColor: '#000094',
            borderWidth: 2,
            borderRadius: 10,
            borderSkipped: 'start',
          },
          {
            label: 'Moyenne Mission',
            data: Object.values(averagePracticeData).map((entry) => entry.mean_mission_note),
            backgroundColor: createStripedPattern(
              ctx,
              "#FFFFFF",
              "#0053FF",
              1,
              2
            ),
            borderColor: '#0053FF',
            borderWidth: 2,
            borderRadius: 10,
            borderSkipped: 'start',
          },
        ];
      const config = {
        type: 'bar',
        data: {
          labels: labels,
          datasets: datasets,
        },
        options: {
          scales: {
            y: {
              min: 0,
              max: 10,
              grid: {
                color: function (context) {
                  if (context.tick.value % 2 === 1) {
                    return '#FFFFFF';
                  } else {
                    return '#E7EBEC';
                  }
                },
              },
              ticks: {
                display: false,
              },
              border: {
                display: false,
              },
            },
            x: {
              grid: {
                color: '#FFFFFF',
              },
              ticks: {
                font: {
                    size: 10,
                    },
                callback : function (value) {
                    // Use the function generateAlias for each label
                    return generateAlias(this.getLabelForValue(value));
                },
                minRotation: 0,
                maxRotation: 0,
              },
            },
          },
          plugins: {
            legend: {
              display: false,
            },
          },

        },
      };

      chartInstance.current = new Chart(ctx, config);
    }

    // Cleanup function
    return () => {
      if (chartInstance.current) {
        chartInstance.current.destroy();
      }
    };
  }, [data, filter, loading]);

  return (
    <div>
      <canvas ref={chartRef} />
    </div>
  );
};

export default PracticeBarChart;
