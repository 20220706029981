import React, { useState, useEffect } from "react";

import TopNav from "../../../core/Component/TopNav";
import "../css/ViewBilan.css";
import ResumeProfile from "./ResumeProfile";
import { ReactComponent as HighIcon } from "../../MyCollabs/Components/Assets/humeur_high.svg";
import { ReactComponent as MediumIcon } from "../../MyCollabs/Components/Assets/humeur_medium.svg";
import { ReactComponent as LowIcon } from "../../MyCollabs/Components/Assets/humeur_low.svg";
import axios from "axios";

const CLOUD_RUN_DOMAIN = process.env.REACT_APP_CLOUD_RUN_DOMAIN;

// FONCTION HISTORIQUE

// TO DO : MODAL SHOW OR NOT FOR DETAILS + CONNECT WITH BACK FOR map()

function convertToISODate(frenchDate) {
  // Assuming the French date format is "DD/MM/YYYY"
  const [day, month, year] = frenchDate.split('/');
  return `${year}-${month}-${day}`;
}

export function ViewBilan() {
  const [bilans, setBilans] = useState([]);

  useEffect(() => {
    axios
      .get(
        `${CLOUD_RUN_DOMAIN}/bilan/collaborator/${sessionStorage.getItem(
          "selectedMatricule"
        )}`,
        {
          headers: {
            "Content-Type": "application/json",
            token: sessionStorage.getItem("token"),
          },
        }
      )
      .then((response) => setBilans(response.data))
      .catch((error) => console.log(error));
  }, []);

  const BilanItem = ({ bilan, index }) => {
    const [isExpanded, setExpanded] = useState();

    function toggleContent(event) {
      setExpanded(!isExpanded);
      const contentSection = event.currentTarget
        .closest(".row")
        .querySelector(".content-section");
      contentSection.classList.toggle("hidden");

      const rotateSvg = event.currentTarget.querySelector(".rotate-svg");
      rotateSvg.classList.toggle("rotate");
    }

    const bilanSections = document.querySelectorAll(
      ".container-css .content-section"
    );
    bilanSections.forEach((section, index) => {
      if (index > -1) {
        section.classList.add("hidden");    
      }
    });

    return (
      <div
        style={{
          backgroundColor: "white",
          padding: "3% 5% 1% 5%",
          borderRadius: "4px",
          boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
          marginBottom: "3%",
        }}
        key={bilan.id}
      >
        <div className="row">
          <div className="col-3">
            <p style={{ color: "#737373", fontSize: "14px" }}>Date</p>
            <p className="rattachement-note" style={{ fontSize: "14px" }}>
              {bilan.date}
            </p>
          </div>
          <div className="col-3">
            <p style={{ color: "#737373", fontSize: "14px" }}>
              Personne qui a saisi le bilan
            </p>
            <p className="rattachement-note" style={{ fontSize: "14px" }}>
              {bilan.reporter_name}
            </p>
          </div>
          <div className="col-2 text-center">
            <p style={{ color: "#737373", fontSize: "14px" }}>Note Hardis</p>
            <p
              className="rattachement-note"
              style={{ fontSize: "14px", fontWeight: "bold" }}
            >
              {isExpanded ? (
                bilan.hardis_note
              ) : bilan.hardis_note >= 7 ? (
                <HighIcon style={{ width: "25px", height: "25px" }} />
              ) : bilan.hardis_note >= 4 ? (
                <MediumIcon style={{ width: "25px", height: "25px" }} />
              ) : (
                <LowIcon style={{ width: "25px", height: "25px" }} />
              )}
            </p>
          </div>
          <div className="col-2 text-center">
            <p style={{ color: "#737373", fontSize: "14px" }}>Note Mission</p>
            <p
              className="rattachement-note"
              style={{ fontSize: "14px", fontWeight: "bold" }}
            >
              {isExpanded ? (
                bilan.mission_note
              ) : bilan.mission_note >= 7 ? (
                <HighIcon style={{ width: "25px", height: "25px" }} />
              ) : bilan.mission_note >= 4 ? (
                <MediumIcon style={{ width: "25px", height: "25px" }} />
              ) : (
                <LowIcon style={{ width: "25px", height: "25px" }} />
              )}
            </p>
          </div>
          <div
            className="col-2"
            style={{
              display: "flex",
              alignSelf: "flex-start",
              alignItems: "end",
            }}
          >
            <button className="btn " onClick={toggleContent}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="grey"
                class="bi bi-arrow-up-circle rotate-svg"
                viewBox="0 0 16 16"
              >
                <path
                  fill-rule="evenodd"
                  d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v5.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V4.5z"
                />
              </svg>
            </button>
          </div>

          <div className={`content-section hidden`}>
            <div className="row" style={{ paddingTop: "1.5%" }}>
              <div className="col">
                <label style={{ fontSize: "14px" }}>Remarque Hardis</label>
                <div
                    dangerouslySetInnerHTML={{ __html: bilan.hardis_comment }}
                    className="form-control"
                    style={{
                      width: "100%",
                      fontSize: "14px",
                      color: "#737373",
                      borderRadius: "4px",
                      borderColor: "#F6F6F6",
                      backgroundColor: "#F6F6F6",
                      padding: "10px",
                      whiteSpace: "pre-wrap", 
                    }}
                  />
              </div>

              <div className="col">
                <label style={{ fontSize: "14px" }}>Remarque Mission</label>
                <div
                    dangerouslySetInnerHTML={{ __html: bilan.mission_comment }}
                    className="form-control"
                    style={{
                      width: "100%",
                      fontSize: "14px",
                      color: "#737373",
                      borderRadius: "4px",
                      borderColor: "#F6F6F6",
                      backgroundColor: "#F6F6F6",
                      padding: "10px",
                      whiteSpace: "pre-wrap",
                    }}
                  />
              </div>
            </div>

            <div className="row" style={{ paddingTop: "2.5%" }}>
              <div className="col">
                <label style={{ fontSize: "14px" }}>Action Hardis</label>
                <div
                    dangerouslySetInnerHTML={{ __html: bilan.hardis_remark }}
                    className="form-control"
                    style={{
                      width: "100%",
                      fontSize: "14px",
                      color: "#737373",
                      borderRadius: "4px",
                      borderColor: "#F6F6F6",
                      backgroundColor: "#F6F6F6",
                      padding: "10px",
                      whiteSpace: "pre-wrap", 
                    }}
                  />
              </div>

              <div className="col" style={{ marginBottom: "4%" }}>
                <label style={{ fontSize: "14px" }}>Action Mission</label>
                <div
                    dangerouslySetInnerHTML={{ __html: bilan.mission_remark }}
                    className="form-control"
                    style={{
                      width: "100%",
                      fontSize: "14px",
                      color: "#737373",
                      borderRadius: "4px",
                      borderColor: "#F6F6F6",
                      backgroundColor: "#F6F6F6",
                      padding: "10px",
                      whiteSpace: "pre-wrap", // To wrap text and preserve line breaks
                    }}
                  />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      {/* <VerticalNav/> */}
      <TopNav />

      <div className="container" style={{ marginLeft: "5%", width: "1140px", height: "1158px" }}>
        <div className="Padding-top">
          <ResumeProfile />
        </div>
        <nav className="ms-2 mb-4">
          <div class="nav" id="nav-tab" role="tablist">
            <a
              class="nav-item nav-link nav-link-notactive border-bottom "
              id="nav-home-tab"
              data-toggle="tab"
              href="/new-bilan"
              role="tab"
              aria-controls="nav-home"
              
            >
              Nouveau Bilan
            </a>
            <a
              class="nav-item nav-link border-bottom border-info "
              id="nav-profile-tab"
              data-toggle="tab"
              href="/view-bilan"
              role="tab"
              aria-controls="nav-profile"
            >
              Tous les bilans
            </a>
            <a
              class="nav-item nav-link nav-link-notactive border-bottom "
              id="nav-home-tab"
              data-toggle="tab"
              href="/view-collab"
              role="tab"
              aria-controls="nav-home"
              aria-selected="true"
            >
              Informations générales
            </a>
            <a
              class="nav-item nav-link nav-link-notactive border-bottom"
              id="nav-home-tab"
              data-toggle="tab"
              href="/view-analytics"
              role="tab"
              aria-controls="nav-home"
              aria-selected="true"
            >
              Statistiques individuelles
            </a>
          </div>
        </nav>

        {bilans.length === 0 ? (
          <div
            style={{
              backgroundColor: "white",
              padding: "3% 5% 1% 5%",
              borderRadius: "4px",
              marginBottom: "3%",
            }}
          >
            <div className="row text-center">
              <label style={{ color: "#737373", fontSize: "14px" }}>
                Aucun bilan n'a été saisi pour le moment.
              </label>
            </div>
          </div>
        ) : (
          <div>
            {bilans &&
              bilans
              .sort((a, b) => new Date(convertToISODate(b.date)) - new Date(convertToISODate(a.date)))
              .map((bilan, index) => (
                <BilanItem bilan={bilan} index={index} key={bilan.id} />
              ))}
          </div>
        )}
      </div>
    </>
  );
}