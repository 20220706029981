import React, {useState, useRef} from "react";
import { useNavigate } from "react-router-dom";
import TopNav from "../../../core/Component/TopNav";
import InputEvaluation from "./InputEvaluation";
import ResumeProfile from "./ResumeProfile";
import TextAreas from "./TextAreasComponent";
import "../css/FormBilan.css";
import PopUp from "../../../core/Component/PopUp";
import axios from 'axios';
import { useUserAuth } from '../../../context/AuthContext.js';


const CLOUD_RUN_DOMAIN = process.env.REACT_APP_CLOUD_RUN_DOMAIN;

function FormBilan() {

  let navigate = useNavigate();
  const { user } = useUserAuth();
  const textAreaStateRef = useRef();
  const inputEvalRef = useRef();
  const [customErrorMessage, setCustomErrorMessage] = useState("");
  const [showConfirmationPopup, setShowConfirmationPopup] = useState(false);
  const [showErrorPopup, setShowErrorPopup] = useState(false);
  const [showSessionPopup, setShowSessionPopup] = useState(false);
  const [showDraftConfirmationPopup, setShowDraftConfirmationPopup] = useState(false);
  const [showDraftPopup, setShowDraftPopup] = useState(false)
  
  

  const handleDraft = async (event)=>{
    event.preventDefault();
    const draft={
          savetime: new Date().getTime(),
          date: inputEvalRef.current.getInputDate(),
          matricule: sessionStorage.getItem("selectedMatricule"),
          hardis_note: inputEvalRef.current.getNoteHardis(),
          hardis_comment: textAreaStateRef.current.getRemarqueHardis(),
          hardis_remark: textAreaStateRef.current.getActionHardis(),
          mission_note: inputEvalRef.current.getNoteMission(),
          mission_comment: textAreaStateRef.current.getRemarqueMission(),
          mission_remark: textAreaStateRef.current.getActionMission(),
    }
    
    //sessionStorage.setItem("draftBilan " + draft.matricule, JSON.stringify(draft));
    try {
      await axios.post(`${CLOUD_RUN_DOMAIN}/draft`, 
      {
        date: draft.date,
        matricule: draft.matricule,
        hardis_note: draft.hardis_note,
        hardis_comment: draft.hardis_comment,
        hardis_remark: draft.hardis_remark,
        mission_note: draft.mission_note,
        mission_comment: draft.mission_comment,
        mission_remark: draft.mission_remark,
      },
      { headers: { "Content-Type": "application/json",  
        token: sessionStorage.getItem("token")
    }});
      if (user) {
       user
        .getIdTokenResult()
        .then((idTokenResult) => {
          user.getIdToken(true).then((newIdToken) => {
            // Log the refreshed ID token
            console.log("Refreshed ID Token:", newIdToken);
          });
        })
        .catch((error) => {
          console.error('Error fetching ID token result:', error);
        });
    }
    
    setShowDraftPopup(true);
    setShowDraftConfirmationPopup(false);
    const expirationTime = 48 * 60 * 60 * 1000; // 48 heures en millisecondes
    
    
    setTimeout(async () => {
       // Logique pour envoyer le brouillon ici
       //const expireddraft = sessionStorage.getItem("draftBilan "+ draft.matricule);
      const response = await axios.get(`${CLOUD_RUN_DOMAIN}/draft/${sessionStorage.getItem("selectedMatricule")}`,
      {
        headers: {
          "Content-Type": "application/json",
          token: sessionStorage.getItem("token"),
        },
      }
      )
      const expireddraft=response.data;
      
      if (expireddraft.date === draft.date){
        try {
          await axios.post(`${CLOUD_RUN_DOMAIN}/bilan`, 
          {
            date: expireddraft.date,
            matricule: expireddraft.matricule,
            hardis_note: expireddraft.hardis_note,
            hardis_comment: expireddraft.hardis_comment,
            hardis_remark: expireddraft.hardis_remark,
            mission_note: expireddraft.mission_note,
            mission_comment: expireddraft.mission_comment,
            mission_remark: expireddraft.mission_remark,
          },
          { headers: { "Content-Type": "application/json",  
            token: sessionStorage.getItem("token")
        }});
          if (user) {
           user
            .getIdTokenResult()
            .then((idTokenResult) => {
              user.getIdToken(true).then((newIdToken) => {
                // Log the refreshed ID token
                console.log("Refreshed ID Token:", newIdToken);
              });
            })
            .catch((error) => {
              console.error('Error fetching ID token result:', error);
            });
        }
        await axios.delete(`${CLOUD_RUN_DOMAIN}/draft/${sessionStorage.getItem("selectedMatricule")}`, 
    
        { headers: { "Content-Type": "application/json",  
          token: sessionStorage.getItem("token")
  }})
       } catch (error) {
              console.log(customErrorMessage);
        }
      }
    }, expirationTime);
    
   } catch (error) {
          setShowDraftConfirmationPopup(false);
          setShowErrorPopup(true);
          setCustomErrorMessage(error.response.data.detail)
          console.log(customErrorMessage);
    }

    //const expirationTime = 48 * 60 * 60 * 1000; // 48 heures en millisecondes
    
    
  }



  const handleDraftPopup = () => {
    setShowDraftPopup(!showDraftPopup);
  }
  

  const handleDraftConfirmationPopup = (event) => {
    setShowDraftConfirmationPopup(!showDraftConfirmationPopup); 
    event.preventDefault()
    
  }
  

  
  
  const handleConfirmationPopup = () => {
    setShowConfirmationPopup(!showConfirmationPopup);
  };

  const handleErrorPopup = () => {
    setShowErrorPopup(!showErrorPopup);
  };

  const handleSessionPopup = () => {
    setShowSessionPopup(!showSessionPopup);
  };

  
  const handleSubmit = async (event) => {
    event.preventDefault();
      try {
        await axios.post(`${CLOUD_RUN_DOMAIN}/bilan`, 
        {
          date: inputEvalRef.current.getInputDate(),
          matricule: sessionStorage.getItem("selectedMatricule"),
          hardis_note: inputEvalRef.current.getNoteHardis(),
          hardis_comment: textAreaStateRef.current.getRemarqueHardis(),
          hardis_remark: textAreaStateRef.current.getActionHardis(),
          mission_note: inputEvalRef.current.getNoteMission(),
          mission_comment: textAreaStateRef.current.getRemarqueMission(),
          mission_remark: textAreaStateRef.current.getActionMission(),
        },
        { headers: { "Content-Type": "application/json",  
          token: sessionStorage.getItem("token")
      }});
      // delete draft
      await axios.delete(`${CLOUD_RUN_DOMAIN}/draft/${sessionStorage.getItem("selectedMatricule")}`,
      {
        headers: {
          "Content-Type": "application/json",
          token: sessionStorage.getItem("token"),
        },
      }
      );
          setShowConfirmationPopup(true);
          if (user) {
            user
              .getIdTokenResult()
              .catch((error) => {
                console.error('Error fetching ID token result:', error);
              });
          }
          const timer = setTimeout(() => {
            navigate('/view-bilan');
          }, 2000);
          return () => clearTimeout(timer);
          
        } catch (error) {
            // eslint-disable-next-line
          if (error.response && error.response.status === 401 || error.response.status === 402) {
            setShowSessionPopup(true);
            const timer = setTimeout(() => {
              navigate('/');
            }, 4000);
            return () => clearTimeout(timer);
          }
          else {
            setShowErrorPopup(true);
            setCustomErrorMessage(error.response.data.detail)
            console.log(customErrorMessage);
          }

        }
  };


  return (
    <>
      <PopUp message="Le bilan a été enregistré avec succès." show={showConfirmationPopup} onClose={handleConfirmationPopup} /> 
      <PopUp message="Veuillez correctement remplir les champs nécessaires avant de valider." show={showErrorPopup} onClose={handleErrorPopup} />
      <PopUp message="Session expirée, veuillez vous reconnecter." show={showSessionPopup} onClose={handleSessionPopup} />
      <PopUp message="Votre brouillon est sauvegardé pour 48 heures " show={showDraftPopup} onClose={handleDraftPopup}/>
      {/* <VerticalNav/> */}
      <TopNav />
      <div className="container" style={{ marginLeft: "5%", width: "1140px", height: "1158px"}}>
        <div className="Padding-top">
          <ResumeProfile />
        </div>
        <nav className="ms-2 mb-4">
          <div class="nav" id="nav-tab" role="tablist">
            <a
              class="nav-item nav-link border-bottom border-info"
              id="nav-home-tab"
              data-toggle="tab"
              href="#nav-home"
              role="tab"
              aria-controls="nav-home"
              aria-selected="true"
            >
              Nouveau Bilan
            </a>
            <a
              class="nav-item nav-link nav-link-notactive border-bottom "
              id="nav-home-tab"
              data-toggle="tab"
              href="/view-bilan"
              role="tab"
              aria-controls="nav-home"
              aria-selected="true"
            >
              Tous les bilans
            </a>
            <a
              class="nav-item nav-link nav-link-notactive border-bottom "
              id="nav-home-tab"
              data-toggle="tab"
              href="/view-collab"
              role="tab"
              aria-controls="nav-home"
              aria-selected="true"
            >
              Informations générales
            </a>
            <a
              class="nav-item nav-link nav-link-notactive border-bottom"
              id="nav-home-tab"
              data-toggle="tab"
              href="/view-analytics"
              role="tab"
              aria-controls="nav-home"
              aria-selected="true"
            >
              Statistiques individuelles
            </a>
          </div>
        </nav>
        
        <div
          
          style={{
            backgroundColor: "white",
            padding: "3% 0px 5% 5%",
            borderRadius: "4px",
            boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
          }}
        > 
        
        <form>
          <InputEvaluation ref={inputEvalRef}/>
          <TextAreas ref={textAreaStateRef} />

          
          <div className="row" style={{ paddingTop: "6%", width:"80%" }}>
              
              
                <div className="col-10 text-end">
                {/* Bouton pour sauvegarder le brouillon */}
                <button  className="btn btn-primary secondary-button" onClick={handleDraftConfirmationPopup}style={{ width: "250px"}}  >
                  Enregistrer le brouillon  <i className="icon-marg bi-check2-all"></i>               
                </button>
                </div>
             
            
              <div className="col-2 text-end" >
                <button className="btn btn-primary main-button"  onClick={handleSubmit} style={{ width: "250px"}}>
                  Enregistrer le bilan<i className="icon-marg bi-check2-all"></i>
                </button>
              </div>
            </div>
            {showDraftConfirmationPopup ? (
        <div
          className="modal modal-bis"
          style={{ width: "100vw", height: "110vh" }}
        >
          <div
           
            className="overlay"
            style={{ width: "110vw", height: "110vh" }}
          ></div>
          <div
            className="modal-content modal-content-bis"
            style={{ marginLeft: "-6.5%", marginTop: "-6.5%" }}
          >
            <div className="row">
              <div className="col">
                <h6>
                  <b>Enregistrer le brouillon</b>
                </h6>
              </div>
            </div>
            <form>
              <div className="row" style={{ paddingTop: "4%" }}>
                <div class="col text-center">
                  <span style={{ color: "#000000", fontSize: "14px" }}>
                    Êtes-vous sûr de vouloir sauvegarder votre brouillon ? Le bilan sera validé automatiquement dans 48h sans modifications de votre part 
                  </span>
                </div>
              </div>
              <div className="row" style={{ paddingTop: "5%" }}>
                <div className="col text-end">
                  <button
                    className="btn btn-primary secondary-button"
                    onClick={() => setShowDraftConfirmationPopup(false)}
                    style={{ width: "158.29px" }}
                  >
                    Annuler
                  </button>
                </div>
                <div className="col text-start">
                  <button
                    className="btn btn-primary main-button"
                    onClick={handleDraft}
                    style={{ width: "158.29px" }}
                  >
                    Enregistrer
                  </button>
                </div>
              </div>
            </form>

            
          </div>
        </div>
            ) : null}
            
          
          </form>
        </div>
      </div>
    </>
  );
}

export default FormBilan;
