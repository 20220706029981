import React from 'react';
import Maintenance from '../Views/Maintenance/Components/Maintenance';


function MaintenanceContext({ children }) {
  const maintenanceMode = false;

  return (
    <div style={
      {
        margin: 0,
        padding: 0,
      }
    }>
      {maintenanceMode ? (
        <Maintenance />
      ) : (
        children
      )}
    </div>
  );
}

export default MaintenanceContext;

