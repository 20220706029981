import React from "react";
import { ReactComponent as HighIcon } from "./Assets/humeur_high.svg";
import { ReactComponent as MediumIcon } from "./Assets/humeur_medium.svg";
import { ReactComponent as LowIcon } from "./Assets/humeur_low.svg";
import AnalyticsBlock from "./AnalyticBlock";
import SatisfactionBlock from "./SatisfactionBlock";

// eslint-disable-next-line
const CLOUD_RUN_DOMAIN = process.env.REACT_APP_CLOUD_RUN_DOMAIN;

export function AnalyticsBloc1({stats, loading, filter}) {

  if (loading) {
    return   <div className="analyticsContainer">
    <SatisfactionBlock
      title="Satisfaction"
      satisfaction="Loading..."
    />

    <AnalyticsBlock
      title="Taux de réalisation"
      value="Loading..."
      infoTitle="Collabs évalués"
      infoValue="Loading..."
    />

    <AnalyticsBlock
      title="Humeur Hardis"
      value={0}
      infoTitle="Humeur de mes collaborateurs"
      icon={
          <LowIcon style={{ width: '25px', height: '25px' }} />
      }
      evol={0}
      evolType="hardis"
    />

    <AnalyticsBlock
      title="Humeur Mission"
      value={0}
      infoTitle="Humeur de mes collaborateurs"
      icon={
          <LowIcon style={{ width: '25px', height: '25px' }} />
      }
      evol={0}
      evolType="mission"
    />
  </div>;
  }

  return (
  <div className="analyticsContainer">
      <SatisfactionBlock
        title="Satisfaction"
        satisfaction={stats[filter]['collab satisfaction']}
      />

      <AnalyticsBlock
        title="Taux de réalisation"
        value={`${stats[filter]['realization rate']}%`}
        infoTitle="Collabs évalués"
        infoValue={`sur ${stats[filter]['number of collabs']} collabs`}
      />

      <AnalyticsBlock
        title="Humeur Hardis"
        value={stats[filter]['hardis mood']}
        infoTitle="Humeur de mes collaborateurs"
        icon={
          stats[filter]['hardis mood'] >= 7 ? (
            <HighIcon style={{ width: '25px', height: '25px' }} />
          ) : stats[filter]['hardis mood'] >= 4 ? (
            <MediumIcon style={{ width: '25px', height: '25px' }} />
          ) : (
            <LowIcon style={{ width: '25px', height: '25px' }} />
          )
        }
        evol={stats[filter]['hardis evol']}
        evolType="hardis"
      />

      <AnalyticsBlock
        title="Humeur Mission"
        value={stats[filter]['mission mood']}
        infoTitle="Humeur de mes collaborateurs"
        icon={
          stats[filter]['mission mood'] >= 7 ? (
            <HighIcon style={{ width: '25px', height: '25px' }} />
          ) : stats[filter]['mission mood'] >= 4 ? (
            <MediumIcon style={{ width: '25px', height: '25px' }} />
          ) : (
            <LowIcon style={{ width: '25px', height: '25px' }} />
          )
        }
        evol={stats[filter]['mission evol']}
        evolType="mission"
      />
    </div>
  );
}
