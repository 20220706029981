import React, { useState } from "react";
import "./Login.css";
import { useNavigate } from "react-router-dom";
import logo from "./Assets/logo.svg";
import axios from "axios";
import PopUp from "../../../core/Component/PopUp";

const CLOUD_RUN_DOMAIN = process.env.REACT_APP_CLOUD_RUN_DOMAIN;

export default function SignUp() {
  const [password, setPass] = useState("");
  const [email, setEmail] = useState("");
  const [confPassword, setConfPass] = useState("");
  const [customErrorMessage, setCustomErrorMessage] = useState("");
  const regex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9]).{8,}$/;
  const [showConfirmationPopup, setShowConfirmationPopup] = useState(false);

  const logoHardis = logo;
  let navigate = useNavigate();

  const handleConfirmationPopup = () => {
    setShowConfirmationPopup(!showConfirmationPopup);
  };

  const handleRegister = async () => {
    try {
      const response = await axios.post(
        `${CLOUD_RUN_DOMAIN}/user`,
        { email, password, 
          "is_manager": true },
        { headers: { "Content-Type": "application/json" } }
      );
      const data = response.data;

      if (response.status !== 201) {
        setCustomErrorMessage(data.detail);
      } else {
        setShowConfirmationPopup(true);
        const timer = setTimeout(() => {
          navigate("/");
        }, 2000);
        return () => clearTimeout(timer);
      }
    } catch (error) {
      setCustomErrorMessage(
        "Un compte actif est déjà associé à ce mail. Je vous invite à utiliser la fonctionnalité mot de passe oublié depuis la page de connexion pour le modifier."
      );
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (password === confPassword && regex.test(password)) {
      handleRegister();
    } else {
      setCustomErrorMessage(
        "Le mot de passe doit contenir au moins un chiffre et des caratères spéciaux."
      );
    }
  };

  const handleChangeEmail = (event) => {
    const email = event.target.value;
    setEmail(email);

    if (!email.endsWith("@hardis-group.com")) {
      setCustomErrorMessage(
        'Votre adresse email doit se finir par "@hardis-group.com"'
      );
    } else {
      setCustomErrorMessage("");
    }
  };

  const handleChangePass = (event) => {
    const password = event.target.value;
    setPass(password);


    if (!regex.test(password)) {
      setCustomErrorMessage(
        "Votre mot de passe doit contenir au moins un chiffre, une lettre minuscule, une lettre majuscule, un caractère spécial et avoir au moins 8 caractères."
      );
    } else {
      setCustomErrorMessage("");
    }
  };

  const handleChangeConfPass = (event) => {
    const confPassword = event.target.value;

    setConfPass(confPassword);
    if (confPassword !== password) {
      setCustomErrorMessage("Les mots de passe ne correspondent pas.");
    } else {
      setCustomErrorMessage("");
    }
  };

  return (
    <>
    <PopUp message="Le compte a bien été activé." show={showConfirmationPopup} onClose={handleConfirmationPopup} />
      <div className="form-signin w-100 m-auto">
        
        <form onSubmit={handleSubmit}>
          {/* Title */}
          <img className="mb-4 img-home-logo" src={logoHardis} alt="logo_hardis" />
          <h1 className="h4 mb-3 fw-normal">Activer un compte</h1>
          {/* {customErrorMessage && (
            <div className="alert alert-danger" role="alert">
              {customErrorMessage}
            </div> */}

          {/* Input email */}
          <div className="form-floating">
            <input
              type="email"
              name="email"
              onChange={handleChangeEmail}
              value={email}
              className="form-control"
              id="floatingInput"
              placeholder="prenom.nom@hardis-group.com"
              pattern=".+@hardis-group\.com"
              required
            />
            <label htmlFor="floatingInput">Adresse email</label>
          </div>

          {/* Input password */}
          <div className="form-floating">
            <input
              required
              type="password"
              className="form-control"
              id="floatingPassword"
              onChange={handleChangePass}
              value={password}
              placeholder="Password"
              minLength={8}
              maxLength={64}
            />
            <label htmlFor="floatingPassword">Mot de passe</label>
          </div>

          {/* Input confirm password */}
          <div className="form-floating">
            <input
              required
              type="password"
              className="form-control"
              id="floatingPassword"
              onChange={handleChangeConfPass}
              value={confPassword}
              placeholder="Confirm Password"
              minLength="8"
              maxLength={64}
            />
            <label htmlFor="floatingPassword">Confirmer le mot de passe</label>
          </div>

          {/* Button */}
          <button className="w-100 btn btn-lg btn-color" type="submit">
            Connexion
          </button>
          <div>{customErrorMessage && (
            <p className="p-error" style={{ color: "#737373" }}>{customErrorMessage}</p>
          )}</div>
          
          <p className="mt-5 mb-3 text-muted">
            <a className="anchor-link" href="/">
              Votre compte est déjà activé ?
            </a>
          </p>
        </form>
      </div>
    </>
  );
}
