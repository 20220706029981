import React, { useState } from "react";
import "./Login.css";
import { useUserAuth } from "../../../context/AuthContext";
import { useNavigate } from "react-router-dom";
import logo from "./Assets/logo.svg";
import PopUp from "../../../core/Component/PopUp";
// import { Alert } from "react-bootstrap"

export default function ForgetPassword() {
  const [showConfirmationPopup, setShowConfirmationPopup] = useState(false);
  const [email, setEmail] = useState("");
    // eslint-disable-next-line
  const [error, setError] = useState("");
  const [customErrorMessage, setCustomErrorMessage] = useState("");
  const logoHardis = logo;
  const { forget } = useUserAuth();
  let navigate = useNavigate();

  const handleConfirmationPopup = () => {
    setShowConfirmationPopup(!showConfirmationPopup);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      await forget(email);
      setShowConfirmationPopup(true);
        // eslint-disable-next-line
        const timer = setTimeout(() => {
          navigate('/');
        }, 4000);
    } catch (err) {
      setCustomErrorMessage("Email incorrect. Veuillez entrer une adresse email valide.");
    }
  };

  const handleChangeEmail = (event) => {
    const email = event.target.value;
    setEmail(email);

    if (!email.endsWith("@hardis-group.com")) {
      setCustomErrorMessage(
        'Votre email doit faire partie du domaine "@hardis-group.com".'
      );
    } 
    else {
      setCustomErrorMessage("");
    }
  };

  return (
    <>
    <PopUp message={`Un email vous sera envoyé contenant un lien pour réinitialiser votre mot de passe.`} show={showConfirmationPopup} onClose={handleConfirmationPopup} />
    <div className="text-center">
      <div className="form-signin w-100 m-auto">
        <form onSubmit={handleSubmit}>
          {/* Title */}
          <img className="mb-4" src={logoHardis} alt="logo_hardis" width={200}/>
          {/* <h1 className="h4 mb-3 fw-normal">Créer un compte</h1> */}
          {error && (
            <div className="alert alert-danger" role="alert">
              {error}
            </div>
          )}
          {/* Input email */}
          <div className="form-floating">
            <input
              type="email"
              onChange={handleChangeEmail}
              value={email}
              className="form-control"
              id="floatingInput"
              placeholder="name@example.com"
              required
            />
            <label htmlFor="floatingInput">Adresse email</label>
          </div>

          {/* Button */}
          <button className="w-100 btn btn-lg btn-color" type="submit">
            Générer un lien de réinitialisation
          </button>
          {customErrorMessage && (
            <p className="p-error" style={{ color: "#737373" }}>{customErrorMessage}</p>
          )}
          <p className="mt-5 mb-3 text-muted">
            <a className="anchor-link" href="/">
              Vous connaissez votre mot de passe ?
            </a>
          </p>
        </form>
      </div>
    </div>
    </>
  );
}
