import React, { useState, useEffect } from "react";
import ResumeProfile from "../../Bilan/Components/ResumeProfile";
import axios from "axios";
import TopNav from "../../../core/Component/TopNav";
import DisablePopUp from "../Components/DisablePopUp";
import PopupModifier from "../Components/ModifierPopUp";
import "../css/ViewDetailCollab.css";
import "../../Admin/css/ViewAdmin.css";
//import ResumeProfile from "../../Bilan/Components/ResumeProfile";

const CLOUD_RUN_DOMAIN = process.env.REACT_APP_CLOUD_RUN_DOMAIN;

function calculateYearsOfExperience(entranceDate) {
  const dateParts = entranceDate.split(/[\s/:-]/);
  if (dateParts.length !== 3) {return "";}
  let year, month, day;
  if (dateParts[0].length === 4) {
    year = parseInt(dateParts[0], 10);
    month = parseInt(dateParts[1], 10);
    day = parseInt(dateParts[2], 10);
  } else if (dateParts[2].length === 4) {
    day = parseInt(dateParts[0], 10);
    month = parseInt(dateParts[1], 10);
    year = parseInt(dateParts[2], 10);
  } else {return "";}
  const entrance = new Date(year, month - 1, day); 
  const now = new Date();
  const diffInMilliseconds = now - entrance;
  const millisecondsInYear = 1000 * 60 * 60 * 24 * 365;
  const yearsOfExperience = diffInMilliseconds / millisecondsInYear;
  return Math.floor(yearsOfExperience);
}


export function DetailCollab() {
  const now = new Date();
  const [buttonDisablePopUp, setButtonDisablePopUp] = useState(false);
  const [buttonPopupModifier, setButtonPopupModifier] = useState(false);
  const [lastname, setnom] = useState("");
  const [firstname, setprenom] = useState("");
  const [emailH, setEmail] = useState("");
  const [job, setjob] = useState("");
  const [yearsExp, setyearsexp] = useState("");
  const [matricule, setmatricule] = useState("");
  const [practice, setpractice] = useState([]);
  const [manager, setmanager] = useState("");
  const [pl, setpl] = useState("");
  const [entrance_date, setdateEntree] = useState("");
  const [dateSortie, setdateSortie] = useState("");
  const [tel, setTel] = useState("");
  const [showFirstMission, setShowFirstMission] = useState(false);
  const [showSecondMission, setShowSecondMission] = useState(false);
  const [nomClient1, setNomClient1] = useState("");
  const [nomClient2, setNomClient2] = useState("");
  const [emailClient1, setEmailClient1] = useState("");
  const [emailClient2, setEmailClient2] = useState("");
  const [descriptionMission1, setdescriptionMission1] = useState("");
  const [descriptionMission2, setdescriptionMission2] = useState("");

  useEffect(() => {
    const fetchUserInfo = async () => {
      try {
        const response = await axios.get(
          `${CLOUD_RUN_DOMAIN}/collaborator/${sessionStorage.getItem("selectedMatricule")}`,
          {
            headers: {
              "Content-Type": "application/json",
              token: sessionStorage.getItem("token"),
            },
          }
        );

        setnom(response.data.lastname);
        setprenom(response.data.firstname);
        setEmail(response.data.email);
        setjob(response.data.job);
        setmatricule(response.data.matricule);
        setpractice(response.data.practice);
        setmanager(response.data.manager);
        setpl(response.data.people_leader);
        setdateEntree(response.data.entrance_date);
        setdateSortie(response.data.exit_date);
        setTel(response.data.tel);
        setyearsexp(calculateYearsOfExperience(response.data.entrance_date));
      } catch (error) {
        console.log(error);
      }
      try {
        const response = await axios.get(
          `${CLOUD_RUN_DOMAIN}/mission/${sessionStorage.getItem("selectedMatricule")}`,
          {
            headers: {
              "Content-Type": "application/json",
              token: sessionStorage.getItem("token"),
            },
          }
        );
        if (response.data.length > 0) {
          setNomClient1(response.data[0].client_name);
          setEmailClient1(response.data[0].email_client);
          setdescriptionMission1(response.data[0].description);
          setShowFirstMission(true);
        }
        if (response.data.length > 1) {
          setNomClient2(response.data[1].client_name);
          setEmailClient2(response.data[1].email_client);
          setdescriptionMission2(response.data[1].description);
          setShowSecondMission(true);
        }
      } catch (error) {
        console.log(error);
      }
    };

    fetchUserInfo();
  }, []);

  return (
    <>
       <TopNav />
      <div className="container" style={{ marginLeft: "5%", width: "1140px", height: "1158px"}}>
        <div className="Padding-top">
          <ResumeProfile />
        </div>
        <nav className="ms-2 mb-4">
          <div class="nav" id="nav-tab" role="tablist">
            <a
              class="nav-item nav-link nav-link-notactive border-bottom"
              id="nav-home-tab"
              data-toggle="tab"
              href="/new-bilan"
              role="tab"
              aria-controls="nav-home"            
            >
              Nouveau Bilan
            </a>
            <a
              class="nav-item nav-link nav-link-notactive border-bottom"
              id="nav-home-tab"
              data-toggle="tab"
              href="/view-bilan"
              role="tab"
              aria-controls="nav-home"
              
            >
              Tous les bilans
            </a>
            <a
              class="nav-item nav-link border-bottom border-info"
              id="nav-home-tab"
              data-toggle="tab"
              href="/view-collab"
              role="tab"
              aria-controls="nav-home"
              aria-selected="true"
            >
              Informations générales
            </a>
            <a
              class="nav-item nav-link nav-link-notactive border-bottom"
              id="nav-home-tab"
              data-toggle="tab"
              href="/view-analytics"
              role="tab"
              aria-controls="nav-home"
              aria-selected="true"
            >
              Statistiques individuelles
            </a>
          </div>
        </nav>
       
        <div    
          style={{
            backgroundColor: "white",
            padding: "3% 0px 5% 5%",
            borderRadius: "4px",
            boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
          }}
        > 

        {/* General information */}
          <div className="row" style={{ paddingTop: "2.5%" }}>
            <div class="col">
              <span style={{ color: "#000094", fontSize: "18px", fontWeight: "500"}}>
                Informations personnelles
              </span>
              <br />
              <label
                style={{ fontSize: "14px", paddingTop: "2%" }}
                htmlFor="nom"
              >
                Nom
              </label>
              <textarea
                className="collab-form form-control"
                readOnly={true}
                id="nom"
                defaultValue={lastname}
              />
            </div>
            <div className="col">
              <span style={{ color: "#000094", fontSize: "18px", fontWeight: "500"}}>
                Informations Hardis
              </span>
              <br />
              <label
                style={{ fontSize: "14px", paddingTop: "2%" }}
                htmlFor="matricule"
              >
                Matricule
              </label>
              <textarea
                className="collab-form form-control"
                readOnly={true}
                id="matricule"
                defaultValue={matricule}
              />
            </div>
          </div>

          <div className="row custom-div" style={{ paddingTop: "2%" }}>
            <div class="col">
              <label style={{ fontSize: "14px" }} htmlFor="prenom">
                Prénom
              </label>
              <textarea
                className=" collab-form form-control"
                readOnly={true}
                id="prenom"
                defaultValue={firstname}
              />
            </div>
            <div className="col">
              <label style={{ fontSize: "14px" }} htmlFor="practice">
                Practice
              </label>
              <div className="practice-checkboxes-view">
                <div className="row">
                  {practice && practice.map((practiceItem, index) => (
                    <div key={practiceItem} className="form-check col-6">
                      <input 
                        className="form-check-input practice-checkbox-readonly" 
                        type="checkbox" 
                        value={practiceItem} 
                        id={`practice-${practiceItem}`}
                        checked={practice.includes(practiceItem)}
                        readOnly
                      />
                      <label className="form-check-label" htmlFor={`practice-${practiceItem}`}>
                        {practiceItem}
                      </label>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>

          <div className="row custom-div" style={{ paddingTop: "2%" }}>
            <div class="col">
              <label style={{ fontSize: "14px" }} htmlFor="emailH">
                Email Hardis
              </label>
              <textarea
                className="collab-form form-control"
                readOnly={true}
                id="emailH"
                type="email"
                defaultValue={emailH}
              />
            </div>

            <div className="col">
              <label style={{ fontSize: "14px" }} htmlFor="manager">
                Manager
              </label>
              <textarea
                className="collab-form form-control"
                readOnly={true}
                id="Manager"
                defaultValue={manager}
                type="text"
              />
            </div>
          </div>

          <div className="row custom-div" style={{ paddingTop: "2%" }}>
            <div class="col">
              <label style={{ fontSize: "14px" }} htmlFor="tel">
                Téléphone
              </label>
              <textarea
                className="collab-form form-control"
                readOnly={true}
                id="tel"
                defaultValue={tel}
              />
            </div>
            <div className="col">
              <label style={{ fontSize: "14px" }} htmlFor="pl">
                People leader
              </label>
              <textarea
                className="collab-form form-control"
                readOnly={true}
                id="pl"
                defaultValue={pl}
              />
            </div>
          </div>

          <div className="row custom-div" style={{ paddingTop: "2%" }}>
            <div class="col-6">
              <label style={{ fontSize: "14px" }} htmlFor="Job">
                Métier
              </label>
              <textarea
                className="collab-form form-control"
                readOnly={true}
                id="Job"
                defaultValue={job}
              />
            </div>

            <div class="col-3">
              <label style={{ fontSize: "14px" }} htmlFor="dateEntree">
                Date d'entrée
              </label>
              <textarea
                className="collab-date form-control"
                readOnly={true}
                id="dateEntree"
                defaultValue={entrance_date}
              />
            </div>

            <div class="col-3">
              <label style={{ fontSize: "14px" }} htmlFor="dateSortie">
                Date de sortie
              </label>
              <textarea
                className="collab-date form-control"
                readOnly={true}
                id="dateEntree"
                defaultValue={dateSortie}
              />
            </div>

            <div className="row custom-div" style={{ paddingTop: "2%" }}>
              <div class="col-6">
                <label style={{ fontSize: "14px" }} htmlFor="yearsExp">
                  Nombre d'années d'expérience
                </label>
                <textarea
                  className="form-control"
                  readOnly={true}
                  style={{
                    width: "82%",
                    height: "5%",
                    fontSize: "14px",
                    color: "#737373",
                    resize: "none",
                    borderRadius: "4px",
                    borderColor: "#F6F6F6",
                    backgroundColor: "#F6F6F6",
                  }}
                  id="yearsExp"
                  value={yearsExp}
                />
                <div class="col" />
              </div>
            </div>
            {showFirstMission && (
              <div>
                <div className="row" style={{ marginTop: "2%" }}>
                  <div className="col">
                    <span style={{ color: "#737373", fontSize: "14px" }}>
                      Informations mission 1
                    </span>
                    <br />
                  </div>
                </div>
                <div className="row" style={{ paddingTop: "0.7%" }}>
                  <div class="col">
                    <label style={{ fontSize: "14px" }} htmlFor="nomClient">
                      Nom du client
                    </label>
                    <textarea
                      className="form-control"
                      readOnly={true}
                      style={{
                        width: "80%",
                        height: "5%",
                        fontSize: "14px",
                        color: "#737373",
                        resize: "none",
                        borderRadius: "4px",
                        borderColor: "#F6F6F6",
                        backgroundColor: "#F6F6F6",
                      }}
                      id="nomClient1"
                      defaultValue={nomClient1}
                    />
                  </div>
                  <div className="col">
                    <label style={{ fontSize: "14px" }} htmlFor="email">
                      Email client
                    </label>
                    <textarea
                      style={{
                        width: "80%",
                        height: "5%",
                        fontSize: "14px",
                        color: "#737373",
                        resize: "none",
                        borderRadius: "4px",
                        borderColor: "#F6F6F6",
                        backgroundColor: "#F6F6F6",
                      }}
                      className="form-control"
                      readOnly={true}
                      id="emailClient1"
                      defaultValue={emailClient1}
                    />
                  </div>
                </div>
                <div className="row custom-div" style={{ paddingTop: "2%" }}>
                <div class="col">
                  <label
                    style={{ fontSize: "14px" }}
                    htmlFor="descriptionMission"
                  >
                    Description mission
                  </label>
                  <textarea
                    className="form-control"
                    readOnly={true}
                    style={{
                      width: "80%",
                      fontSize: "14px",
                      color: "#737373",
                      resize: "none",
                      borderRadius: "4px",
                      borderColor: "#F6F6F6",
                      backgroundColor: "#F6F6F6",
                      overflowY: "auto",
                    }}
                    id="descriptionMission1"
                    defaultValue={descriptionMission1}
                  />
                </div>
                <div class="col"></div>
              </div>
              </div>
            )}
            {showSecondMission && (
              <div>
                <div className="row" style={{ marginTop: "2%" }}>
                  <div className="col">
                    <span style={{ color: "#737373", fontSize: "14px" }}>
                      Informations mission 2
                    </span>
                    <br />
                  </div>
                </div>
                <div className="row" style={{ paddingTop: "0.7%" }}>
                  <div class="col">
                    <label style={{ fontSize: "14px" }} htmlFor="nomClient">
                      Nom du client
                    </label>
                    <textarea
                      className="form-control"
                      readOnly={true}
                      style={{
                        width: "80%",
                        height: "5%",
                        fontSize: "14px",
                        color: "#737373",
                        resize: "none",
                        borderRadius: "4px",
                        borderColor: "#F6F6F6",
                        backgroundColor: "#F6F6F6",
                      }}
                      id="nomClient2"
                      defaultValue={nomClient2}
                    />
                  </div>
                  <div className="col">
                    <label style={{ fontSize: "14px" }} htmlFor="email">
                      Email client
                    </label>
                    <textarea
                      style={{
                        width: "80%",
                        height: "5%",
                        fontSize: "14px",
                        color: "#737373",
                        resize: "none",
                        borderRadius: "4px",
                        borderColor: "#F6F6F6",
                        backgroundColor: "#F6F6F6",
                      }}
                      className="form-control"
                      readOnly={true}
                      id="emailClient2"
                      defaultValue={emailClient2}
                    />
                  </div>
                </div>
                <div className="row custom-div" style={{ paddingTop: "2%" }}>
                <div class="col">
                  <label
                    style={{ fontSize: "14px" }}
                    htmlFor="descriptionMission"
                  >
                    Description mission
                  </label>
                  <textarea
                    className="form-control"
                    readOnly={true}
                    style={{
                      width: "80%",
                      fontSize: "14px",
                      color: "#737373",
                      resize: "none",
                      borderRadius: "4px",
                      borderColor: "#F6F6F6",
                      backgroundColor: "#F6F6F6",
                      overflowY: "auto",
                    }}
                    id="descriptionMission2"
                    defaultValue={descriptionMission2}
                  />
                </div>
                <div class="col"></div>
              </div>
              </div>
            )}
            {/* Activate & Modify buttons */}
            {sessionStorage.getItem("admin") === "true" && dateSortie < now ? (
              <div className="row" style={{ marginTop: "4%", width: "90%" }}>
                <div className="col-10 text-end">
                  <button
                    className="btn btn-primary secondary-button"
                    onClick={() => setButtonDisablePopUp(true)}
                    style={{ width: "158.29px" }}
                  >
                    Désactiver
                  </button>
                </div>
                <div className="col-2 text-end">
                  <button
                    className="btn btn-primary main-button"
                    onClick={() => setButtonPopupModifier(true)}
                    style={{ width: "158.29px" }}
                  >
                    Modifier
                  </button>
                </div>
              </div>
            ) : null}
          </div>

          <DisablePopUp
            trigger={buttonDisablePopUp}
            setTrigger={setButtonDisablePopUp}
          />

          <div className="popup-container">
            <PopupModifier
              trigger={buttonPopupModifier}
              setTrigger={setButtonPopupModifier}
              formValues={[
                lastname,
                firstname,
                emailH,
                job,
                yearsExp,
                matricule,
                practice,
                manager,
                pl,
                entrance_date,
                dateSortie,
                tel,
              ]}
              formSetters={[
                setnom,
                setprenom,
                setEmail,
                setjob,
                setyearsexp,
                setmatricule,
                setpractice,
                setmanager,
                setpl,
                setdateEntree,
                setdateSortie,
                setTel,
              ]}
            ></PopupModifier>
          </div>
        </div>
      </div>
    </>
  );
}
