import "../css/Modal.css";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import axios from "axios";
import PopUp from "../../../core/Component/PopUp";
import { useUserAuth } from '../../../context/AuthContext.js';

const CLOUD_RUN_DOMAIN = process.env.REACT_APP_CLOUD_RUN_DOMAIN;

export default function Modal({ show, handleClose }) {
  let navigate = useNavigate();
  const { user } = useUserAuth();
  const [profilCollab, setProfilCollab] = useState("");
  const [nom, setNom] = useState("");
  const [prenom, setPrenom] = useState("");
  const [emailHardis, setEmailHardis] = useState("");
  const [tel, setTel] = useState(null);
  const [metier, setMetier] = useState("");
  const [matricule, setMatricule] = useState("");
  const [practice, setPractice] = useState([]);
  const [manager, setManager] = useState("");
  const [peopleLeader, setPeopleLeader] = useState("");
  const date = new Date();
  const defaultDate = date.toLocaleDateString("en-CA");
  const [dateEntree, setDateEntree] = useState(defaultDate);
  const [managers, setManagers] = useState([]);
  const [peopleLeaders, setPeopleLeaders] = useState([]);
  const [practices, setPractices] = useState([]);
  // eslint-disable-next-line
  const [profils, setProfils] = useState([]);
  const [showConfirmationPopup, setShowConfirmationPopup] = useState(false);
  const [showErrorPopup, setShowErrorPopup] = useState(false);
  const [showSessionPopup, setShowSessionPopup] = useState(false);

  useEffect(() => {
    if (show) {
      document.body.classList.add("active-modal");
    } else {
      document.body.classList.remove("active-modal");
    }
  }, [show]);

  useEffect(() => {
    axios
      .get(`${CLOUD_RUN_DOMAIN}/infos/manager`, {
        params: {
          practice: practice,
        },
        headers: {
          "Content-Type": "application/json",
          token: sessionStorage.getItem("token"),
        },
      })
      .then((response) => {
        console.log(response.data);
        setManagers(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [practice]);

  useEffect(() => {
    setPeopleLeaders(JSON.parse(sessionStorage.getItem("pl")));
  }, []);

  useEffect(() => {
    axios
      .get(`${CLOUD_RUN_DOMAIN}/infos/practice`, {
        headers: {
          "Content-Type": "application/json",
          token: sessionStorage.getItem("token"),
        },
      })
      .then((response) => {
        console.log(response.data);
        setPractices(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    axios
      .get(`${CLOUD_RUN_DOMAIN}/infos/profil`, {
        headers: {
          "Content-Type": "application/json",
          token: sessionStorage.getItem("token"),
        },
      })
      .then((response) => {
        console.log(response.data);
        setProfils(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const handleConfirmationPopup = () => {
    setShowConfirmationPopup(!showConfirmationPopup);
  };

  const handleErrorPopup = () => {
    setShowErrorPopup(!showErrorPopup);
  };

  const handleSessionPopup = () => {
    setShowSessionPopup(!showSessionPopup);
  };

  const handleNom = (event) => {
    setNom(event.target.value);
  };
  const handleProfilCollab = (event) => {
    setProfilCollab(event.target.value);
  };
  const handlePrenom = (event) => {
    setPrenom(event.target.value);
  };
  const handleEmailHardis = (event) => {
    setEmailHardis(event.target.value);
  };
  const handleTel = (event) => {
    setTel(event.target.value);
  };
  const handleMetier = (event) => {
    setMetier(event.target.value);
  };
  const handleMatricule = (event) => {
    setMatricule(event.target.value);
  };
  const handlePractice = (event) => {
    const selectedPractice = event.target.value;
    if (practice.includes(selectedPractice)) {
      const updatedPractice = practice.filter(item => item !== selectedPractice);
      setPractice(updatedPractice);
    } else {
      const updatedPractice = [...practice, selectedPractice];
      setPractice(updatedPractice);
    }
  };
  const handleManager = (event) => {
    setManager(event.target.value);
  };
  const handlePeopleLeader = (event) => {
    setPeopleLeader(event.target.value);
  };
  const handleDateEntree = (event) => {
    setDateEntree(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
      try {
        await axios.post(
          `${CLOUD_RUN_DOMAIN}/collaborator`,
          {
            matricule: matricule,
            status: profilCollab,
            job: metier,
            firstname: prenom,
            lastname: nom.toUpperCase(),
            email:
              emailHardis.split("@")[0] + "@hardis-group.com",
            tel: tel,
            practice: practice,
            manager_uid: manager,
            people_leader_uid: peopleLeader,
            entrance_date: dateEntree,
            
          },
          {
            headers: {
              "Content-Type": "application/json",
              token: sessionStorage.getItem("token"),
            },
          }
        );
        handleClose();
        setShowConfirmationPopup(true);
        if (user) {
          user
            .getIdTokenResult()
            .then((idTokenResult) => {
              user.getIdToken(true).then((newIdToken) => {
                // Log the refreshed ID token
                console.log("Refreshed ID Token:", newIdToken);
              });
            })
            .catch((error) => {
              console.error('Error fetching ID token result:', error);
            });
        }
        const timer = setTimeout(() => {
          window.location.reload();
        }, 2000);
        return () => clearTimeout(timer);
      } catch (error) {
        // eslint-disable-next-line
        if (error.response && error.response.status === 401 || error.response.status === 402) {
          setShowSessionPopup(true);
          const timer = setTimeout(() => {
            navigate('/');
          }, 4000);
          return () => clearTimeout(timer);
        }
        else {
          setShowErrorPopup(true);
          console.log(error);
        }
      }
  };

  return (
    <>
      <PopUp
        message="Le collaborateur a été ajouté avec succès."
        show={showConfirmationPopup}
        onClose={handleConfirmationPopup}
      />
      <PopUp
        message="Veuillez correctement remplir les champs nécessaires avant de valider."
        show={showErrorPopup}
        onClose={handleErrorPopup}
      />
      <PopUp message="Session expirée, veuillez vous reconnecter." show={showSessionPopup} onClose={handleSessionPopup} />
      {show && (
        <form onSubmit={handleSubmit}>
          <div className="modal">
            <div onClick={handleClose} className="overlay"></div>
            <div className="modal-content">
              <div className="row">
                <div className="col">
                  <h6 style={{ fontSize: "22px", color: "#0053FF"}}>
                    <b>Nouveau Collab</b>
                  </h6>
                </div>
              </div>
              <div className="row" style={{ paddingTop: "1%" }}>
                <div className="col">
                  <span style={{ color: "#000094", fontSize: "18px", fontWeight: "500"}}>
                    Profil du collab
                  </span>
                  <br />
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <label style={{ fontSize: "14px" }} htmlFor="">
                    Profil*
                  </label>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <select
                    className="input-modal form-select custom-selector"
                    id=""
                    onChange={handleProfilCollab}
                    value={profilCollab}
                  >
                    <option value="" disabled selected>
                      Sélectionnez un profil
                    </option>
                    <option key={"Collaborateur"} value={"Collaborateur"}>
                      {" "}
                      {"Collaborateur"}{" "}
                    </option>
                    <option key={"People_Leader"} value={"People_Leader"}>
                      {" "}
                      {"People_Leader"}{" "}
                    </option>
                  </select>
                </div>
              </div>
              <div className="row" style={{ paddingTop: "2%" }}>
                <div class="col">
                  <span style={{ color: "#000094", fontSize: "18px", fontWeight: "500"}}>
                    Informations personnelles
                  </span>
                  <br />
                  <label style={{ fontSize: "14px" }} htmlFor="" required>
                    Nom*
                  </label>
                  <textarea
                    className="input-modal form-control"
                    style={{ width: "90%", height: "5%" }}
                    onChange={handleNom}
                    value={nom}
                  />
                </div>
                <div className="col">
                  <span style={{ color: "#000094", fontSize: "18px", fontWeight: "500" }}>
                    Informations Hardis
                  </span>
                  <br />
                  <label style={{ fontSize: "14px" }} htmlFor="" required>
                    Matricule*
                  </label>
                  <textarea
                    style={{ width: "90%", height: "5%" }}
                    className="input-modal form-control"
                    onChange={handleMatricule}
                    value={matricule}
                  />
                </div>
              </div>
              <div className="row custom-div" style={{ paddingTop: "0.5%" }}>
                <div class="col">
                  <label style={{ fontSize: "14px" }} htmlFor="" required>
                    Prénom*
                  </label>
                  <textarea
                    className="input-modal form-control"
                    style={{ width: "90%", height: "5%" }}
                    onChange={handlePrenom}
                    value={prenom}
                  />
                </div>
                <div className="col">
                  <label style={{ fontSize: "14px" }} htmlFor="">
                    Practice*
                  </label>
                  <div className="practice-checkboxes" style={{ width: "90%", marginLeft: "15px" }}>
                    <div className="row">
                      {practices && practices.map((practiceItem, index) => (
                        <div key={practiceItem} className="form-check col-6">
                          <input 
                            className="form-check-input" 
                            type="checkbox" 
                            value={practiceItem} 
                            id={`practice-${practiceItem}`}
                            checked={practice.includes(practiceItem)}
                            onChange={handlePractice}
                          />
                          <label className="form-check-label" htmlFor={`practice-${practiceItem}`}>
                            {practiceItem}
                          </label>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div style={{ marginBottom: "20px" }}>
                    <span style={{ color: "#000094", fontSize: "14px" }}>
                      {practice.length} practice(s) sélectionnée(s)
                    </span>
                  </div>
                </div>
              </div>
              <div className="row custom-div" style={{ paddingTop: "0.5%" }}>
                <div class="col">
                  <label style={{ fontSize: "14px" }} htmlFor="" required>
                    Email Hardis*
                  </label>
                  <div class="input-group" style={{ width: "90%" }}>
                    <input
                      type="text"
                      class="form-control"
                      aria-describedby="basic-addon2"
                      style={{
                        width: "57%",
                        fontSize: "14px",
                        resize: "none",
                        borderRadius: "4px 0px 0px 4px",
                        borderColor: "#C4C4C4",
                      }}
                      onChange={handleEmailHardis}
                      value={emailHardis}
                    ></input>
                    <span
                      class="input-group-text text-center"
                      id="basic-addon2"
                      style={{
                        width: "43%",
                        height: "5%",
                        fontSize: "14px",
                        resize: "none",
                        borderRadius: "0px 4px 4px 0px",
                        borderColor: "#C4C4C4",
                        color: "#121212",
                        alignItems: "center",
                        display: "block",
                      }}
                    >
                      @hardis-group.com
                    </span>
                  </div>
                </div>
                <div className="col">
                  <label style={{ fontSize: "14px" }} htmlFor="">
                    Manager*
                  </label>
                  <select
                    className="input-modal form-select"
                    style={{ width: "90%" }}
                    id=""
                    onChange={handleManager}
                    value={manager}
                  >
                    <option value="" selected>
                      Aucun
                    </option>
                    {managers &&
                      managers.map((manager) => (
                        <option key={manager.uid} value={manager.uid}>
                          {manager.Name} {manager.LastName}
                        </option>
                      ))}
                  </select>
                </div>
              </div>
              <div className="row" style={{ paddingTop: "1%" }}>
                <div class="col">
                  <label style={{ fontSize: "14px" }} htmlFor="">
                    Téléphone
                  </label>
                  <textarea
                    className="input-modal form-control"
                    style={{ width: "90%", height: "5%" }}
                    onChange={handleTel}
                    value={tel}
                  />
                </div>
                <div className="col">
                  <label style={{ fontSize: "14px" }} htmlFor="">
                    People Leader*
                  </label>
                  <select
                    className="input-modal form-select"
                    style={{ width: "90%" }}
                    id=""
                    onChange={handlePeopleLeader}
                    value={peopleLeader}
                  >
                    <option value="" selected>
                      Aucun
                    </option>
                    {peopleLeaders &&
                      peopleLeaders.map((peopleLeader) => (
                        <option key={peopleLeader.uid} value={peopleLeader.uid}>
                          {peopleLeader.Name} {peopleLeader.LastName}
                        </option>
                      ))}
                  </select>
                </div>
              </div>
              <div className="row custom-div" style={{ paddingTop: "0.5%" }}>
                <div class="col">
                <label style={{ fontSize: "14px" }} htmlFor="" required>
  Métier*
</label>
<select
  className="input-modal form-select"
  id=""
  style={{ width: "90%" }}
  onChange={handleMetier}
  value={metier}
>
  <option value="" disabled selected>
    Sélectionnez un métier
  </option>
  <option value="Manager">Manager</option>
  <option value="Chef de projet">Chef de projet</option>
  <option value="Développeur">Développeur</option>
  <option value="Software Engineer">Software Engineer</option>
  <option value="DevOps Engineer">DevOps Engineer</option>
  <option value="Data Engineer">Data Engineer</option>
  <option value="Data Analyste">Data Analyste</option>
  <option value="Architecte">Architecte</option>
</select>
                </div>
                <div class="col">
                  <label style={{ fontSize: "14px" }} htmlFor="" required>
                    Date entrée Hardis*
                  </label>
                  <input
                    class="input-modal form-control"
                    style={{ width: "90%" }}
                    value={dateEntree}
                    defaultValue={defaultDate}
                    name="dateEntree"
                    type="date"
                    min="2000-01-01"
                    max="2100-12-31"
                    onChange={handleDateEntree}
                  />
                </div>
              </div>
              
              <div className="row" style={{ paddingTop: "4%", width: "90%" }}>
                <div className="col-10 text-end">
                  <button
                    className="btn btn-primary secondary-button"
                    onClick={handleClose}
                    style={{ width: "158.29px" }}
                  >
                    Annuler
                  </button>
                </div>
                <div className="col-2 text-end">
                  <button
                    className="btn btn-primary main-button"
                    onClick={handleSubmit}
                    style={{ width: "158.29px" }}
                  >
                    Ajouter
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      )}
    </>
  );
}
