import { initializeApp } from "firebase/app";
import { getAuth, initializeAuth,connectAuthEmulator } from "firebase/auth";


const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID
};

const app = initializeApp(firebaseConfig);
initializeAuth(app);
export const auth = getAuth(app);

//Check if the url contains localhost, if so, connect to the emulator
if (window.location.href.includes('localhost')) {
  connectAuthEmulator(auth, 'http://127.0.0.1:9099', { disableWarnings: false })
}

export default app;