import React from 'react';

const Circle = ({ value, isStriped }) => {
  const percentage = (value / 10) * 100;

  return (
    <div
      className={`circle ${isStriped ? 'striped' : ''}`}
      style={{ '--percentage': percentage }}
    >
      <div className="inner-circle">{value} pts</div>
    </div>
  );
};

export default Circle;
