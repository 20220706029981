import React, { useState, useEffect } from "react";

import TopNav from "../../../core/Component/TopNav";
import "../../Bilan/css/ViewBilan.css";
import ResumeProfile from "../../Bilan/Components/ResumeProfile";
import axios from "axios";
import CollabPersonalChart from "./CollabPersonalChart";
import * as data from "./default_stats.json";

const CLOUD_RUN_DOMAIN = process.env.REACT_APP_CLOUD_RUN_DOMAIN;


export function AnalyticsCollab() {
  const [filter, setFilter] = useState(1);
  const [type, setType] = useState("all");
  const [stats, setStats] = useState(data);
  const [loading, setLoading] = useState(true);

  useEffect(() => {

    axios.get(`${CLOUD_RUN_DOMAIN}/analytics-collab/${sessionStorage.getItem(
        "selectedMatricule")}`, {
      })
      .then((response) => {
        console.log(response.data);
        setStats(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }
    , []);



  return (
    <>
      {/* <VerticalNav/> */}
      <TopNav />

      <div className="container" style={{ marginLeft: "5%", width: "1140px" }}>
        <div className="Padding-top">
          <ResumeProfile />
        </div>
        <nav className="ms-2 mb-4">
          <div class="nav" id="nav-tab" role="tablist">
            <a
              class="nav-item nav-link nav-link-notactive border-bottom "
              id="nav-home-tab"
              data-toggle="tab"
              href="/new-bilan"
              role="tab"
              aria-controls="nav-home"
              
            >
              Nouveau Bilan
            </a>
            <a
              class="nav-item nav-link nav-link-notactive border-bottom  "
              id="nav-profile-tab"
              data-toggle="tab"
              href="/view-bilan"
              role="tab"
              aria-controls="nav-profile"
            >
              Tous les bilans
            </a>
            <a
              class="nav-item nav-link nav-link-notactive border-bottom "
              id="nav-home-tab"
              data-toggle="tab"
              href="/view-collab"
              role="tab"
              aria-controls="nav-home"
              aria-selected="true"
            >
              Informations générales
            </a>
            <a
              class="nav-item nav-link nav-link border-bottom border-info"
              id="nav-home-tab"
              data-toggle="tab"
              href="/view-analytics"
              role="tab"
              aria-controls="nav-home"
              aria-selected="true"
            >
              Statistiques individuelles
            </a>
          </div>
        </nav>

      </div>
      <div
        className="mb-5"
        style={{ width: "1140px", textAlign: "left", paddingLeft: "5%", paddingRight: "5%" }} // to change width
      >
                      {/* filtre */}
       <div className="sub-block" style={{width:"106%", marginLeft:"1%"}}>
       <div className="filter-options">
     <button
       className={`filter-option ${filter === 12 && 'selected'}`}
       onClick={() => setFilter(12)}
     >
       12m
     </button>
     <button
       className={`filter-option ${filter === 6 && 'selected'}`}
       onClick={() => setFilter(6)}
     >
       6m
     </button>
     <button
       className={`filter-option ${filter === 3 && 'selected'}`}
       onClick={() => setFilter(3)}
     >
       3m
     </button>
     <button
       className={`filter-option ${filter === 1 && 'selected'}`}
       onClick={() => setFilter(1)}
     >
       1m
     </button>
   </div>
   <div className="filter-options">
   <button
       className={`filter-option ${type === 'all' && 'selected'}`}
       onClick={() => setType('all')}
     >
       Tous
     </button>
     <button
       className={`filter-option ${type === 'hardis' && 'selected'}`}
       onClick={() => setType('hardis')}
     >
       Hardis
     </button>
     <button
       className={`filter-option ${type === 'mission' && 'selected'}`}
       onClick={() => setType('mission')}
     >
       Mission
     </button>
   </div>
 
       </div>

<div className="mt-4 mb-5" style={{ display: "table", margin: "0 auto", textAlign: "center" }}>
    <div style={{ display: "inline-block", margin: "0 auto", width: "1094px", height: "100px", marginLeft: "10px" }}>
       {(filter === 1) ? <CollabPersonalChart data={stats[3]} loading={loading} type={type}/> : <CollabPersonalChart data={stats[filter]} loading={loading} type={type}/>}
</div>
</div>
       </div>
    </>
  );

}