import React from 'react';
import { ReactComponent as HighIcon } from "./Assets/humeur_high.svg";
import { ReactComponent as MediumIcon } from "./Assets/humeur_medium.svg";
import { ReactComponent as LowIcon } from "./Assets/humeur_low.svg";


const SatisfactionBlock = ({ title, satisfaction}) => {
  console.log(satisfaction)

  return (
    <div className="block" style={{backgroundColor: "white"}}>
      <div>
        <p className="title">{title}</p>
      </div>
      <div className="icon-line">
        <div className="icon-container">
          <LowIcon />
          {satisfaction["very unsatisfied"]}
        </div>
        <div className="icon-container">
          <MediumIcon />
          {satisfaction["unsatisfied"]}
        </div>
        <div className="icon-container">
          <HighIcon />
          {satisfaction["satisfied"]}
        </div>
      </div>
    </div>
  );
};

export default SatisfactionBlock;
