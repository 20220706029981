import React from 'react';

const AnalyticsBlock = ({ title, value, infoTitle, infoValue, icon, evol, evolType }) => {
  const renderEvol = () => {
    if (evol && evol !== 0) {
      return (
        <>
          {evol >= 0 ? <span>+ {evol} pts</span> : <span>{evol} pts</span>}
          <span className={evol > 0 ? "green" : evol < 0 ? "red" : "grey"}>
            {evol > 0 ? ' ↑' : evol < 0 ? ' ↓' : ' ↔'}
          </span>
        </>
      );
    }
    return null;
  };

  return (
    <div className="block" style={{backgroundColor: "white"}}>
      <div className={icon ? 'sub-block' : ''}>
        {icon && (
          <>
            <p className="title">{title}</p>
            {icon}
          </>
        )}
        {!icon && <p className="title">{title}</p>}
      </div>
      <p className="value">{value !== undefined ? `${value} ${title === 'Taux de réalisation' || title === 'Satisfaction' ? '' : 'pts'}` : ''}</p>
      <div className="info">
        <p className="info-title">{infoTitle}</p>
        <p className="info-value">
          {infoValue !== undefined ? `${infoValue}` : ''}
          {renderEvol()}
        </p>
      </div>
    </div>
  );
};

export default AnalyticsBlock;
