import React, {useState} from "react";
import "../css/ResumeProfile.css";
import { useNavigate } from "react-router-dom";
import image from "./img.png";
import PopUp from "../../../core/Component/PopUp";
import axios from "axios";
import Avatar from 'react-avatar';

const CLOUD_RUN_DOMAIN = process.env.REACT_APP_CLOUD_RUN_DOMAIN;

function  ResumeProfile() {
  let navigate = useNavigate();
    // eslint-disable-next-line
  const picture = image;
  const [showSessionPopup, setShowSessionPopup] = useState(false);

  const handleSessionPopup = () => {
    setShowSessionPopup(!showSessionPopup);
  };

  const getProfile = async (event) => {
    axios.get(`${CLOUD_RUN_DOMAIN}/user/me`, {
        headers: {
          "Content-Type": "application/json",
          token: sessionStorage.getItem("token"),
        },
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          setShowSessionPopup(true);
          const timer = setTimeout(() => {
            navigate('/');
          }, 4000);
          return () => clearTimeout(timer);
        }
        else {
          navigate('/');
        }
      });
    try {
      const url = `${CLOUD_RUN_DOMAIN}/collaborator/${sessionStorage.getItem("selectedMatricule")}/infos`
      const header = {
        "Content-Type": "application/json",
        token: sessionStorage.getItem("token")
      };
      const res = await axios.get(url , {headers: header});
      sessionStorage.setItem("collab", JSON.stringify(res["data"]))

      console.log(JSON.parse(sessionStorage.getItem("collab")))
    } catch (error) {
      console.log(error.response.data.detail);
    }
  };

  return (
    <>
    <PopUp message="Session expirée, veuillez vous reconnecter." show={showSessionPopup} onClose={handleSessionPopup} />
    <div className="container" onLoad={getProfile()}>
        <div class="row container-css p-3 mt-3 mb-4">
            <div className="col">
              <div style={
                {
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                }
              }
              >
            <Avatar
    name={`${JSON.parse(sessionStorage.getItem("collab"))["firstname"]} ${JSON.parse(sessionStorage.getItem("collab"))["lastname"]}`}
    size={80}
    round={true}
    textSizeRatio={3}
  />
  </div>
              {/* <img
              class="img-fluid img-rounded mx-3 d-block"
              src={picture}
              alt="profile_picture"
            /> */}

            </div>
            <div class="col">
              <p className="Namecss">{JSON.parse(sessionStorage.getItem("collab"))["firstname"]} {JSON.parse(sessionStorage.getItem("collab"))["lastname"]}</p>
              <p class="sub-title subtitle-profile">{JSON.parse(sessionStorage.getItem("collab"))["job"]}</p>
            </div>
            <div className="col">
              <p className="sub-title rattachement-subtitle">Rattachement actuel</p>
            <p className="rattachement-data">Manager : {JSON.parse(sessionStorage.getItem("collab"))["manager"]} | People Leader : {JSON.parse(sessionStorage.getItem("collab"))["people_leader"]}</p>
            </div>

            <div className="col">
              <p className="sub-title rattachement-subtitle-note">Dernières notes</p>
              <p className="rattachement-note">Hardis : {JSON.parse(sessionStorage.getItem("collab"))["note_hardis"]} | Mission : {JSON.parse(sessionStorage.getItem("collab"))["note_mission"]}</p>
            </div>
          </div>
                    
          <div class="col">
          </div>
          <div class="ms-5 col-md-3">
            
          </div>
          
    </div>
    </>
  );
}
export default ResumeProfile;
