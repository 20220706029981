import React, { useState } from 'react';
import Circle from './Circle';

export function AnalyticManagerBlock ({ stats, loading }) {
  const [showAll, setShowAll] = useState(false);

  const renderStats = () => {
    if (!stats.average_per_pl) {
      return null;
    }
    if (loading) {
      return <p>Loading...</p>;
    }

    const sortedManagers = Object.keys(stats.average_per_pl)
      .map((plUid) => stats.average_per_pl[plUid])
      .sort((a, b) => a.first_name.localeCompare(b.first_name));

    const displayedManagers = showAll ? sortedManagers : sortedManagers.slice(0, 5);

    return displayedManagers.map((manager, index) => {
      const roundedHardis = Math.round(manager.note_hardis);
      const roundedMission = Math.round(manager.note_mission);

      return (
        <div key={index} className="manager-block">
          <p className="manager-name">
            {`${manager.first_name} ${manager.last_name.charAt(0)}. | ${manager.nb_collabs} collabs`}
          </p>
          <p>
            <span className="job">
              {`${manager.job} ${manager.practice}`.length > 30
                ? `${`${manager.job} ${manager.practice}`.slice(0, 30)}...`
                : `${manager.job} ${manager.practice}`}
            </span>
          </p>
          <div className="circle-container mt-2 mb-5">
            <Circle value={roundedHardis} />
            <Circle value={roundedMission} isStriped />
          </div>
        </div>
      );
    });
  };

  return (
    <>
      <div className="manager-component mt-4">
        <div className="title-container">
          <p className="h5 fw-bold">
            Statistiques People Leader
          </p>
          <div className="legend">
            <div className="legend-item">
              <div className="legend-color" style={{ backgroundColor: '#000094', border: "2px solid #000094"}}></div>
              <p style={{ marginTop: "15px", color: "#7D7D7D", fontSize: "12px"}}>Moyenne Hardis</p>
            </div>
            <div className="legend-item">
              <div className="legend-color" style={{ backgroundColor: '#0053FF', backgroundImage: 'repeating-linear-gradient(-45deg, #FFFFFF, #FFFFFF 2px, #0053FF 2px, #0053FF 4px)', border: "2px solid #0053FF"}}></div>
              <p style={{ marginTop: "15px", color: "#7D7D7D", fontSize: "12px"}}>Moyenne Mission</p>
            </div>
          </div>
        </div>
        {renderStats()}
        {!showAll && !loading && stats.average_per_pl && (
          <>
            <div style={{ height: '1px', backgroundColor: '#E7EBEC', width: '96%' }}></div>{' '}
            <button className="btn btn-link more" onClick={() => setShowAll(true)}>
              Voir plus
            </button>
          </>
        )}
        {showAll && !loading && stats.average_per_pl && (
          <>
            <hr />
            <button className="btn btn-link more" onClick={() => setShowAll(false)}>
              Voir moins
            </button>
          </>
        )}
      </div>
    </>
  );
  
};

export default AnalyticManagerBlock;
