import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as ClearIcon } from "../css/clear_icon.svg";
import TopNav from "../../../core/Component/TopNav";
import Modal from "../Components/Modal";
import "../css/ViewAdmin.css";
import PopUp from "../../../core/Component/PopUp";
import axios from "axios";

const CLOUD_RUN_DOMAIN = process.env.REACT_APP_CLOUD_RUN_DOMAIN;

const date = new Date();
const defaultDate = date.toLocaleDateString("en-CA");

export function ViewAdmin() {
  let navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);

  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);

  const [query, setQuery] = useState("");
  const [collaborators, setCollaborators] = useState([]);
  const [filteredCollabs, setFilteredCollabs] = useState([]);
  const [practice, setPractice] = useState("");
  const [manager, setManager] = useState("");
  const [peopleLeader, setPeopleLeader] = useState("");
  const [managers, setManagers] = useState([]);
  const [peopleLeaders, setPeopleLeaders] = useState([]);
  const [practices, setPractices] = useState([]);
  const [nameIncreasingOrder, setNameIncreasingOrder] = useState(true);
  const [managerIncreasingOrder, setManagerIncreasingOrder] = useState(true);
  const [plIncreasingOrder, setPlIncreasingOrder] = useState(true);
  const [practiceIncreasingOrder, setPracticeIncreasingOrder] = useState(true);
  const [exitDateIncreasingOrder, setExitDateIncreasingOrder] = useState(true);
  const [applyFilters, setApplyFilters] = useState(false);
  const [showSessionPopup, setShowSessionPopup] = useState(false);
  const [practicePopUp, setPracticePopUp] = useState(false);
  const [selectedCollabPractices, setSelectedCollabPractices] = useState([]);

  const isManager = sessionStorage.getItem("admin");

  const handleSessionPopup = () => {
    setShowSessionPopup(!showSessionPopup);
  };

  useEffect(() => {
    if (isManager !== "true") {
      navigate("/home-analytics");
    }
  }, [isManager, navigate]);

  useEffect(() => {
    axios
      .get(`${CLOUD_RUN_DOMAIN}/collaborator`, {
        headers: {
          "Content-Type": "application/json",
          token: sessionStorage.getItem("token"),
        },
      })
      .then((response) => {
        // Make sure response.data is an array
        if (Array.isArray(response.data)) {
          setCollaborators(response.data);
          setApplyFilters(true);
        } else {
          console.log("Invalid response data:", response.data);
        }
      })
      .catch((error) => console.log(error));
  }, []);

  useEffect(() => {
    axios
      .get(`${CLOUD_RUN_DOMAIN}/user/me`, {
        headers: {
          "Content-Type": "application/json",
          token: sessionStorage.getItem("token"),
        },
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          setShowSessionPopup(true);
          const timer = setTimeout(() => {
            navigate("/");
          }, 4000);
          return () => clearTimeout(timer);
        } else {
          navigate("/");
        }
      });
        // eslint-disable-next-line
  }, []);

  const handleClickOnIcon = async (matricule) => {
    sessionStorage.setItem("selectedMatricule", matricule);
    const url = `${CLOUD_RUN_DOMAIN}/collaborator/${sessionStorage.getItem(
      "selectedMatricule"
    )}/infos`;
    const header = {
      "Content-Type": "application/json",
      token: sessionStorage.getItem("token"),
    };

    const res = await axios.get(url, { headers: header });
    sessionStorage.setItem("collab", JSON.stringify(res["data"]));
    navigate("/view-collab");
  };

  const handleClickOnCollab = async (matricule) => {
    sessionStorage.setItem("selectedMatricule", matricule);
    const url = `${CLOUD_RUN_DOMAIN}/collaborator/${sessionStorage.getItem(
      "selectedMatricule"
    )}/infos`;
    const header = {
      "Content-Type": "application/json",
      token: sessionStorage.getItem("token"),
    };
    const res = await axios.get(url, { headers: header });
    sessionStorage.setItem("collab", JSON.stringify(res["data"]));
    navigate("/view-collab");
  };

  const handleNameOrder = async () => {
    if (nameIncreasingOrder) {
      const sortedCollabs = [...filteredCollabs].sort((a, b) => {
        return a.firstname.localeCompare(b.firstname);
      });
      setFilteredCollabs(sortedCollabs);
    } else {
      const sortedCollabs = [...filteredCollabs].sort((a, b) => {
        return b.firstname.localeCompare(a.firstname);
      });
      setFilteredCollabs(sortedCollabs);
    }
    setNameIncreasingOrder(!nameIncreasingOrder);
  };

  const handleManagerOrder = async () => {
    if (managerIncreasingOrder) {
      const sortedCollabs = [...filteredCollabs].sort((a, b) => {
        if (a.manager_name === "-" && b.manager_name !== "-") {
          return 1;
        }
        if (a.manager_name !== "-" && b.manager_name === "-") {
          return -1;
        }
        return a.manager_name.localeCompare(b.manager_name);
      });
      setFilteredCollabs(sortedCollabs);
    } else {
      const sortedCollabs = [...filteredCollabs].sort((a, b) => {
        if (!a.manager_name) return -1;
        return b.manager_name.localeCompare(a.manager_name);
      });
      setFilteredCollabs(sortedCollabs);
    }
    setManagerIncreasingOrder(!managerIncreasingOrder);
  };

  const handlePlOrder = async () => {
    if (plIncreasingOrder) {
      const sortedCollabs = [...filteredCollabs].sort((a, b) => {
        if (a.people_leader_name === "-" && b.people_leader_name !== "-") {
          return 1;
        }
        if (a.people_leader_name !== "-" && b.people_leader_name === "-") {
          return -1;
        }
        return a.people_leader_name.localeCompare(b.people_leader_name);
      });
      setFilteredCollabs(sortedCollabs);
    } else {
      const sortedCollabs = [...filteredCollabs].sort((a, b) => {
        return b.people_leader_name.localeCompare(a.people_leader_name);
      });
      setFilteredCollabs(sortedCollabs);
    }
    setPlIncreasingOrder(!plIncreasingOrder);
  };

  const handlePracticeOrder = async () => {

    const compareArrays = (a, b) => {
        a.sort();
        b.sort();

        const aString = a.join(',');
        const bString = b.join(',');

        return practiceIncreasingOrder ? aString.localeCompare(bString) : bString.localeCompare(aString);
    };

    const sortedCollabs = [...filteredCollabs].sort((a, b) => {
        return compareArrays(a.practice, b.practice);
    });

    setFilteredCollabs(sortedCollabs);

    setPracticeIncreasingOrder(!practiceIncreasingOrder);
};

  const parseDateBis = (dateStr) => {
    const [year, month, day] = dateStr.split("-").map(Number);
    return new Date(year, month - 1, day);
  };

  const handleExitDateOrder = async () => {
    if (exitDateIncreasingOrder) {
      const sortedCollabs = [...filteredCollabs].sort((a, b) => {
        if (!a.exit_date) {
          a.exit_date = "2901-01-01";
        }
        if (!b.exit_date) {
          b.exit_date = "2901-01-01";
        }
        return parseDateBis(a.exit_date) - parseDateBis(b.exit_date);
      });
      setFilteredCollabs(sortedCollabs);
    } else {
      const sortedCollabs = [...filteredCollabs].sort((a, b) => {
        if (!a.exit_date) {
          a.exit_date = "2901-01-01";
        }
        if (!b.exit_date) {
          b.exit_date = "2901-01-01";
        }
        return parseDateBis(b.exit_date) - parseDateBis(a.exit_date);
      });
      setFilteredCollabs(sortedCollabs);
    }
    setExitDateIncreasingOrder(!exitDateIncreasingOrder);
  };

  const handlePractice = (event) => {
    setPractice(event.target.value);
  };

  const handleManager = (event) => {
    setManager(event.target.value);
  };

  const handlePeopleLeader = (event) => {
    setPeopleLeader(event.target.value);
  };

  useEffect(() => {
    // Charger les filtres
    const savedFilters = JSON.parse(localStorage.getItem("adminFilters")) || {};
    setQuery(savedFilters.query || "");
    setPractice(savedFilters.practice || "");
    setManager(savedFilters.manager || "");
    setPeopleLeader(savedFilters.peopleLeader || "");
    // Charger les listes
    setPractices(JSON.parse(sessionStorage.getItem("practice")));
    setPeopleLeaders(JSON.parse(sessionStorage.getItem("pl")));
    setManagers(JSON.parse(sessionStorage.getItem("manager")));
  }, []);

  // Gérer le changement de filtres et sauvegarder dans sessionStorage
  useEffect(() => {
    const res = search(collaborators);
    setFilteredCollabs(res);
    const filtersToSave = { query, practice, manager, peopleLeader };
    localStorage.setItem("adminFilters", JSON.stringify(filtersToSave));
      // eslint-disable-next-line
  }, [query, practice, manager, peopleLeader, applyFilters]);

  const search = (data) => {
    return data.filter(
      (item) =>
        (item.lastname.toLowerCase().includes(query) ||
          item.firstname.toLowerCase().includes(query)) &&
        (manager !== "MANAGER"
          ? item.manager_name.toLowerCase().includes(manager.toLowerCase())
          : item) &&
        (peopleLeader !== "PEOPLE LEADER"
          ? item.people_leader_name
              .toLowerCase()
              .includes(peopleLeader.toLowerCase())
          : item) &&
        (practice !== "PRACTICE"
          ? // item.practice est un tableau, il faut vérifier si le tableau contient la valeur de practice
            practice === "" || item.practice.includes(practice)
          : // Si practice est vide, on retourne tout
            item)
    );
  };

  const handlePracticeClear = () => {
    setPractice("");
  };

  const handleManagerClear = () => {
    setManager("");
  };

  const handlePeopleLeaderClear = () => {
    setPeopleLeader("");
  };

  const handlePracticePopUp = (practices) => {
    setSelectedCollabPractices(practices);
    setPracticePopUp(true);
  };

  return (
    <>
      <PopUp
        message="Session expirée, veuillez vous reconnecter."
        show={showSessionPopup}
        onClose={handleSessionPopup}
      />
      <TopNav />
      {practicePopUp && (
        <div
          className="popup"
          style={{
            width: "100vw",
            height: "100vh",
            position: "fixed",
            top: "0",
            left: "0",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "rgba(0,0,0,0.5)",
            zIndex: "1000",
          }}
        >
          <div
            className="popup_inner"
            style={{
              position: "relative",
              padding: "20px",
              width: "50%",
              backgroundColor: "white",
              borderRadius: "10px",
              boxShadow: "0 0 10px 3px rgba(0,0,0,0.2)",
            }}
          >
            <h2>Practices</h2>
            <ul
              style={{
                listStyle: "none",
                padding: "0",
              }}
            >
              {selectedCollabPractices.map((practice, index) => (
                <li key={index}>{practice}</li>
              ))}
            </ul>
            <button
              style={{
                position: "absolute",
                top: "10px",
                right: "10px",
                cursor: "pointer",
                border: "none",
                background: "none",
                fontSize: "20px",
              }}
              onClick={() => setPracticePopUp(false)}
            >
              <i class="bi bi-x-lg"></i>
            </button>
          </div>
        </div>
      )}

      <div
        className="container padding-top"
        style={{ width: "100vw", marginLeft: "3.5%" }}
      >
        <p className="text-uppercase h4 fw-bold">Administration</p>

        {/* PARTIE FILTRES  */}
        <div
          style={{
            backgroundColor: "white",
            padding: "3% 3% 3% 3%",
            borderRadius: "4px",
            boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
            marginTop: "3%",
          }}
        >
          <div class="row">
            <div class="col-2">
              <input
                type="text"
                class="form-control selector"
                style={{ fontSize: "16px" }}
                id="floatingInput"
                placeholder="Recherche"
                value={query}
                onChange={(e) => setQuery(e.target.value)}
              />
            </div>
            <div className="col-2" style={{ position: "relative" }}>
              <div style={{ display: "flex" }}>
                <select
                  className="form-select selector"
                  style={{
                    fontSize: "14px",
                    borderColor: "#FFFFFF",
                    width: "80%",
                    paddingRight: "30px",
                  }}
                  id="floatingPractice"
                  onChange={handlePractice}
                  value={practice}
                >
                  <option value="" disabled selected>
                    PRACTICE
                  </option>
                  {practices &&
                    practices.map((practice) => (
                      <option key={practice} value={practice}>
                        {practice}
                      </option>
                    ))}
                </select>
                {practice && (
                  <div
                    className="mark"
                    style={{ right: "8px" }}
                    onClick={handlePracticeClear}
                  >
                    <ClearIcon
                      style={{ width: "12px", height: "12px", fill: "black" }}
                    />
                  </div>
                )}
              </div>
            </div>

            <div className="col-2" style={{ position: "relative" }}>
              <div style={{ display: "flex" }}>
                <select
                  className="form-select selector"
                  style={{
                    fontSize: "14px",
                    borderColor: "#FFFFFF",
                    width: "81%",
                    paddingRight: "30px",
                  }}
                  id="floatingPractice"
                  onChange={handleManager}
                  value={manager}
                >
                  <option value="" disabled selected>
                    MANAGER
                  </option>
                  {managers &&
                    managers.map((manager) => (
                      <option key={manager["Name"]} value={manager["name"]}>
                        {manager["LastName"]}
                      </option>
                    ))}
                </select>
                {manager && (
                  <div
                    className="mark"
                    style={{ right: "8px" }}
                    onClick={handleManagerClear}
                  >
                    <ClearIcon
                      style={{ width: "12px", height: "12px", fill: "black" }}
                    />
                  </div>
                )}
              </div>
            </div>

            <div className="col-3" style={{ position: "relative" }}>
              <div style={{ display: "flex" }}>
                <select
                  className="form-select selector"
                  style={{
                    fontSize: "14px",
                    borderColor: "#FFFFFF",
                    width: "67%",
                    paddingRight: "30px",
                  }}
                  id="floatingPractice"
                  onChange={handlePeopleLeader}
                  value={peopleLeader}
                >
                  <option value="" disabled selected>
                    PEOPLE LEADER
                  </option>
                  {peopleLeaders &&
                    peopleLeaders.map((peopleLeader) => (
                      <option
                        key={peopleLeader["Name"]}
                        value={peopleLeader["name"]}
                      >
                        {peopleLeader["LastName"]}
                      </option>
                    ))}
                </select>
                {peopleLeader && (
                  <div
                    className="mark"
                    style={{ right: "58px" }}
                    onClick={handlePeopleLeaderClear}
                  >
                    <ClearIcon
                      style={{ width: "12px", height: "12px", fill: "black" }}
                    />
                  </div>
                )}
              </div>
            </div>

            <div className="col-3">
              <button
                className="btn btn-primary main-button"
                onClick={handleShow}
                style={{ width: "100%" }}
              >
                Nouveau collab<i className="icon-marg bi-plus-circle"></i>
              </button>
            </div>
            <Modal show={showModal} handleClose={handleClose} />
          </div>
        </div>
        <div>
          <div
            className="container container-no-shadow"
            style={{ marginTop: "3%", padding: "2%" }}
          >
            <div>
              <div className="row">
                <div className="col-2 text-center">
                  <button
                    className="column-title sub-title column-button"
                    onClick={handleNameOrder}
                  >
                    PRENOM & NOM ⇅
                  </button>
                </div>
                <div className="col-3 text-center">
                  <button
                    className="column-title sub-title column-button"
                    onClick={handleManagerOrder}
                  >
                    MANAGER ⇅
                  </button>
                </div>
                <div className="col-2 text-center">
                  <button
                    className="column-title sub-title column-button"
                    onClick={handlePlOrder}
                  >
                    PEOPLE LEADER ⇅
                  </button>
                </div>
                <div className="col-2 text-center">
                  <button
                    className="column-title sub-title column-button"
                    onClick={handlePracticeOrder}
                  >
                    PRACTICE ⇅
                  </button>
                </div>
                <div className="col-1 text-center">
                  <button
                    className="column-title sub-title column-button"
                    onClick={handleExitDateOrder}
                  >
                    STATUT ⇅
                  </button>
                </div>
                <div className="col-2 text-center">
                  <p className="column-title sub-title">MODIFIER</p>
                </div>
              </div>

              {/* PARTIE DATA DES COLLAB  */}
              {/* Utiliser la fonction map() pour gérer l'affichage en fonction de la data  */}
              <div>
                {filteredCollabs &&
                  filteredCollabs.map((collaborator) => (
                    <div
                      className="row"
                      style={{ marginTop: "14px" }}
                      key={collaborator.matricule}
                    >
                      <div
                        className="col-2"
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <p
                          className="data-txt-css text"
                          onClick={() =>
                            handleClickOnCollab(collaborator.matricule)
                          }
                          style={{
                            fontSize: "16px",
                            margin: "0px",
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                          }}
                        >
                          {collaborator.firstname} {collaborator.lastname}
                        </p>
                      </div>
                      <div
                        className="col-3"
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <p
                          className="data-txt-css"
                          style={{
                            fontSize: "16px",
                            margin: "0px",
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                          }}
                        >
                          {collaborator.manager_name}
                        </p>
                      </div>
                      <div
                        className="col-2"
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <p
                          className="data-txt-css"
                          style={{
                            fontSize: "16px",
                            margin: "0px",
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                          }}
                        >
                          {collaborator.people_leader_name}
                        </p>
                      </div>
                      <div className="center-text col-2">
                        {collaborator.practice.length > 1 ? (
                          <p
                            className="data-txt-css"
                            style={{
                              fontSize: "16px",
                              margin: "0px",
                              textOverflow: "ellipsis",
                              overflow: "hidden",
                              cursor: "pointer",
                            }}
                            onClick={() =>
                              handlePracticePopUp(collaborator.practice)
                            }
                          >
                            Multi Practices{" "}
                            <i class="bi bi-info-circle-fill"></i>
                          </p>
                        ) : (
                          <p
                            className="data-txt-css"
                            style={{
                              fontSize: "16px",
                              margin: "0px",
                              textOverflow: "ellipsis",
                              overflow: "hidden",
                            }}
                          >
                            {collaborator.practice}
                          </p>
                        )}
                      </div>
                      <div
                        className="col-1"
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                      {defaultDate >= collaborator.exit_date ? (
                        <>
                          <span
                            class=" badge badge-ok badge-pill badge-primary"
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              color: "#414141",
                              fontSize: "12px",
                              fontWeight: "normal",
                              borderRadius: "20px",
                              width: "67px",
                              backgroundColor: "#E8E8E8",
                            }}
                          >
                            Inactif
                          </span>
                        </>
                      ) : (
                        <>
                          <span
                            class=" badge badge-ok badge-pill badge-primary"
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              color: "white",
                              fontSize: "12px",
                              fontWeight: "normal",
                              borderRadius: "20px",
                              width: "67px",
                              backgroundColor: "#0053FF",
                            }}
                          >
                            Actif
                          </span>
                        </>
                      )}
                      </div>
                      <div
                        className="col-2"
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <button
                          className="btn btn-primary secondary-button"
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                          onClick={() =>
                            handleClickOnIcon(collaborator.matricule)
                          }
                        >
                          <i class=" bi-pencil "></i>
                        </button>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
